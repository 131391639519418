import { styled } from '@mui/material'
import TextInput from '@rentspree/component-2023.components.atoms.text-input'

export const StyledTextField = styled(TextInput)(() => ({
  margin: '10px 0px!important',
  marginBottom: '16px',

  '& input': {
    padding: '16px',
    borderRadius: '8px',
  },

  '& label': {
    padding: '0 5px',
  },
}))
