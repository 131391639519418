import isEmpty from "lodash/isEmpty"
import cleanDeep from "clean-deep"

export const criminalMapper = reportData => {
  let resultCriminal = {}
  if (!isEmpty(reportData)) {
    resultCriminal = {
      records: {
        criminalIdentityCount: reportData.CriminalIdentityCount,
        sexOffenderIdentityCount: reportData.SexOffenderIdentityCount,
        mostWantedIdentityCount: reportData.MostWantedIdentityCount,
        ofacIdentityCount: reportData.OFACIdentityCount,
        otherIdentityCount: reportData.OtherIdentityCount,
      },
      requestedConsumer: {
        ...reportData.RequestedConsumer,
      },
      identities: [...(reportData.Identities ?? [])],
    }
  }
  return cleanDeep(
    { ...resultCriminal },
    {
      emptyArrays: false,
      emptyObjects: false,
      emptyStrings: false,
      nullValues: true,
      undefinedValues: true,
    },
  )
}
