import React from 'react'
import { compose } from 'redux'
import get from 'lodash/get'
import OnImagesLoaded from 'react-on-images-loaded'
import { withRouter } from 'react-router-dom'

import { ReportLoading } from 'components/reports/loading'
import EvictionReportComponent from 'components/reports/screening-reports/shareable/eviction'
import { REPORT_TYPES } from '../../constants'
import { withConnect } from '../connect'
import withReportAvailable from '../../with-report-available'
import withExpired from '../with-expired'
import withScreeningReportError from '../with-error'
import { shouldGetCreditReport } from '../helper'

// TODO: lnr-2991 clean up duplicated container
export const ShareableEviction = ({
  creditReportDetail,
  isFetchingCreditReport,
  getCreditReport,
  fetchedEvictionImage,
  applicant,
  externalRenterDetail,
  externalLandlordDetail,
  generatedOn,
  expiresOn,
  rentalDetail,
  showHeaderComponent = true,
  customBorder,
  customMargin,
  applicationCreationDate,
  userType,
  match
}) => {
  React.useEffect(() => {
    if (shouldGetCreditReport(rentalDetail, creditReportDetail)) {
      getCreditReport(get(rentalDetail, '_id'))
    }
  }, [rentalDetail])

  if (isFetchingCreditReport) {
    return <ReportLoading />
  }
  const applicants = get(applicant, 'CreditReport[0].reportData.applicants')
  const evictionReportProps = {
    expiresOn,
    generatedOn,
    renter: externalRenterDetail,
    landlord: externalLandlordDetail,
    applicant: applicants ? applicants[0] : {},
    evictionRecords: get(applicant, 'EvictionRecords[0].reportData'),
    showHeaderComponent,
    applicationCreationDate,
    rentalDetail,
    userType, 
    match,
  }

  return (
    <OnImagesLoaded
      onLoaded={fetchedEvictionImage}
      onTimeout={fetchedEvictionImage}
      timeout={30000}
    >
      <EvictionReportComponent
        {...evictionReportProps}
        customBorder={customBorder}
        customMargin={customMargin}
      />
    </OnImagesLoaded>
  )
}

export default compose(
  withReportAvailable(REPORT_TYPES.EVICTION),
  withScreeningReportError,
  withExpired,
  withConnect,
  withRouter,
)(ShareableEviction)
