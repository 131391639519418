import produce from 'immer'
import { LEASE_AGREEMENTS } from 'constants/error-messages'
import {
  LEASE_AGREEMENT_DETAIL_REQUEST,
  LEASE_AGREEMENT_DETAIL_SUCCESS,
  LEASE_AGREEMENT_DETAIL_FAILURE,
  CLEAR_LEASE_AGREEMENT_DETAIL,
  OPEN_VOID_MODAL,
  CLOSE_VOID_MODAL,
  VOID_ENVELOPE_REQUEST,
  VOID_ENVELOPE_SUCCESS,
  VOID_ENVELOPE_FAILED,
  DELETE_ENVELOPE_REQUEST,
  DELETE_ENVELOPE_SUCCESS,
  DELETE_ENVELOPE_FAILED,
  CLEAR_VOID_STATUS,
  UPDATE_LEASE_AGREEMENT_CONSENT_FAILED,
  CLEAR_LEASE_ERROR,
  UPDATE_LEASE_AGREEMENT_CONSENT_REQUEST,
  RESEND_ENVELOPE_REQUEST,
  RESEND_ENVELOPE_SUCCESS,
  RESEND_ENVELOPE_FAILED,
  RESEND_ENVELOPE_CALL,
  UPDATE_LEASE_AGREEMENT_CONSENT_SUCCESS,
} from './constants'

export const initialState = {
  isLoading: false,
  isError: false,
  duration: '',
  error: null,
  isAccepting: false,
  isResending: false,
  isCloseModalResend: false,
  rentAmount: '',
  securityDeposit: '',
  title: '',
  isMonthToMonth: false,
  moveInDate: '',
  expiredDate: '',
  envelopes: [],
  status: '',
  propertyId: '',
  userId: '',
  isConsentSuccess: false,
  isShowVoidModal: false,
  isLoadVoid: false,
  isVoidSuccess: false,
  isLoadDelete: false,
  isDeleteSuccess: false,
}

/* eslint-disable default-case, no-param-reassign */
const leaseAgreementDetailReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case LEASE_AGREEMENT_DETAIL_REQUEST:
        draft.isLoading = true
        break
      case LEASE_AGREEMENT_DETAIL_SUCCESS:
        draft = {
          ...initialState,
          ...payload,
          isLoading: false,
          isError: false,
        }
        break
      case LEASE_AGREEMENT_DETAIL_FAILURE:
        draft.isLoading = false
        draft.isError = true
        break
      case CLEAR_LEASE_AGREEMENT_DETAIL:
        draft = initialState
        break
      // TODO: TECH-17060 [DUPLICATED_ALERT] moved to `lease-agreement-list` reducers already
      case OPEN_VOID_MODAL:
        draft.isShowVoidModal = true
        break
      case CLOSE_VOID_MODAL:
        draft.isShowVoidModal = false
        break
      case VOID_ENVELOPE_REQUEST: {
        draft.isLoadVoid = true
        break
      }
      case VOID_ENVELOPE_SUCCESS: {
        draft.isLoadVoid = false
        draft.isVoidSuccess = true
        draft.isShowVoidModal = false
        break
      }
      case VOID_ENVELOPE_FAILED: {
        draft.isLoadVoid = false
        draft.isVoidSuccess = false
        draft.isErrorVoid = true
        break
      }
      case DELETE_ENVELOPE_REQUEST: {
        draft.isLoadDelete = true
        draft.isDeleteSuccess = false
        break
      }
      case DELETE_ENVELOPE_SUCCESS: {
        draft.isLoadDelete = false
        draft.isDeleteSuccess = true
        break
      }
      case DELETE_ENVELOPE_FAILED: {
        draft.isLoadDelete = false
        draft.isDeleteSuccess = false
        break
      }
      case CLEAR_VOID_STATUS: {
        draft.isLoadVoid = false
        draft.isVoidSuccess = false
        draft.isErrorVoid = false
        break
      }
      case UPDATE_LEASE_AGREEMENT_CONSENT_FAILED: {
        draft.isAccepting = false
        draft.error = {
          errorMessage: LEASE_AGREEMENTS.CONSENT_ERROR.TITLE,
          secondaryMessage: LEASE_AGREEMENTS.CONSENT_ERROR.MESSAGE,
        }
        break
      }
      case CLEAR_LEASE_ERROR: {
        draft.error = null
        draft.isConsentSuccess = false
        break
      }
      case UPDATE_LEASE_AGREEMENT_CONSENT_REQUEST: {
        draft.isAccepting = true
        break
      }
      case UPDATE_LEASE_AGREEMENT_CONSENT_SUCCESS: {
        draft.isConsentSuccess = true
        break
      }
      case RESEND_ENVELOPE_CALL:
      case RESEND_ENVELOPE_REQUEST: {
        draft.isResending = true
        draft.isCloseModalResend = false
        break
      }
      case RESEND_ENVELOPE_SUCCESS: {
        draft.isResending = false
        draft.isCloseModalResend = true
        break
      }
      case RESEND_ENVELOPE_FAILED: {
        draft.isResending = false
        draft.isCloseModalResend = true
        break
      }
    }
    return draft
  })

export default leaseAgreementDetailReducer
