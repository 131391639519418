import { Box, FormControl } from '@mui/material'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { string, object } from 'yup'

import { updateProfile } from 'legacy/actions/user.action'
import { OVERHAUL_RENT_PAYMENT } from 'tracker/const'
import { DrawerFooter } from 'v3/containers/overhaul-rent-payment/components/footer/drawer-footer'
import { buttonLayoutChoices } from 'v3/containers/overhaul-rent-payment/components/footer/utils'
import { stepperFormGroups } from 'v3/containers/overhaul-rent-payment/components/shared-style'
import { StyledTextField } from 'v3/containers/overhaul-rent-payment/components/text/text-field'
import { RECIPIENT_DETAILS } from 'v3/containers/overhaul-rent-payment/text-constants'
import { trackClick } from 'v3/utils/tracker-utils'

export const SelfDetailsDrawer = ({ renterInfo, setRenterInfoPiece }) => {
  const { TITLE, FIRST_NAME_LABEL, LAST_NAME_LABEL } = RECIPIENT_DETAILS.SELF_DETAILS

  const dispatch = useDispatch()

  const validationSchema = object({
    firstName: string().min(1).required(),
    lastName: string().min(1).required(),
  })

  const formik = useFormik({
    initialValues: {
      firstName: renterInfo?.firstName ?? null,
      lastName: renterInfo?.lastName ?? null,
    },
    validateOnMount: true,

    validationSchema,
  })

  useEffect(() => {
    const { firstName, lastName } = formik.values
    setRenterInfoPiece({ firstName, lastName, email: null })
  }, [formik.values.firstName, formik.values.lastName])

  const updateUserProfileName = data => {
    const { firstName, lastName } = data
    if (!isEmpty(firstName) && !isEmpty(lastName)) {
      dispatch(updateProfile({ lastName, firstName }))
    }
  }

  return (
    <Box
      sx={stepperFormGroups}
      data-page-url={window.location.href}
      data-event="element_visibility"
      data-step={OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.STEP.WHO_RECEIVES_THIS_PAYMENT}
      data-location={OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.LOCATION.ADD_NAME_ON_FILE}
      data-main-feature={OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.MAIN_FEATURE.OVERHAUL_RENT_PAYMENT}
      className="visibility-tracking element_visibility"
    >
      <Typography variant="title-medium">{TITLE}</Typography>
      {/* TODO: skip if details can be found elsewhere? */}
      <form onSubmit={formik.handleSubmit}>
        <FormControl fullWidth defaultValue="" required>
          <StyledTextField
            id="firstName"
            label={FIRST_NAME_LABEL}
            variant="outlined"
            required
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            {...formik.getFieldProps('firstName')}
            helperText={formik.touched.firstName && formik.errors.firstName}
            size="normal"
          />
          <StyledTextField
            id="lastName"
            label={LAST_NAME_LABEL}
            variant="outlined"
            required
            defaultValue={renterInfo?.lastName}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            {...formik.getFieldProps('lastName')}
            helperText={formik.touched.lastName && formik.errors.lastName}
            size="normal"
          />
        </FormControl>
        <DrawerFooter
          buttonLayout={buttonLayoutChoices.NEXT_BACK}
          textOverrides={{ back: 'Cancel', next: 'Save' }}
          nextEnabled={formik.isValid}
          onBack={() =>
            trackClick(OVERHAUL_RENT_PAYMENT.EVENT_NAME.COLLECT_PAYMENTS_CLICK, {
              click_text: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.CLICK_TEXT.CANCEL,
              location: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.LOCATION.ADD_NAME_ON_FILE,
              step: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.STEP.WHO_RECEIVES_THIS_PAYMENT,
            })
          }
          onNext={() => {
            updateUserProfileName(renterInfo)
            trackClick(OVERHAUL_RENT_PAYMENT.EVENT_NAME.COLLECT_PAYMENTS_CLICK, {
              click_text: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.CLICK_TEXT.SAVE,
              location: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.LOCATION.ADD_NAME_ON_FILE,
              step: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.STEP.WHO_RECEIVES_THIS_PAYMENT,
            })
          }}
        />
      </form>
    </Box>
  )
}
