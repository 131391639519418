import { FormControl, Box } from '@mui/material'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { string, object } from 'yup'

import { OVERHAUL_RENT_PAYMENT } from 'tracker/const'
import { DrawerFooter } from 'v3/containers/overhaul-rent-payment/components/footer/drawer-footer'
import { buttonLayoutChoices } from 'v3/containers/overhaul-rent-payment/components/footer/utils'
import { stepperFormGroups } from 'v3/containers/overhaul-rent-payment/components/shared-style'
import { StyledTextField } from 'v3/containers/overhaul-rent-payment/components/text/text-field'
import { RECIPIENT_DETAILS } from 'v3/containers/overhaul-rent-payment/text-constants'
import { trackClick } from 'v3/utils/tracker-utils'

export const ClientDetailsDrawer = ({ renterInfo, setRenterInfoPiece }) => {
  const { TITLE, SUBTITLE, FIRST_NAME_LABEL, LAST_NAME_LABEL, EMAIL_LABEL } =
    RECIPIENT_DETAILS.CLIENT_DETAILS

  const validationSchema = object({
    email: string().email('Email must be a valid email').required('Email required'),
    firstName: string().min(1).required('First name required'),
    lastName: string().min(1).required('Last name required'),
  })

  const formik = useFormik({
    initialValues: {
      firstName: renterInfo?.firstName ?? '',
      lastName: renterInfo?.lastName ?? '',
      email: renterInfo?.email ?? '',
    },
    validateOnMount: true,

    validationSchema,
  })

  useEffect(() => {
    const { firstName, lastName, email } = formik.values
    setRenterInfoPiece({ firstName, lastName, email })
  }, [formik.values.firstName, formik.values.lastName, formik.values.email])

  return (
    <Box
      sx={stepperFormGroups}
      data-step={OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.STEP.WHO_RECEIVES_THIS_PAYMENT}
      data-location={OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.LOCATION.ADD_CLIENT_DETAILS_MODAL}
      data-main-feature={OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.MAIN_FEATURE.OVERHAUL_RENT_PAYMENT}
      className="visibility-tracking element_visibility"
      data-page-url={window.location.href}
    >
      <Typography variant="title-medium">{TITLE}</Typography>
      <Typography variant="body">{SUBTITLE}</Typography>
      <FormControl fullWidth required>
        <StyledTextField
          id="firstName"
          label={FIRST_NAME_LABEL}
          variant="outlined"
          size="normal"
          required
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
          {...formik.getFieldProps('firstName')}
        />
        <StyledTextField
          id="lastName"
          label={LAST_NAME_LABEL}
          variant="outlined"
          size="normal"
          required
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
          {...formik.getFieldProps('lastName')}
        />
        <StyledTextField
          id="email"
          label={EMAIL_LABEL}
          variant="outlined"
          size="normal"
          required
          {...formik.getFieldProps('email')}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </FormControl>
      <DrawerFooter
        buttonLayout={buttonLayoutChoices.NEXT_BACK}
        textOverrides={{ back: 'Cancel', next: 'Save' }}
        nextEnabled={formik.isValid}
        onBack={() =>
          trackClick(OVERHAUL_RENT_PAYMENT.EVENT_NAME.COLLECT_PAYMENTS_CLICK, {
            click_text: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.CLICK_TEXT.CANCEL,
            step: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.STEP.WHO_RECEIVES_THIS_PAYMENT,
            destination: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.DESTINATION.STEP_1_RECIPIENT_DETAILS,
            location: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.LOCATION.ADD_CLIENT_DETAILS_MODAL,
          })
        }
        onNext={() =>
          trackClick(OVERHAUL_RENT_PAYMENT.EVENT_NAME.COLLECT_PAYMENTS_CLICK, {
            click_text: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.CLICK_TEXT.SAVE,
            step: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.STEP.WHO_RECEIVES_THIS_PAYMENT,
            destination: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.DESTINATION.STEP_1_RECIPIENT_DETAILS,
            location: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.LOCATION.ADD_CLIENT_DETAILS_MODAL,
          })
        }
      />
    </Box>
  )
}
