import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import './pdf-overlay-page-controller.scss'

const PdfOverlayPageController = ({ currentPageIndex, totalPage, onChange }) => {
  const [page, setPage] = useState(currentPageIndex + 1)

  useEffect(() => {
    setPage(currentPageIndex + 1)
  }, [currentPageIndex])

  const handleChange = event => {
    setPage(event.target.value)
  }
  const handleBlur = event => {
    const value = parseInt(event.target.value, 10)
    if (value > 0 && value <= totalPage) {
      onChange(value - 1)
    } else {
      // eslint-disable-next-line no-param-reassign
      event.target.value = currentPageIndex + 1
    }
  }
  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      event.target.blur()
    }
  }

  return (
    <Typography className="RSPPdfOverlayPageController" variant="body-medium">
      <input
        className='RSPPdfOverlayPageController-input'
        type="number"
        value={page}
        min={1}
        max={totalPage}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
      <span>/</span>
      <span>{totalPage}</span>
    </Typography>
  )
}

export default PdfOverlayPageController
