import { Box } from '@mui/material'
import TakeoverTemplate from '@rentspree/component-2023.components.templates.takeover-template'
import debounce from 'lodash/debounce'
import React, { useState, useCallback } from 'react'

import { RENT_PAYMENT } from 'constants/route'
import { RentPaymentFooter } from 'v3/containers/overhaul-rent-payment/components/footer/rent-payment-footer'
import { RentPaymentStepper } from 'v3/containers/overhaul-rent-payment/components/stepper/rent-payment-stepper'
import { pageNames, pages } from 'v3/containers/overhaul-rent-payment/flow-pages'

import {
  PageIndexesContext,
  pageIndexesTemplate,
  PageProgressionContext,
  PaymentInfoContext,
  HistoryContext,
} from '../context'

import { smartNextIndexesCalc } from './utils'

export const OverhaulRentPaymentPageRoot = ({ history }) => {
  /*
   * The root page for the rent-payment flow, orchestrating the other pages within the pages/ subfolders
   * along with the general organization.
   *
   * Pages are broken out separately, and the order-consistent 'pages' variable below + pageIndexes keeps
   * track of the user's page state through the flow.
   */

  const [paymentInfo, setPaymentInfo] = useState()
  const [pageIndexes, setPageIndexes] = useState(pageIndexesTemplate)
  const [historyObj] = useState(history)
  const [isValid, setIsValid] = useState(false)

  const debouncedSetPageIndexes = useCallback(
    debounce(nextIndexes => {
      setPageIndexes(smartNextIndexesCalc(nextIndexes, pageIndexes))
    }, 200),
    [pageIndexes],
  )
  const key = pageNames[pageIndexes.pageL1Index]
  const { pageField, progressionHandler, Page, buttonLayout, textOverrides } = pages.get(key)
  const backupProgressionHandler = ({ increment = false, decrement = false }) => {
    debouncedSetPageIndexes({
      pageL1Index: Math.min(
        pageNames.length - 1,
        Math.max(0, pageIndexes.pageL1Index + increment - decrement),
      ),
    })
  }

  const contextProgressionHandler =
    progressionHandler === null ? backupProgressionHandler : progressionHandler

  const onClose = () => {
    history.push(RENT_PAYMENT)
  }

  /*
   * leveraging useCallback to save some rendering power, slight workaround for inheritance forcing hook vars be passed in
   * Allows rendering callers to just worry about increment/decrement below regardless
   */
  const simplifiedProgressionHandler = useCallback(
    ({ increment = false, decrement = false }) =>
      contextProgressionHandler(
        { increment, decrement },
        pageIndexes,
        debouncedSetPageIndexes,
        pageField,
        paymentInfo,
      ),
    [pageIndexes, debouncedSetPageIndexes, pageField, paymentInfo],
  )

  const updatePageDataHandler = newData => {
    // should only change data under the page's dedicated field, while keeping all page data in one object
    setPaymentInfo(prevState => {
      return { ...prevState, [pageField]: newData }
    })
  }

  return (
    /*
     * Disabling explainer:
     * seems leveraging an array instantiation in general throws a useMemo error, but useMemo would just change every time they changed as well...
     * StackOverflow suggests it's a potentially out of date linting rule: https://stackoverflow.com/q/71454846
     * Open to refactoring if internal best practices prefer the useMemo approach still
     */
    /* eslint-disable react/jsx-no-constructed-context-values */
    <PageIndexesContext.Provider value={[pageIndexes, debouncedSetPageIndexes]}>
      <TakeoverTemplate
        closeButtonProps={{
          isIconButton: true,
          onClose,
          closeIconButtonProp: {
            color: 'black',
          },
        }}
        // Todo: Enable component native navigation options
        navigationProps={{
          isHidden: true,
        }}
      >
        <Box
          sx={{
            width: { xs: 'calc(100vw - 32px)', md: '100%' },
            justifySelf: 'center',
          }}
        >
          <RentPaymentStepper
            stepTitles={pageNames}
            pageIndex={pageIndexes.pageL1Index}
            handleClick={i => debouncedSetPageIndexes({ pageL1Index: i })}
          />
          <PaymentInfoContext.Provider value={[paymentInfo, setPaymentInfo]}>
            <PageProgressionContext.Provider value={simplifiedProgressionHandler}>
              <HistoryContext.Provider value={historyObj}>
                <Box
                  sx={{
                    fontFamily: 'Inter',
                    marginLeft: 0,
                    marginBottom: '108px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'flex-start',
                    flexDirection: 'column',
                    textAlign: 'left',
                  }}
                  spacing={2}
                >
                  <Box
                    style={{
                      width: '100%',
                      maxWidth: '450px',
                      margin: 'auto',
                      justifyContent: 'left',
                      alignContent: 'center',
                    }}
                  >
                    <Page
                      setIsValid={setIsValid}
                      pageField={pageField}
                      updateDataHandler={updatePageDataHandler}
                      isValid={isValid}
                    />
                  </Box>
                </Box>
                <RentPaymentFooter
                  pagesIndexes={pageIndexes}
                  buttonLayout={buttonLayout}
                  textOverrides={textOverrides}
                  isNextEnabled={isValid}
                />
              </HistoryContext.Provider>
            </PageProgressionContext.Provider>
          </PaymentInfoContext.Provider>
        </Box>
      </TakeoverTemplate>
    </PageIndexesContext.Provider>
  )
}
