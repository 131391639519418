import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { selectDisabledPropertyFeatures } from 'containers/property/toggle-property-feature/selectors'
import { continueToRentPayment, openAcceptDenyModal } from 'containers/accept-deny/actions'
import {
  openTogglePropertyFeatureModal,
  disableFeature,
  enableFeature,
} from 'containers/property/toggle-property-feature/actions'
import { selectIsAllowCarLease } from 'containers/wrapper/selectors'
import * as ApplicationListActions from '../../actions/application-list.action'
import * as ModalActions from '../../actions/modals.action'
import * as PropertyActions from '../../actions/property.action'
import * as AnnouncementActions from '../../actions/announcement.action'

export default ComposedComponent =>
  connect(
    state => ({
      applicationList: state.applicationList,
      property: state.property && state.property.property,
      isPropertyFetching:
        state.property && (state.property.isFetching || state.property.isFetchingInactive),
      creditReport: state.creditReport,
      showDeactivatePropertyModal: state.modals.showDeactivatePropertyModal,
      showReactivatePropertyModal: state.modals.showReactivatePropertyModal,
      showPostLogInAdvertiseModal: state.modals.showPostLogInAdvertiseModal,
      postLogInAdvertiseChecked: state.announcement.postLogInAdvertiseChecked,
      disabledFeatures: selectDisabledPropertyFeatures(state),
      isAllowCarLease: selectIsAllowCarLease(state),
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          ...ApplicationListActions,
          ...ModalActions,
          ...PropertyActions,
          ...AnnouncementActions,
          openTogglePropertyFeatureModal,
          disableFeature,
          enableFeature,
          openAcceptDenyModal,
          continueToRentPayment,
        },
        dispatch,
      ),
      dispatch,
    }),
  )(ComposedComponent)
