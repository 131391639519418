import React from 'react'
import Box from '@mui/material/Box'
import { Pagination } from '@rentspree/component-2023.components.organisms.pagination'

import './index.scss'

export const TenantScreeningPagination = ({ pagination, handleChangePage }) => {
  const { page, maxPage } = pagination
  const onPageChange = (event, current) => {
    handleChangePage(current)
  }

  if (maxPage <= 1) {
    return null
  }

  return (
    <Box className="screening-table-pagination-container" data-testid="screening-table-pagination">
      <Pagination count={maxPage} page={page} onChange={onPageChange} />
    </Box>
  )
}
