/**
 * Get outta here (please)! As of November 2024, please add any new routes to the non-legacy file found at 'app/constants/routes'
 * The remaining routes in this file are pending action. This parent ticket has been created to track the progress: [TECH-22254](https://rentspree.atlassian.net/browse/TECH-22254)
 */

import { API_URL } from '../../env'

export const SUPPORT_URL = process.env.SUPPORT_URL || 'https://support.rentspree.com'
export const SUPPORT_HELP_PRO_URL = `${SUPPORT_URL}/for-agents-owners-managers#rentspree-pro` // TODO_EMILY: this URL is different than in current routes
export const SUPPORT_FAQ_URL = `${SUPPORT_URL}/en/refer-earn-referral-program-terms-and-conditions` // TODO_EMILY: this URL is different than in current routes

// OUTSIDE PATH
export const UPLOAD_DOCUMENT_VIDEO = 'https://www.youtube.com/embed/aSETxOitHVQ?rel=0&showinfo=0' // TODO_EMILY: this URL is different than in current routes
export const HOW_TO_REQUEST_DOC_VIDEO = 'https://www.youtube.com/embed/K2E-zKC3-KM?rel=0&showinfo=0' // TODO_EMILY: this URL is different than in current routes

// Lease Agreement Page
export const LEASE_AGREEMENT_PAGE = `/properties/:propertyId/esign-documents` // TODO_EMILY: is this an actual duplicate (with a differnt name tho), or am I missing something?
export const LEASE_AGREEMENT_REQUEST = `${LEASE_AGREEMENT_PAGE}/request` // // TODO_EMILY: this URL is different than in current routes

// RENT PAYMENT
// const RENT_PAYMENT_PAGE = `/properties/:propertyId/rent-payments` // TODO_EMILY: this becomes RENT_PAYMENT_PROPERTY_PAGE

// POLLING SERVICE
const POLLING_SERVICE = `${API_URL}/api/v2/files`
export const GENERATE_REPORT_FILES = `${POLLING_SERVICE}/reports` // no new endpoint that matches
export const POLLING_REPORT_FILES_STATUS = `${POLLING_SERVICE}/reports/:transactionId/status` // no new endpoint that matches
