import Level1Template from '@rentspree/component-2023.components.templates.level-1-template'
import React from 'react'
import EmptyState from '@rentspree/component-2023.components.templates.empty-state'
import Button from '@rentspree/component-2023.components.atoms.button'

export const NewMessageCTA = ({ onNewMessageClick, color = 'primary' }) => (
  <Button
    onClick={onNewMessageClick}
    size="small"
    variant="contained"
    color={color}
    data-testid="new-message-cta-click"
  >
    Send message
  </Button>
)

export const MessageLandings = ({ isMobile, onNewMessageClick }) => (
  <>
    <>
      <div className="messaging-landing messaging-landing-container">
        <Level1Template
          title="Messages"
          cta={
            <NewMessageCTA
              onNewMessageClick={() => onNewMessageClick('title_cta')}
              color="secondary"
            />
          }
          isMobile={isMobile}
        />
        <div className="messaging-landing messaging-landing-empty-state">
          <EmptyState
            image={
              <img
                src="https://cdn.rentspree.com/static-files/zero-state/messaging-empty-state.png"
                alt="empty state"
              />
            }
            title="Start a conversation"
            subTitle="Keep your exchanges with applicants, landlords, and other agents organized and in one spot."
            cta={
              <>
                <NewMessageCTA
                  onNewMessageClick={() => onNewMessageClick('empty_state')}
                  color="secondary"
                />
              </>
            }
          />
        </div>
      </div>
    </>
    )
  </>
)
