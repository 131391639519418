import React, { useState } from 'react'
import { Dialog } from '@mui/material'
import { Document } from 'react-pdf/dist/esm/entry.webpack5'
import Snackbar from '@rentspree/component-2023.components.atoms.snackbar'
import { useScreenSize } from '../../hooks/use-screen-size'
import PdfOverlayContent from './pdf-overlay-content/pdf-overlay-content'
import PdfOverlayHeader from './pdf-overlay-header/pdf-overlay-header'
import PdfOverlayPageController from './pdf-overlay-header/pdf-overlay-page-controller'
import PdfOverlayScaleController from './pdf-overlay-header/pdf-overlay-scale-controller'
import PdfOverlaySidebarPdf from './pdf-overlay-sidebar/pdf-overlay-sidebar'
import './pdf-overlay.scss'
import PdfOverlayLoading from './pdf-overlay-loading'

const PdfOverlay = ({ show, pdfFile, title, onClose }) => {
  const [isError, setIsError] = useState(false)
  const [totalPage, setTotalPage] = useState(0)
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [scale, setScale] = useState(1)

  const { isDesktop } = useScreenSize()

  const handleClose = () => {
    onClose()
  }
  const handleCloseSnackbar = () => {
    setIsError(false)
    onClose()
  }

  const handleScaleChange = newScale => {
    setScale(newScale)
  }
  const handlePaginationChange = pageIndex => {
    setCurrentPageIndex(pageIndex)
  }
  const handleThumbnailClick = pageIndex => {
    setCurrentPageIndex(pageIndex)
  }
  const handleContentChange = pageIndex => {
    setCurrentPageIndex(pageIndex)
  }

  const onDocumentLoadSuccess = params => {
    setTotalPage(params.numPages)
  }
  const onDocumentLoadError = () => {
    // TODO: Tracking and Receive handler callback
    setIsError(true)
  }

  if (!show) {
    return null
  }

  if (isError) {
    return (
      <Snackbar
        sx={{ zIndex: 2000 }}
        open={isError}
        message="Unable to open PDF. Please try again."
        onClose={handleCloseSnackbar}
      />
    )
  }

  return (
    <Document
      file={pdfFile}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadError={onDocumentLoadError}
      error={null}
      loading={<PdfOverlayLoading show={show} title={title} onClose={handleClose} />}
    >
      <Dialog
        data-testid='pdf-overlay'
        className="RSPPdfOverlay"
        open={show}
        fullScreen
        hideBackdrop
        scroll="paper"
      >
        <PdfOverlayHeader title={title} onClose={handleClose}>
          <PdfOverlayPageController
            totalPage={totalPage}
            currentPageIndex={currentPageIndex}
            onChange={handlePaginationChange}
          />
          <PdfOverlayScaleController scale={scale} onChange={handleScaleChange} />
        </PdfOverlayHeader>
        {isDesktop && (
          <PdfOverlaySidebarPdf
            totalPage={totalPage}
            currentPageIndex={currentPageIndex}
            onClick={handleThumbnailClick}
          />
        )}
        <PdfOverlayContent
          totalPage={totalPage}
          scale={scale}
          currentPageIndex={currentPageIndex}
          onChange={handleContentChange}
        />
      </Dialog>
    </Document>
  )
}

export default PdfOverlay
