import React from 'react'

import { Typography } from '@rentspree/component-2023.components.atoms.typography'

import CardElement from '@rentspree/component-2023.components.organisms.card-element'
import Card from '@rentspree/component-2023.components.organisms.card'
import { generateAddress } from '@rentspree/helper/build/generate-address'
import { DropdownWithUseDisableFeature } from 'components/property-list/dropdown-with-use-disable-feature'
import { Box } from '@mui/material'
import HomeIcon from '@mui/icons-material/HomeOutlined'

import './table-mobile-card.scss'
import Button from '@rentspree/component-2023.components.atoms.button'
import { buildPath } from '@rentspree/path'
import { PROPERTY_OVERVIEW_PAGE } from 'constants/route'

export const TableMobileCard = ({
  property,
  index,
  isHighlightScreenTenant,
  setIsHighlightScreenTenant,
  onArchiveProperty,
  onUnarchiveProperty,
  history,
}) => {
  const propertyAddress = generateAddress(property)
  const { _id: propertyId } = property

  const clickPropertyRow = () => {
    const url = buildPath(PROPERTY_OVERVIEW_PAGE, {
      propertyId,
    })
    history.push(url)
  }

  const isArchived = !!property.archived
  return (
    <Card
      variant="outlined"
      onClick={() => clickPropertyRow()}
      className="property-table-mobile-card"
      data-testid={`property-table-row-${index}`}
    >
      <CardElement className="property-table-mobile-card-element">
        <Box className="property-box">
          <HomeIcon
            name="listing"
            id={`propertyItem${index}Icon`}
            color={isArchived ? 'disabled' : 'unset'}
            sx={{ fontSize: '24px' }}
          />
          <Typography disabled variant="body-medium" className="ellipsis-text">
            {propertyAddress}
          </Typography>
        </Box>
        <Box>
          {isArchived ? (
            <Button
              variant="text"
              color="secondary"
              className="underline"
              size="small"
              onClick={e => {
                e.stopPropagation()
                onUnarchiveProperty(propertyId)
              }}
            >
              Unarchive
            </Button>
          ) : (
            <DropdownWithUseDisableFeature
              id={`DropdownAction${index}`}
              property={property}
              clickArchiveProperty={onArchiveProperty}
              history={history}
              isRedesignSelectTemplateEnabled
              isHighlightScreenTenant={isHighlightScreenTenant && index === 0}
              setIsHighlightScreenTenant={setIsHighlightScreenTenant}
              isNewUi
            />
          )}
        </Box>
      </CardElement>
    </Card>
  )
}
