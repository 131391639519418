import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { convert } from 'helpers/convert'
import {
  FontReport,
  Panel,
  PanelContainer,
  RentSpreeLogo,
  TULogo,
} from 'legacy/components/reports-v2/share/doc-style'

const transImg = require('../../../../../legacy/images/credit-report/TU-logo.png')
const rentImg = require('../../../../../legacy/images/logos/rentspree-logo.svg')

export const HeaderDoc = ({
  submittedAt,
  text = 'Rental Application',
  transUnion,
  ssnMessage = 'N/A',
}) => {
  const generatedOn = transUnion?.generatedOn
  const expiresOn = transUnion?.expiresOn
  const isTransUnionReport = !isEmpty(transUnion)
  const formatDate = 'MMM DD, YYYY'
  const dateType = 'dateOfLocal'
  return (
    <Panel Row Section Header>
      <PanelContainer Row>
        {isTransUnionReport ? (
          <TULogo src={transImg} data-testid="tu-logo" />
        ) : (
          <RentSpreeLogo src={rentImg} data-testid="rent-img" />
        )}
      </PanelContainer>
      <PanelContainer rowFlex>
        <FontReport HeaderB data-testid="report-type">
          {text}
        </FontReport>
        {submittedAt && (
          <FontReport
            HeaderN
            WhiteGray
            EtcDetail
            data-testid="submitted-at"
          >{`Submitted on ${convert(submittedAt, dateType, formatDate)}`}</FontReport>
        )}
        {generatedOn && (
          <FontReport HeaderN WhiteGray Small data-testid="generated-on">{`Generated on ${convert(
            generatedOn,
            dateType,
            formatDate,
          )}`}</FontReport>
        )}
        {expiresOn && (
          <FontReport HeaderN WhiteGray Small data-testid="expires-on">{`Expires on  ${convert(
            expiresOn,
            dateType,
            formatDate,
          )}`}</FontReport>
        )}
        <FontReport HeaderN Small data-testid="ssn-message">
          SSN Message: {ssnMessage}
        </FontReport>
      </PanelContainer>
    </Panel>
  )
}
