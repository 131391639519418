import isArray from 'lodash/isArray'
import forEach from 'lodash/forEach'
import cleanDeep from 'clean-deep'

export const evictionMapper = evictionRecords => {
  const resultEviction = []
  if (isArray(evictionRecords)) {
    forEach(evictionRecords, evictionRecord => {
      const {
        City,
        FirstName,
        LastName,
        MiddleName,
        SourceState: State,
        PostalCode: ZipCode,
        Address1: StreetAddress,
        Events,
        ...eviction
      } = evictionRecord

      const Subject = {
        City,
        State,
        ZipCode,
        LastName,
        FirstName,
        MiddleName,
        StreetAddress,
      }

      resultEviction.push({
        State,
        Events,
        Subject,
        ...eviction,
      })
    })
  }
  return cleanDeep([...resultEviction], {
    emptyArrays: false,
    emptyObjects: false,
    emptyStrings: false,
    nullValues: true,
    undefinedValues: true,
  })
}

export const evictionMapperV2 = evictionRecords => {
  const resultEviction = []
  if (isArray(evictionRecords)) {
    forEach(evictionRecords, evictionRecord => {
      const {
        subject: {
          city: City,
          firstName: FirstName,
          lastName: LastName,
          middleName: MiddleName,
          zip: ZipCode,
          address: StreetAddress,
          state: State
        },
        ...eviction
      } = evictionRecord

      const Subject = {
        City,
        State,
        ZipCode,
        LastName,
        FirstName,
        MiddleName,
        StreetAddress
      }

      resultEviction.push({
        Subject,
        ...eviction,
      })
    })
  }
  return cleanDeep([...resultEviction], {
    emptyArrays: false,
    emptyObjects: false,
    emptyStrings: false,
    nullValues: true,
    undefinedValues: true,
  })
}
