import { Box } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@rentspree/component-2023.components.atoms.radio'
import RadioGroup from '@rentspree/component-2023.components.atoms.radio-group'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { selectProfile } from 'containers/user/selectors'
import { OVERHAUL_RENT_PAYMENT } from 'tracker/const'
import { PaymentsDrawer } from 'v3/containers/overhaul-rent-payment/components/drawer/drawer'
import {
  recipientDetailsOptions,
  recipientFields,
} from 'v3/containers/overhaul-rent-payment/constants'
import {
  PageIndexesContext,
  PaymentInfoContext,
  PageProgressionContext,
} from 'v3/containers/overhaul-rent-payment/context'
import { ClientDetailsDrawer } from 'v3/containers/overhaul-rent-payment/pages/recipient-details/drawer-states/client-details'
import { SelfDetailsDrawer } from 'v3/containers/overhaul-rent-payment/pages/recipient-details/drawer-states/self-details'
import {
  validateRecipient,
  validateObject,
  validateRecipientInfo,
} from 'v3/containers/overhaul-rent-payment/pages/recipient-details/validators'
import { RECIPIENT_DETAILS } from 'v3/containers/overhaul-rent-payment/text-constants'
import { trackClick } from 'v3/utils/tracker-utils'

import { stepperFormGroups } from '../../components/shared-style'

export const WideButtonFormControlLabel = styled(FormControlLabel)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 20px',

  border: '2px solid #2222221f',
  borderRadius: '10px',
  backgroundColor: 'white',

  width: '50%',
  maxWidth: '500px',
  minWidth: '350px',
  height: '6rem',

  '&:hover': {
    border: '2px solid #222222',
  },
}))

export const handleRecipientDetailsProgression = (
  { increment = false, decrement = false },
  curPageIndexes,
  setPageIndexes,
  pageField,
  curPaymentInfo,
) => {
  /* Returns the page index changes expected for an increment/decrement, based on the current page & data states */

  const indexChanges = {
    pageL1Index: curPageIndexes.pageL1Index,
    drawerOpen: curPageIndexes.drawerOpen,
  }

  // shortcut invalid scenarios
  if (!(decrement || increment) || (increment && decrement)) {
    /*
     * logging an error, but this can still be consumer facing, so staying vague
     * need to find direct DD log avenue for debug details if possible
     */
    console.error('Unusual state for Recipient Details page progression request')
  } else {
    if (increment) {
      const pageFieldPresent = Object.keys(curPaymentInfo).includes(pageField)
      const recipientDataEntered = validateRecipient(curPaymentInfo[pageField]?.recipient)
      const allDataEnteredAndValid = validateObject(curPaymentInfo[pageField])
      if (curPageIndexes.drawerOpen && pageFieldPresent && allDataEnteredAndValid) {
        // if the drawer is open & all info is filled out, we can progress to the next page
        indexChanges.pageL1Index += 1
        // drawer resetting should be handled by the smart index progression method, but add safety in redundancy

        trackClick(OVERHAUL_RENT_PAYMENT.EVENT_NAME.COLLECT_PAYMENTS_CLICK, {
          step: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.STEP.WHO_RECEIVES_THIS_PAYMENT,
          payment_receiver:
            curPaymentInfo?.[recipientFields.recipient] === recipientDetailsOptions.ME
              ? OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.PAYMENT_RECEIVER.ME_OR_MY_BUSINESS
              : OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.PAYMENT_RECEIVER.MY_CLIENT,
          destination: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.DESTINATION.STEP_2_PROPERTY_TENANT,
        })

        indexChanges.drawerOpen = false
      } else if (recipientDataEntered) {
        if (
          recipientDetailsOptions.ME === curPaymentInfo[pageField]?.recipient &&
          allDataEnteredAndValid
        ) {
          indexChanges.pageL1Index += 1

          trackClick(OVERHAUL_RENT_PAYMENT.EVENT_NAME.COLLECT_PAYMENTS_CLICK, {
            step: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.STEP.WHO_RECEIVES_THIS_PAYMENT,
            payment_receiver:
              curPaymentInfo?.[recipientFields.recipient] === recipientDetailsOptions.ME
                ? OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.PAYMENT_RECEIVER.ME_OR_MY_BUSINESS
                : OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.PAYMENT_RECEIVER.MY_CLIENT,
            destination: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.DESTINATION.SET_UP_PAYMENT_FOR_CLIENT,
          })
          // if the recipient is selected, data is not entered, and the drawer isn't open, open the drawer
        } else {
          indexChanges.drawerOpen = true
        }
      }
    }

    if (decrement) {
      // we have no prior page to go back to in this flow, so only the drawer can be closed
      indexChanges.drawerOpen = false
    }
  }
  /*
   * All decrement states for this page involve going back to the root of the flow
   * Absorbing errored-state traversal handling as well
   */
  setPageIndexes(indexChanges)
}

export const RecipientDetailsPage = ({ pageField, updateDataHandler, setIsValid }) => {
  const [pageIndexes] = useContext(PageIndexesContext)
  const [paymentInfo] = useContext(PaymentInfoContext)
  const progressionHandler = useContext(PageProgressionContext)
  const profile = useSelector(selectProfile)

  const pageInfo = paymentInfo?.[pageField] || {}
  const renterRecipient = pageInfo?.[recipientFields.recipient]
  const renterInfo = pageInfo?.[recipientFields.recipientInfo] || {}

  // don't spam state variable changes & page re-rendering while someone's typing in the drawers
  const setRenterInfoPiece = newRenterInfoPiece => {
    updateDataHandler({
      // leave the other non-recipient-info sub-fields for the page's other info alone
      ...pageInfo,
      // change only what we care about
      [recipientFields.recipientInfo]: { ...renterInfo, ...newRenterInfoPiece },
    })
  }

  useEffect(() => {
    if (renterRecipient) {
      if (
        recipientDetailsOptions.ME === renterRecipient &&
        profile?.firstName &&
        profile?.lastName
      ) {
        setRenterInfoPiece({
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: null,
        })
      } else if (!validateRecipientInfo(renterRecipient, renterInfo)) {
        progressionHandler({ increment: true })
      }
    }
    setIsValid(!!renterRecipient)
  }, [renterRecipient])

  const drawerSelectionObj = {
    [recipientDetailsOptions.CLIENT]: (
      <ClientDetailsDrawer
        renterInfo={renterInfo}
        setRenterInfoPiece={setRenterInfoPiece}
        data-event="element_visibility"
        data-location="add_client_details_modal"
        className="visibility-tracking element_visibility add_client_details_modal"
      />
    ),
    [recipientDetailsOptions.ME]: (
      <SelfDetailsDrawer
        renterInfo={renterInfo}
        setRenterInfoPiece={setRenterInfoPiece}
        data-event="element_visibility"
        data-location="add_name_on_file"
        className="visibility-tracking element_visibility add_self_details_modal"
      />
    ),
  }
  const getDrawerContents = () => {
    if (drawerSelectionObj?.[renterRecipient] === undefined) {
      return <Typography level="h3">{RECIPIENT_DETAILS.SELECTION_ERROR}</Typography>
    }
    return drawerSelectionObj[renterRecipient]
  }

  return (
    <Box sx={stepperFormGroups}>
      <Typography variant="title-medium">{RECIPIENT_DETAILS.TITLE}</Typography>
      <RadioGroup
        sx={{ alignContent: 'center', padding: 0 }}
        onChange={event => {
          updateDataHandler({
            ...pageInfo,
            recipient: event.target.value,
            'recipient-info': { firstName: '', lastName: '', email: '' },
          })
        }}
      >
        <WideButtonFormControlLabel
          sx={{ width: '100%', height: '5rem', margin: '0px 0px 16px 0px' }}
          control={
            <Radio
              inputProps={{
                'data-testid': `radio-button-${recipientDetailsOptions.ME.toLowerCase()}`,
              }}
              checked={renterRecipient === recipientDetailsOptions.ME}
            />
          }
          labelPlacement="start"
          label="Me or my business"
          value={recipientDetailsOptions.ME}
        />
        <WideButtonFormControlLabel
          sx={{ width: '100%', height: '5rem', margin: '0px 0px 16px 0px' }}
          control={
            <Radio
              inputProps={{
                'data-testid': `radio-button-${recipientDetailsOptions.CLIENT.toLowerCase()}`,
              }}
              checked={renterRecipient === recipientDetailsOptions.CLIENT}
            />
          }
          labelPlacement="start"
          label="My client"
          value={recipientDetailsOptions.CLIENT}
        />
      </RadioGroup>
      {/* TODO: ask Jackie if we should be pulling User data & skipping this drawer if possible */}
      <PaymentsDrawer drawerOpen={pageIndexes.drawerOpen}>{getDrawerContents()}</PaymentsDrawer>
    </Box>
  )
}
