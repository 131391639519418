/* eslint-disable camelcase */
import React, { useRef, useState, useImperativeHandle, forwardRef } from 'react'

import { Popper } from 'v3/components/molecules/popper'
import { useMediaQuery } from 'v3/hooks/use-media-query'
import { useQuerySeenAnnouncements } from 'v3/hooks/use-query-seen-announcement'
import { useMutateMySeenAnnouncement } from 'v3/hooks/use-mutate-seen-announcement'
import { SEEN_ANNOUNCEMENT_TYPE } from 'v3/containers/page-dashboard/const'

import EmptyState from '@rentspree/component-2023.components.templates.empty-state'

import { CenterLoading } from 'components/organisms/center-loading'
import { Box } from '@mui/material'
import { ONE_TIME_POPPER_FOR_INVITED_USER_SEEN_GROUP, POPPER_CONTENT } from '../constants'

import { TenantScreeningTableMobile } from './mobile/table-mobile'
import { TenantScreeningTableDesktop } from './desktop/table-desktop'

export const TenantScreeningTable = forwardRef(
  (
    {
      data,
      isLoading,
      isAllowAcceptDeny,
      isAllowCarLease,
      eventSource,
      onClickRentalSubmissionRow,
      onOpenAcceptDenyModal,
      onOpenAssignPropertyModal,
      onClickSetupLease,
      onClickSetupRentPayment,
      isShowingWelcomeModal,
      hidePropertyAddress = false,
      showDataEmptyState,
    },
    ref,
  ) => {
    const { data: seenAnnouncements, isLoading: isSeenAnnouncementLoading } =
      useQuerySeenAnnouncements({
        retry: false,
        enabled: isShowingWelcomeModal,
      })
    const mutateSeenAnnouncement = useMutateMySeenAnnouncement({
      announcementType: SEEN_ANNOUNCEMENT_TYPE.GET_STARTED,
      groups: [ONE_TIME_POPPER_FOR_INVITED_USER_SEEN_GROUP],
    })
    const anchorRef = useRef(null)
    const [anchorEleRef, setAnchorEleRef] = useState(anchorRef?.current)
    const [shouldShowPopper, setShouldShowPopper] = useState(false)
    const isMobile = useMediaQuery('(max-width: 1016px)')

    const tenantScreeningTableProps = {
      data,
      isLoading,
      isAllowAcceptDeny,
      isAllowCarLease,
      eventSource,
      onClickRentalSubmissionRow,
      onOpenAcceptDenyModal,
      onOpenAssignPropertyModal,
      onClickSetupLease,
      onClickSetupRentPayment,
      hidePropertyAddress,
    }

    useImperativeHandle(ref, () => ({
      showOneTimePopper: () => {
        if (
          !seenAnnouncements?.['seen_groups']?.[ONE_TIME_POPPER_FOR_INVITED_USER_SEEN_GROUP] &&
          anchorRef?.current
        ) {
          setAnchorEleRef(anchorRef.current)
          setShouldShowPopper(true)
          mutateSeenAnnouncement.mutate()
        }
      },
    }))

    if (isSeenAnnouncementLoading) {
      return <CenterLoading />
    }

    if (showDataEmptyState) {
      return (
        <Box data-testid="screening-table-empty-state">
          <EmptyState
            image={
              <img
                src="https://cdn.rentspree.com/static-files/zero-state/search-not-found-empty-state.svg"
                alt="search screening empty state"
              />
            }
            subTitle="No application match your search and filter criteria."
          />
        </Box>
      )
    }

    return (
      <Box
        ref={anchorRef}
        id="applicationTable"
        className="application-table"
        isHideDesktop={data.length === 0}
      >
        {isMobile ? (
          <TenantScreeningTableMobile {...tenantScreeningTableProps} />
        ) : (
          <TenantScreeningTableDesktop {...tenantScreeningTableProps} />
        )}
        <Popper
          open={shouldShowPopper}
          anchorElement={anchorEleRef}
          currentStep={0}
          totalStep={1}
          title={POPPER_CONTENT.TITLE}
          placement={isMobile ? 'bottom' : 'bottom-start'}
          content={POPPER_CONTENT.SUBTITLE}
          onClose={() => setShouldShowPopper(false)}
          spotlightConfig={{ enabled: true }}
          hasCta={false}
          sx={{
            margin: isMobile ? '16px !important' : '16px 0 32px !important',
          }}
          popperOptions={{
            ...(!isMobile && {
              modifiers: [
                {
                  name: 'arrow',
                  options: {
                    padding: ({ popper }) => popper.width / 2,
                  },
                },
              ],
            }),
          }}
        />
      </Box>
    )
  },
)
