import { takeLatest, put, call, all } from 'redux-saga/effects'
import { buildPath } from '@rentspree/path'
import * as PATH from 'constants/route'
import { LEASE_AGREEMENTS } from 'tracker/const'
import tracker from 'tracker'
import { LEASE_AGREEMENTS as CONST_LEASE_AGREEMENT } from 'constants/error-messages'
import { addToast } from 'containers/toast/actions'
import { apiInstanceWithErrorHandler, UserApiInstance } from '../../utils/api-interceptor'
import {
  leaseAgreementDetailApi,
  alertSuccess,
  voidEnvelopeApi,
  deleteEnvelopeApi,
  promiseCallback,
  updateConsentLeaseAgreementApi,
  resendEnvelopeApi,
  alertError,
} from './actions'
import {
  LEASE_AGREEMENT_DETAIL_CALL,
  VOID_ENVELOPE_CALL,
  DELETE_ENVELOPE_CALL,
  RESEND_ENVELOPE_CALL,
  UPDATE_LEASE_AGREEMENT_CONSENT_CALL,
  CREATE_CUSTOM_TEMPLATE,
  CREATE_CUSTOM_TEMPLATE_TOAST_SUCCESS,
  CREATE_CUSTOM_TEMPLATE_TOAST_FAILED,
  DELETE_ENVELOPE,
} from './constants'
import { updateEnvelope } from '../envelope/actions'
import { LEASE_AGREEMENT_TERM, LEASE_TERM_VERSION } from '../../constants/terms'
import { makeSelectIsAcceptTermsLeaseAgreement } from './selectors'

export const callAPI = ({ propertyId, leaseAgreementId }) =>
  apiInstanceWithErrorHandler.get(
    buildPath(PATH.API_LEASE_AGREEMENT_BY_ID, { propertyId, leaseAgreementId }),
  )

// TODO: TECH-17060 [DUPLICATED_ALERT] moved to `lease-agreement-list` saga already
export const callVoidEnvelope = ({ envelopeId, voidReason, propertyId, leaseAgreementId }) =>
  apiInstanceWithErrorHandler.put(buildPath(PATH.VOID_ENVELOPE, { envelopeId }), {
    voidReason,
    propertyId,
    leaseAgreementId,
  })

// TODO: TECH-17060 [DUPLICATED_ALERT] moved to `lease-agreement-list` saga already
export const callDeleteEnvelope = ({ envelopeId }) => {
  apiInstanceWithErrorHandler.delete(buildPath(PATH.DELETE_ENVELOPE, { envelopeId }))
}

export const callResendEnvelope = ({ envelopeId, recipientEmail, propertyId, leaseAgreementId }) =>
  apiInstanceWithErrorHandler.post(buildPath(PATH.RESEND_ENVELOPE, { envelopeId }), {
    email: recipientEmail,
    propertyId,
    leaseAgreementId,
  })

export const callUpdateConsent = () =>
  UserApiInstance.post(PATH.USER_TERMS_API, {
    termName: LEASE_AGREEMENT_TERM,
    version: LEASE_TERM_VERSION, // format is YYYYMMDD
  })

export function* leaseAgreementDetailSaga({ payload }) {
  yield put(leaseAgreementDetailApi.request())
  try {
    const response = yield call(callAPI, payload)
    yield put(leaseAgreementDetailApi.success(response))
  } catch (err) {
    yield put(leaseAgreementDetailApi.failure())
  }
}

export const selectIsAcceptTermsLeaseAgreement = makeSelectIsAcceptTermsLeaseAgreement()

export function* updateConsentLeaseAgreement({ payload }) {
  yield put(updateConsentLeaseAgreementApi.request())
  try {
    const response = yield call(callUpdateConsent)
    yield put(updateConsentLeaseAgreementApi.success(response))
    yield call([tracker, 'trackEvent'], LEASE_AGREEMENTS.ACCEPT_CONCENT)
    const { files, envelopeId, nextPath, from } = payload
    yield put(updateEnvelope(files, envelopeId, nextPath, from))
  } catch (err) {
    yield put(updateConsentLeaseAgreementApi.failure(err))
  }
}

// TODO: TECH-17060 [DUPLICATED_ALERT] moved to `lease-agreement-list` saga already
export function* voidEnvelopeSaga({ payload }) {
  yield put(voidEnvelopeApi.request())
  try {
    const response = yield call(callVoidEnvelope, payload)
    yield put(voidEnvelopeApi.success(response))
    yield call([tracker, 'trackEvent'], LEASE_AGREEMENTS.VOIDED_ENVELOPE, {
      reason: payload.voidReason,
    })
    yield put(
      alertSuccess(promiseCallback(payload), {
        title: 'Voided',
        text: 'Document was voided.',
        button: 'Close',
      }),
    )
  } catch (err) {
    yield put(voidEnvelopeApi.failure(err))
  }
}

// TODO: TECH-17060 [DUPLICATED_ALERT] moved to `lease-agreement-list` saga already
export function* deleteEnvelopeSaga({ payload }) {
  yield put(deleteEnvelopeApi.request())
  try {
    const response = yield call(callDeleteEnvelope, payload)
    yield put(deleteEnvelopeApi.success(response))
    yield put(
      addToast({
        bodyMessage: DELETE_ENVELOPE,
        status: 'success',
        width: '350px',
      }),
    )
  } catch (err) {
    yield put(deleteEnvelopeApi.failure(err))
  }
}

export function* resendEnvelopeSaga({ payload }) {
  yield put(resendEnvelopeApi.request())
  try {
    const response = yield call(callResendEnvelope, payload)
    yield put(resendEnvelopeApi.success(response))
    yield call([tracker, 'trackEvent'], LEASE_AGREEMENTS.RESEND_E_SIGN, {
      recipient: payload.recipientEmail,
    })
    yield put(
      alertSuccess(promiseCallback(payload), {
        title: 'Signature request sent',
        text: 'A signature request email has been sent successfully.',
        button: 'Close',
      }),
    )
  } catch (err) {
    yield put(resendEnvelopeApi.failure(err))
    yield put(
      alertError({
        title: CONST_LEASE_AGREEMENT.RESEND_ERROR.TITLE,
        text: CONST_LEASE_AGREEMENT.RESEND_ERROR.MESSAGE,
        button: 'Close',
      }),
    )
  }
}

export function* createCustomTemplateSaga({ payload }) {
  const { saveAsTemplateResult } = payload
  if (saveAsTemplateResult === 'success') {
    yield put(
      addToast({
        bodyMessage: CREATE_CUSTOM_TEMPLATE_TOAST_SUCCESS,
        status: 'success',
        width: '350px',
      }),
    )
  } else if (saveAsTemplateResult === 'fail') {
    yield put(
      addToast({
        bodyMessage: CREATE_CUSTOM_TEMPLATE_TOAST_FAILED,
        status: 'error',
        width: '350px',
      }),
    )
  }
}

// TODO: TECH-17060 [DUPLICATED_ALERT] moved to `lease-agreement-list` saga already
export function* watchVoidEnvelope() {
  yield takeLatest(VOID_ENVELOPE_CALL, voidEnvelopeSaga)
}

// TODO: TECH-17060 [DUPLICATED_ALERT] moved to `lease-agreement-list` saga already
export function* watchDeleteEnvelope() {
  yield takeLatest(DELETE_ENVELOPE_CALL, deleteEnvelopeSaga)
}

export function* watchApiCall() {
  yield takeLatest(LEASE_AGREEMENT_DETAIL_CALL, leaseAgreementDetailSaga)
}

export function* watchResendEnvelope() {
  yield takeLatest(RESEND_ENVELOPE_CALL, resendEnvelopeSaga)
}

export function* watchUpdateConsentLeaseAgreement() {
  yield takeLatest(UPDATE_LEASE_AGREEMENT_CONSENT_CALL, updateConsentLeaseAgreement)
}

export function* watchCreateCustomTemplate() {
  yield takeLatest(CREATE_CUSTOM_TEMPLATE, createCustomTemplateSaga)
}

export function* rootSaga() {
  yield all([
    watchApiCall(),
    watchVoidEnvelope(),
    watchDeleteEnvelope(),
    watchResendEnvelope(),
    watchUpdateConsentLeaseAgreement(),
    watchCreateCustomTemplate(),
  ])
}

export default rootSaga
