import CloseIcon from '@mui/icons-material/Close'
import { Divider, FormControl, FormControlLabel, Grid, MenuItem } from '@mui/material'
import { Radio } from '@rentspree/component-2023.components.atoms.radio'
import { RadioGroup } from '@rentspree/component-2023.components.atoms.radio-group'
import { Select } from '@rentspree/component-2023.components.atoms.select'
import { TextInput } from '@rentspree/component-2023.components.atoms.text-input'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import dayjs from 'dayjs'
import timezonePlugin from 'dayjs/plugin/timezone'
import utcPlugin from 'dayjs/plugin/utc'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'

import { DatePickerInput } from 'v3/components/date-picker-input/index'
import { PaymentsDrawer } from 'v3/containers/overhaul-rent-payment/components/drawer/drawer'
import { DrawerFooter } from 'v3/containers/overhaul-rent-payment/components/footer/drawer-footer'
import {
  ButtonLayoutEnum,
  DEPOSIT_AND_FEES,
  PAYMENT_TYPES,
  PaymentCategories,
  PAYMENT_LABEL,
  FEE_LABEL,
} from 'v3/containers/overhaul-rent-payment/constants'
import {
  capitalize,
  isCategoryOneTimeOnly,
  utcStartOfDay,
} from 'v3/containers/overhaul-rent-payment/pages/utils'
import { ABOUT_PAYMENT } from 'v3/containers/overhaul-rent-payment/text-constants'

import {
  oneTimePaymentValidationSchema,
  otherCategoryPaymentValidationSchema,
  recurringPaymentValidationSchema,
} from '../../pages/payment-details/validators'

dayjs.extend(utcPlugin)
dayjs.extend(timezonePlugin)
const NOW = dayjs()

const { RECURRING, ONE_TIME } = PAYMENT_TYPES

const typographyStyle = { fontSize: '1.5rem', marginBottom: '15px' }

const getGeneralInitialValues = payment => {
  return {
    startDate: payment?.startDate ? utcStartOfDay(payment?.startDate).format('YYYY-MM-DD') : '',
    dueDate: payment?.dueDate ? utcStartOfDay(payment?.dueDate).format('YYYY-MM-DD') : '',
    endDate: payment?.endDate ? utcStartOfDay(payment?.endDate).format('YYYY-MM-DD') : '',
    dueOn: payment?.dueOn ?? '',
    type: payment?.type ?? '',
    amount: payment?.amount ?? 0.0,
    description: payment?.description ?? '',
    category: payment?.category ?? '',
  }
}

const toNumberFormat = val => parseFloat(val.replace(',', ''))

const GeneralPaymentForm = ({ payment, updatePayment, setIsValid, formikParent }) => {
  /*
   * @payment: the payment object for the form to reference for current values
   * @updatePayment: the update-er (vs strict setter) for the payment object, assuming a setState or equivalent flow
   */

  const onlyOneTimePayment = isCategoryOneTimeOnly(payment?.category)

  const formik =
    formikParent ??
    useFormik({
      initialValues: getGeneralInitialValues(payment),
      validateOnMount: true,
      validationSchema:
        payment?.type === ONE_TIME
          ? oneTimePaymentValidationSchema
          : recurringPaymentValidationSchema,
    })

  useEffect(() => {
    const type = onlyOneTimePayment ? ONE_TIME : payment?.type
    updatePayment({ type })
  }, [payment?.category])

  useEffect(() => {
    setIsValid(formik?.isValid)
  }, [formik.isValid])

  return (
    <>
      {!onlyOneTimePayment && (
        <FormControl>
          <Typography sx={typographyStyle} variant="body1">
            How often?
          </Typography>
          <RadioGroup
            id="type"
            error={formik.touched.type && Boolean(formik.errors.type)}
            {...formik.getFieldProps('type')}
            onChange={e => {
              updatePayment({ type: e.target.value, startDate: '' })
              formik.handleChange(e)
            }}
            sx={{ marginBottom: '15px' }}
          >
            <FormControlLabel value={RECURRING} control={<Radio />} label="Monthly" />
            <FormControlLabel value={ONE_TIME} control={<Radio />} label="One-time" />
          </RadioGroup>
        </FormControl>
      )}
      <Typography sx={typographyStyle} variant="body1">
        How much?
      </Typography>
      <FormControl>
        <TextInput
          id="amount"
          name="amount"
          {...formik.getFieldProps('amount')}
          startAdornment="$"
          numberFormatOptions={{
            thousandSeparator: true,
            fixedDecimalScale: true,
            decimalScale: 2,
          }}
          variant="outlined"
          size="normal"
          sx={{ marginBottom: '15px' }}
          onChange={e => {
            const val = e.target.value
            updatePayment({ amount: val ? toNumberFormat(val) : 0 })
            formik.handleChange({
              target: { name: 'amount', value: val ? toNumberFormat(val) : 0 },
            })
          }}
          onBlur={e => {
            const val = e.target.value
            updatePayment({ amount: val ? toNumberFormat(val) : 0 })
            formik.handleBlur({
              target: { name: 'amount', value: val ? toNumberFormat(val) : 0 },
            })
          }}
          error={formik.touched.amount && Boolean(formik.errors.amount)}
          helperText={formik?.touched?.amount && formik?.errors?.amount}
          required
        />
      </FormControl>
      {payment?.type === ONE_TIME ? (
        <FormControl>
          <Typography sx={typographyStyle} variant="body1">
            When&#39;s the due date?
          </Typography>
          <DatePickerInput
            label="Due on"
            sx={typographyStyle}
            name="dueDate"
            {...formik.getFieldProps('dueDate')}
            onDateChange={date => {
              const nextDueOn = date ? utcStartOfDay(date).date() : null
              updatePayment({ startDate: date, dueDate: date, dueOn: nextDueOn })
              formik.setFieldTouched('dueDate')
              formik.handleChange({ target: { name: 'dueDate', value: date } })
            }}
            onBlur={e => {
              const date = e.target.value
              const nextDueOn = date ? utcStartOfDay(date).date() : null
              updatePayment({ startDate: date || null, dueDate: date || null, dueOn: nextDueOn })
              formik.handleBlur(e)
            }}
            minDate={NOW}
            error={formik.touched.dueDate && Boolean(formik.errors.dueDate)}
            helperText={formik?.touched?.dueDate && formik?.errors?.dueDate}
            required
          />
        </FormControl>
      ) : (
        <>
          <FormControl>
            <Typography sx={typographyStyle} variant="body1">
              For how long?
            </Typography>
            <DatePickerInput
              {...formik.getFieldProps('startDate')}
              label="Starts on"
              sx={typographyStyle}
              name="startDate"
              onDateChange={date => {
                const nextDueOn = date ? utcStartOfDay(date).date() : null
                updatePayment({ startDate: date, dueDate: date, dueOn: nextDueOn })
                formik.setFieldTouched('startDate')
                formik.handleChange({ target: { name: 'startDate', value: date } })
              }}
              onBlur={e => {
                const date = e.target.value
                const nextDueOn = date ? utcStartOfDay(date).date() : null
                updatePayment({ startDate: date || null, dueDate: date || null, dueOn: nextDueOn })
                formik.handleBlur(e)
              }}
              minDate={NOW}
              error={formik.touched.startDate && Boolean(formik.errors.startDate)}
              helperText={formik?.touched?.startDate && formik?.errors?.startDate}
              required
            />
          </FormControl>
          <FormControl>
            <DatePickerInput
              {...formik.getFieldProps('endDate')}
              label="Ends on"
              sx={typographyStyle}
              name="endDate"
              onDateChange={date => {
                updatePayment({ endDate: date || null })
                formik.setFieldTouched('endDate')
                formik.handleChange({ target: { name: 'endDate', value: date } })
              }}
              onBlur={e => {
                const date = e.target.value
                updatePayment({ endDate: date || null })
                formik.handleBlur(e)
              }}
              minDate={dayjs(payment?.startDate)}
              error={formik?.touched?.endDate && Boolean(formik?.errors?.endDate)}
              helperText={
                (formik?.touched?.endDate && formik?.errors?.endDate) ||
                ABOUT_PAYMENT.HELPER_TEXT_END_DATE
              }
              required
            />
          </FormControl>
        </>
      )}
      <FormControl sx={{ marginBottom: '15px' }}>
        <TextInput
          {...formik.getFieldProps('description')}
          id="description"
          label="Note"
          showOptional
          variant="outlined"
          size="normal"
          helperText={ABOUT_PAYMENT.HELPER_TEXT_NOTE}
        />
      </FormControl>
    </>
  )
}

const TellUsAboutRent = ({ payment, updatePayment, setIsValid }) => {
  return (
    <GeneralPaymentForm payment={payment} updatePayment={updatePayment} setIsValid={setIsValid} />
  )
}

const TellUsAboutPaymentType = ({
  payment,
  updatePayment,
  paymentOptions = DEPOSIT_AND_FEES,
  editAllTypes = false,
  setIsValid,
}) => {
  /*
   * Currently used for Fees in the payment details page, and Rent OR Fees in the Review flow's Edit Payment drawer
   *
   * @payment: the payment object for the form to reference for current values
   * @setPayment: the setter for the payment object, assuming a setState or equivalent flow
   * @paymentOptions: allows the caller to set the payment options; especially helpful to keep from adding the same payment type
   * @editAllTypes: the review page needs to allow all types to be edited at once, whereas other pages have a
   *                dedicated Rent vs separate Fees (all) page and flow.
   */
  const paymentOrFee = editAllTypes ? PAYMENT_LABEL : FEE_LABEL
  // Avoid an empty Select screen if the payment options don't include the current value
  const inclusivePaymentOptions =
    payment?.category && paymentOptions.includes(payment?.category)
      ? paymentOptions
      : [payment?.category, ...paymentOptions]

  const paymentFormikState = useFormik({
    initialValues: {
      ...getGeneralInitialValues(payment),
      name: payment?.customCategory ?? '',
    },
    validateOnMount: true,
    validationSchema:
      payment?.type === ONE_TIME
        ? oneTimePaymentValidationSchema.shape({ ...otherCategoryPaymentValidationSchema.fields })
        : recurringPaymentValidationSchema.shape({
            ...otherCategoryPaymentValidationSchema.fields,
          }),
  })

  // API payments will have custom category names instead of "Other", so also check if the category isn't a typical option
  const categoryCustom = !Object.values(PaymentCategories).includes(payment?.category)
  const otherCategory =
    payment?.category && (payment?.category === PaymentCategories.OTHER || categoryCustom)

  return (
    <>
      <Divider sx={{ marginBottom: '30px', width: '100%' }} />
      <FormControl defaultValue="Other" sx={{ marginBottom: '15px' }} required>
        <Typography sx={typographyStyle} variant="body1">
          {`Which ${paymentOrFee}?`}
        </Typography>
        <Select
          id="category"
          {...paymentFormikState.getFieldProps('category')}
          label={`${capitalize(paymentOrFee)} type`}
          value={payment?.category}
          onChange={e => {
            updatePayment({ category: e.target.value })
            paymentFormikState.handleChange(e)
          }}
          data-testid="fee-type-select-component"
          MenuProps={{
            style: { zIndex: 5050 },
          }}
          required
        >
          {inclusivePaymentOptions.map(label => {
            const keyLabel = label?.replace(/\s+/g, '-')?.toLowerCase()
            return (
              <MenuItem
                value={label}
                data-testid="deposit-and-fee-option"
                // e.g. 'deposit-and-fee-option-' + ('Pet deposit' -> pet-deposit)
                key={`deposit-and-fee-option-${keyLabel}`}
              >
                <Typography variant="body-medium">{label}</Typography>
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      {otherCategory && (
        <FormControl>
          <TextInput
            id="name"
            {...paymentFormikState.getFieldProps('name')}
            placeholder={`${capitalize(paymentOrFee)} name`}
            label={`${capitalize(paymentOrFee)} name`}
            variant="outlined"
            size="normal"
            onChange={e => {
              updatePayment({ customCategory: e.target.value })
              paymentFormikState.handleChange(e)
            }}
            sx={{ marginBottom: '15px' }}
            required
            error={paymentFormikState.touched.name && Boolean(paymentFormikState.errors.name)}
            helperText={paymentFormikState?.touched?.name && paymentFormikState?.errors?.name}
          />
        </FormControl>
      )}
      <GeneralPaymentForm
        payment={payment}
        updatePayment={updatePayment}
        setIsValid={setIsValid}
        formikParent={paymentFormikState}
      />
    </>
  )
}

export const TellUsAboutPayment = ({
  payment,
  setPayment,
  paymentOptions = DEPOSIT_AND_FEES,
  editAllTypes = false,
  setIsValid,
}) => {
  /*
   * @payment: the payment object for the form to reference for current values
   * @setPayment: the setter for the payment object, assuming a setState or equivalent flow
   * @paymentOptions: allows the caller to set the payment options; especially helpful to keep from adding the same payment type
   * @editAllTypes: the review page needs to allow all types to be edited at once, whereas other pages have a
   *                dedicated Rent vs separate Fees (all) page and flow.
   */

  const updatePayment = updates => {
    // we likely want to update a few to one field at a time, so this makes it easy to not lose data by accident
    const nextPaymentObj = {
      ...payment,
      ...updates,
    }
    setPayment(nextPaymentObj)
  }

  // if we're in any category other than rent, render the type-selection form -- assume fees
  let PaymentPageSelection = TellUsAboutPaymentType
  if (payment?.category === PaymentCategories.RENT && !editAllTypes) {
    PaymentPageSelection = TellUsAboutRent
  }

  return (
    <PaymentPageSelection
      payment={payment}
      updatePayment={updatePayment}
      paymentOptions={paymentOptions}
      editAllTypes={editAllTypes}
      setIsValid={setIsValid}
    />
  )
}

export const TellUsAboutPaymentDrawer = ({
  payment,
  setPayment,
  drawerOpen,
  onClose,
  onSave,
  onBack,
  paymentOptions = DEPOSIT_AND_FEES,
  editAllTypes = false,
  isNewPayment = false,
  setIsValid,
  isValid,
}) => {
  /*
   * @payment: the payment object for the form to reference for current values
   * @setPayment: the setter for the payment object, assuming a setState or equivalent flow
   * @drawerOpen: a boolean flag for whether the side drawer should be open
   * @onClose: what method to run on a "close" action
   * @onSave: what method to run on a "save"/"next" action
   * @paymentOptions: allows the caller to set the payment options; especially helpful to keep from adding the same payment type
   * @editAllTypes: the review page needs to allow all types to be edited at once, whereas other pages have a
   *                dedicated Rent vs separate Fees (all) page and flow.
   */

  const title = editAllTypes ? 'Edit payment' : 'Tell us about fees'

  return (
    <PaymentsDrawer drawerOpen={drawerOpen}>
      <Grid container sx={{ justifyContent: 'space-between', marginBottom: '15px' }}>
        <Typography variant="h4">{title}</Typography>
        <CloseIcon sx={{ fontSize: '24px', cursor: 'pointer' }} onClick={onClose} />
      </Grid>
      <TellUsAboutPayment
        payment={payment}
        setPayment={setPayment}
        paymentOptions={paymentOptions}
        editAllTypes={editAllTypes}
        setIsValid={setIsValid}
      />
      <DrawerFooter
        buttonLayout={ButtonLayoutEnum.NEXT_BACK}
        textOverrides={{ back: isNewPayment ? 'Cancel' : 'Delete payment', next: 'Save' }}
        onBack={onBack}
        onNext={onSave}
        nextEnabled={isValid}
      />
    </PaymentsDrawer>
  )
}
