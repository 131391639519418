import React, { useCallback, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import IconButton from '@rentspree/component-2023.components.atoms.icon-button'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import { useScreenSize } from '../../../hooks/use-screen-size'
import './pdf-overlay-scale-controller.scss'

const calculatePercent = float => parseInt(float * 100, 10)

const PdfOverlayScaleController = ({ onChange, scale, min, max, step }) => {
  const { isDesktop } = useScreenSize()
  const [percent, setPercent] = useState(`${calculatePercent(scale)}%`)

  useEffect(() => {
    setPercent(`${calculatePercent(scale)}%`)
  }, [scale])

  const handleChange = event => {
    setPercent(event.target.value)
  }
  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      event.target.blur()
    }
  }
  const handleBlur = useCallback(
    event => {
      let newPercent = `${calculatePercent(scale)}%`
      const isPercent = /^\d+%{0,1}$/.test(event.target.value)
      if (isPercent) {
        const newValue = parseInt(event.target.value.replace('%', ''), 10) / 100
        if (newValue >= min && newValue <= max) {
          newPercent = `${calculatePercent(newValue)}%`
          handleScaleChange(newValue)
        }
      }
      setPercent(newPercent)
    },
    [scale],
  )
  const handleScaleChange = value => {
    if (value >= min && value <= max) {
      onChange(parseFloat(value.toFixed(2)))
    }
  }

  return (
    <Typography className="RSPPdfOverlayScaleController" variant="body-medium">
      <IconButton
        disabled={scale === min}
        color="white"
        aria-label="zoom-in"
        onClick={() => handleScaleChange(scale - step)}
      >
        <RemoveIcon />
      </IconButton>
      {isDesktop && (
        <input
          className="RSPPdfOverlayScaleController-input"
          type="text"
          min={min * 100}
          max={max * 100}
          value={percent}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
      )}
      <IconButton
        disabled={scale === max}
        color="white"
        aria-label="zoom-out"
        onClick={() => handleScaleChange(scale + step)}
      >
        <AddIcon />
      </IconButton>
    </Typography>
  )
}

PdfOverlayScaleController.defaultProps = {
  min: 1,
  max: 4,
  step: 0.1,
}

export default PdfOverlayScaleController
