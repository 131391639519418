import { Box, MenuItem } from '@mui/material'
import Select from '@rentspree/component-2023.components.atoms.select'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectProfile } from 'containers/user/selectors'
import { USER_ROLE_FOR_PROPERTY } from 'v3/components/templates/user-role-for-property-form/constants'
import { useCreatePropertyHook } from 'v3/containers/create-property-form/hooks'
import { initialState as initialCreatePropertyState } from 'v3/containers/create-property-form/reducers/create-property.reducer'
import {
  createPropertyString,
  isEmptyOrNotObject,
} from 'v3/containers/overhaul-rent-payment/pages/utils'
import { PROP_AND_TENANT } from 'v3/containers/overhaul-rent-payment/text-constants'
import { useListPropertiesQuery } from 'v3/containers/rent-payment/apis/property'

export const PropertySelectAndForm = ({ selectedProperty, setProperty, recipientInfo }) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)

  const selectedPropertyStr =
    typeof selectedProperty === 'string' && selectedProperty.length > 0
      ? createPropertyString(selectedProperty)
      : undefined

  const [properties, setProperties] = useState([])

  const listPropertiesQuery = useListPropertiesQuery()
  const { registeredUserType } = useSelector(selectProfile)

  useEffect(() => {
    if (listPropertiesQuery.isSuccess && !listPropertiesQuery.isLoading) {
      const foundProperties = listPropertiesQuery.data?.data || []
      const filteredProperties = foundProperties.filter(el => !isEmptyOrNotObject(el))
      setProperties(filteredProperties)
    }
  }, [listPropertiesQuery.data, listPropertiesQuery.isSuccess, listPropertiesQuery.isLoading])

  const {
    setupCreateProperty,
    open: openPropertyForm,
    close: closePropertyForm,
  } = useCreatePropertyHook()

  useEffect(() => {
    if (isOpen) {
      // if something's wrong with the field value, let the role be empty so the user is prompted to specify
      const role = Object.values(USER_ROLE_FOR_PROPERTY).includes(registeredUserType)
        ? registeredUserType
        : ''
      const options = {
        onSuccessCallback: async ({ property }) => {
          await listPropertiesQuery.refetch()
          setProperty(property)
          setIsOpen(false)
        },
        // prefilling the user's property role & profile fields allows for the 2nd page to be skipped
        preFillFormValues: {
          ...initialCreatePropertyState?.preFillFormValues,
          // TODO: grab user's account type from state?
          userRoleForProperty: role,
          landlordProfile: {
            ...initialCreatePropertyState?.preFillFormValues?.landlordProfile,
            firstName: recipientInfo?.firstName,
            lastName: recipientInfo?.lastName,
            email: recipientInfo?.email,
          },
        },
      }
      setupCreateProperty(options)
      openPropertyForm()
    } else {
      closePropertyForm()
    }
  }, [isOpen, dispatch])

  return (
    <Box style={{ margin: 'auto', width: '100%' }}>
      <Select
        label="Property"
        value={properties.length > 0 ? selectedProperty?._id || '' : ''}
        style={{ fontSize: '1.75rem', textAlign: 'left' }}
        required
      >
        <MenuItem
          key="new"
          onClick={() => {
            setProperty(undefined)
            setIsOpen(true)
          }}
        >
          {PROP_AND_TENANT.ADD_NEW_PROPERTY}
        </MenuItem>
        {properties.map(val => {
          const propString = createPropertyString(val)
          return (
            <MenuItem
              key={val._id}
              onClick={() => setProperty(val)}
              value={val._id}
              selected={selectedPropertyStr === propString}
            >
              {propString}
            </MenuItem>
          )
        })}
      </Select>
    </Box>
  )
}
