import React from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { COLOR } from 'styles/settings'
import { generateAddress, generateFullName } from '@rentspree/helper'
import { EvictionHeaderV4 } from 'legacy/components/reports-v2/share/doc-components'
import { convertNA } from 'legacy/components/reports-v2/share/function'
import { convert } from 'legacy/components/helper/convert'
import {
  AvoidPrintBreakInside,
  Content,
  AnnotationLine,
  InformationPanel,
  Section,
  SubSection,
  FlexInfoContainer,
  ScreeningInfoContentWrapper
} from 'components/reports/screening-reports/share/responsive-doc-style'
import {
  InformationLine,
  SubSectionHeader,
  HalfWidthInfoField,
} from 'components/reports/screening-reports/share/responsive-doc-components'
import styled from 'styled-components'

const ContentStyled = styled(Content)`
  margin-bottom: 10px;
`
const CaseResultContainer = styled.div`
  border: solid 2px ${COLOR.lightTextGrey};
  border-bottom: solid 1px ${COLOR.lightTextGrey};
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
`
const CaseHeaderWrapper = styled.div`
  font-size: 17px;
  margin-left: 25px;
  margin-top: 10px;
`
const AnnotationWrapper = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 40px;
`
export const CaseAnnotationLine = styled.span`
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  color: ${COLOR.black};
`
const CaseDetailsWrapper = styled.div`
  width: 100%;
`

export const EvictionCase = ({fullName, eviction, footnote}) => (
  <div>
    <EvictionCaseSection
      fullName={fullName}
      eviction={eviction}
      footnote={footnote}
    />
    <AnnotationWrapper>
      <CaseAnnotationLine>
        *{footnote}
      </CaseAnnotationLine>
    </AnnotationWrapper>
  </div>
)

const CaseHeader = ({eviction}) => (
  <CaseHeaderWrapper>
    <b>Case #: </b> {eviction.caseNumber}
  </CaseHeaderWrapper>
)

const EvictionCaseSection = ({ fullName, eviction }) => (
  <CaseDetailsWrapper>
    <CaseHeader eviction={eviction}/>
    <CaseResultContainer>
      <EvictionCaseDetails fullName={fullName} eviction={eviction} />
    </CaseResultContainer>
  </CaseDetailsWrapper>
)

const EvictionCaseDetails = ({ fullName, eviction }) => {
  const subject = get(eviction, 'Subject')

  return (<FlexInfoContainer>
    <ScreeningInfoContentWrapper>
      <HalfWidthInfoField
        label="Defendant"
        value={fullName}
      />
      <HalfWidthInfoField
        label="Plaintiff"
        value={convertNA(get(eviction, 'plaintiff'))}
      />
      <HalfWidthInfoField
        label="File Date"
        value={convert(get(eviction, 'fileDate'), 'date', 'YYYY-MM-DD')}
      />
      <HalfWidthInfoField
        label="Address"
        value={convertNA(get(subject, 'StreetAddress'))}
      />
      <HalfWidthInfoField
        label="City & State"
        value={`${convertNA(get(subject, 'City'))}, ${convertNA(get(subject, 'State'))}`}
      />
      <HalfWidthInfoField
        label="Zip"
        value={convertNA(get(subject, 'ZipCode'))}
      />
      <HalfWidthInfoField label="Court" value={get(eviction, 'court')} />
      <HalfWidthInfoField label="Court State" value={get(eviction, 'courtState')} />
      <HalfWidthInfoField label="Judgment" value={get(eviction, 'judgment.judgment')} />
      <HalfWidthInfoField
        label="Court Reported Amount*"
        value={convert(get(eviction, 'judgment.courtReportedAmount'), '$', '0,0.00')}
      />
      <HalfWidthInfoField
        label="Judgment Date"
        value={convert(get(eviction, 'judgment.date'), 'date', 'YYYY-MM-DD')}
      />
      <HalfWidthInfoField
        label="Judgment Release Date"
        value={convert(get(eviction, 'judgment.releaseDate'), 'date', 'YYYY-MM-DD')}
      />
    </ScreeningInfoContentWrapper>
  </FlexInfoContainer>
)}

// OLD Eviction Record - to be deleted later
const HalfScreenWidth = 'calc((100% - 10px) / 2)'
const InformationLineHalfScreen = props => (
  <InformationLine {...props} labelWidth={HalfScreenWidth} value={props.value || '-'} />
)
const EvictionRecord = ({ eviction, state, index }) => {
  const events = get(eviction, 'Events')
  const subject = get(eviction, 'Subject')
  const fileNumber = get(eviction, 'FileNumber')
  const stateKey = get(eviction, 'StateKey')
  return (
    <AvoidPrintBreakInside>
      <Section>
        <EvictionHeaderV4 state={state} fileNumber={fileNumber} index={index} />
        {!isEmpty(subject) && <SubjectDetail subject={subject} />}
        {events?.map(event => (
            <InternalRecord
              event={event}
              key={event.EventID}
              stateKey={stateKey}
            />
        ))}
      </Section>
    </AvoidPrintBreakInside>
  )
}

export const SubjectDetail = ({ subject }) => (
  <SubSection>
    <SubSectionHeader title="Subject" />
    <Content>
      <InformationPanel>
        <InformationLineHalfScreen label="Name" value={generateFullName(subject)} />
        <InformationLineHalfScreen label="Address" value={generateAddress(subject)} />
      </InformationPanel>
      <InformationPanel />
    </Content>
  </SubSection>
)

export const InfoDetail = ({ eviction }) => (
  <ContentStyled>
    <InformationPanel>
      <InformationLineHalfScreen
        label="Record Reported Date"
        value={convert(get(eviction, 'Court.Address.ReportDate'), 'date', 'MMM DD, YYYY')}
      />
      <InformationLineHalfScreen
        label="Filing Date"
        value={convert(get(eviction, 'FilingDate'), 'date', 'MMM DD, YYYY')}
      />
      <InformationLineHalfScreen label="Action Type" value={get(eviction, 'ActionType')} />
      <InformationLineHalfScreen label="Filing Type" value={get(eviction, 'FilingType')} />
      <InformationLineHalfScreen label="Record Source" value={get(eviction, 'Court.Name')} />
    </InformationPanel>
    <InformationPanel>
      <InformationLineHalfScreen
        label="Plaintiff"
        value={convertNA(get(eviction, 'Parties.[0].FullName'))}
      />
      <InformationLineHalfScreen
        label="Court Reported Amount*"
        value={convertNA(convert(get(eviction, 'AmountOrLiability'), '$', '0,0.00'))}
      />
      <InformationLineHalfScreen
        label="Release Date"
        value={convertNA(convert(get(eviction, 'ReleaseDate'), 'date', 'MMM DD, YYYY'))}
      />
    </InformationPanel>
  </ContentStyled>
)

export const InternalRecord = ({ event, stateKey }) => {
  const { EventID: eventId } = event
  return (
    <SubSection>
      <SubSectionHeader title={`Internal Record ID - ${stateKey} - ${eventId}`} />
      <InfoDetail eviction={event} />
      <AnnotationLine>
        *Court Reported Amount means the amount reported by the court as a final judgment amount,
        or an amount reflecting full or partial satisfaction of such judgment and may include
        attorney or court fees or costs.
      </AnnotationLine>
    </SubSection>
  )
}

export default EvictionRecord
