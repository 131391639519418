import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { BlueGradientButton } from '@rentspree/component-v2'
import B16 from '@rentspree/component-v2/dist/typography/b16'

import { COLOR } from 'components/constants'
import CONFIRMATION_ACCORDION_ARROW_ICON from 'images/confirmation-accordion-arrow.svg'
import CONFIRMATION_ACCORDION_MOBILE_ICON from 'images/confirmation-accordion-mobile-icon.svg'

export const ReportAccordionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 760px) {
    width: 100%;
  }
`

export const ReportAccordionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 762px;
  position: relative;

  @media (max-width: 760px) {
    width: 100%;
  }
`

export const ReportAccordionButton = styled.button`
  width: 100%;
  height: 50px;
  color: ${COLOR.black};
  padding: 8px 24px 8px 35px;
  background-color: ${COLOR.white};
  border: 1px solid ${COLOR.thinGrey};
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 760px) {
    color: ${COLOR.textBlack};
    padding: 12px 12px;
  }
`

export const ReportAccordionHeaderLeft = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;

  @media (max-width: 760px) {
    font-size: 12px;
  }
`

export const ReportAccordionHeaderRight = styled.div`
  display: flex;
  align-items: center;
`

export const ConfirmationButtonText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${COLOR.white};
`

export const ConfirmationButtonStyled = styled(BlueGradientButton)`
  height: 32px;
  margin-right: 8px;
`

export const ConfirmationAccordionArrowIcon = styled.img`
  transition: transform 0.3s;
  ${props => props.isExpanded && `transform: rotate(-180deg);`}
`

export const ReportAccordionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${COLOR.white};
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out;
  z-index: 0;
  border-left: 1px solid ${COLOR.placeholderGrey};
  border-right: 1px solid ${COLOR.placeholderGrey};

  ${props => props.isExpanded && 'max-height: calc(100vh - 220px);'}
`

export const ReportAccordionMainContent = styled.div`
  overflow: auto;
`

export const ReportAccordionSubtitleCenter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${COLOR.mediumGrey};
  top: 49px;
`

export const ContactSupportTextStyled = styled(B16)`
  @media (max-width: 760px) {
    margin 5px;
    font-size: 12px;
  }
`

export const ConfirmationAccordion = ({
  title = 'title',
  subtitle,
  confirmedText = 'confirmed text',
  confirmBtnText = 'confirm button text',
  isExpanded = false,
  isConfirmed = false,
  onExpand = () => {},
  onConfirm = () => {},
  dataTestKey = 'default',
  children,
  isMobile,
  isDisabledConfirmBtn = false,
}) => {
  const handleConfirmButtonClick = event => {
    event.stopPropagation()
    onConfirm()
  }

  return (
    <ReportAccordionWrapper data-testid={`${dataTestKey}-report-accordion-wrapper`}>
      <ReportAccordionButton
        data-testid={`${dataTestKey}-report-accordion-bar`}
        id="report-accordion-btn"
        onClick={onExpand}
      >
        <ReportAccordionHeaderLeft
          data-testid={`${dataTestKey}-report-accordion-header-left-text`}
          id="report-accordion-header-left"
        >
          {title}
        </ReportAccordionHeaderLeft>
        <ReportAccordionHeaderRight
          id="report-accordion-header-right"
          data-testid={`${dataTestKey}-report-accordion-header-right`}
        >
          {isConfirmed &&
            (isMobile ? (
              <img src={CONFIRMATION_ACCORDION_MOBILE_ICON} alt="Confirmed Icon" />
            ) : (
              confirmedText
            ))}
          {!isConfirmed && !isMobile && (
            <ConfirmationButtonStyled
              id="confirmation-accordion-btn-id"
              data-testid={`${dataTestKey}-confirm-btn`}
              small
              padding="9px 16px"
              onClick={handleConfirmButtonClick}
              disabled={isDisabledConfirmBtn}
            >
              <ConfirmationButtonText id="confirmation-accordion-btn-text">
                {confirmBtnText}
              </ConfirmationButtonText>
            </ConfirmationButtonStyled>
          )}
          {isConfirmed && !isMobile && (
            <>
              &nbsp;&nbsp;&nbsp;
              <ConfirmationAccordionArrowIcon
                src={CONFIRMATION_ACCORDION_ARROW_ICON}
                alt="Confirmation Accordion Arrow"
                isExpanded={isExpanded}
              />
            </>
          )}
        </ReportAccordionHeaderRight>
      </ReportAccordionButton>
      <ReportAccordionContent
        data-testid={`${dataTestKey}-report-content-wrapper`}
        isExpanded={isExpanded}
      >
        {subtitle && (
          <ReportAccordionSubtitleCenter isExpanded={isExpanded}>
            <ContactSupportTextStyled
              data-testid={`${dataTestKey}-report-contact-text`}
              lineHeight="20px"
              margin="4px 0"
              color={COLOR.black}
            >
              {subtitle}
            </ContactSupportTextStyled>
          </ReportAccordionSubtitleCenter>
        )}
        <ReportAccordionMainContent>{children}</ReportAccordionMainContent>
      </ReportAccordionContent>
    </ReportAccordionWrapper>
  )
}

ConfirmationAccordion.propTypes = {
  /** The string indicates the accordion's title */
  title: PropTypes.string,
  /** The string indicates the children context for contact support */
  subtitle: PropTypes.string,
  /** The string indicates the accordion's confirmed text */
  confirmedText: PropTypes.string,
  /** The string indicates the accordion's confirm button text */
  confirmBtnText: PropTypes.string,
  /** The boolean indicates the accordion's content will be shown or not */
  isExpanded: PropTypes.bool.isRequired,
  /** The boolean indicates the accordion's content has confirmed or not */
  isConfirmed: PropTypes.bool.isRequired,
  /** The function for accordion expand handler */
  onExpand: PropTypes.func.isRequired,
  /** The function for accordion confirmation handler */
  onConfirm: PropTypes.func.isRequired,
  /** The react node of accordion component */
  children: PropTypes.node.isRequired,
  /** The boolean indicates should accordion's confirm button be disabled or not */
  isDisabledConfirmBtn: PropTypes.bool,
}
