export const RECIPIENT_DETAILS = {
  TITLE: "First, who'll receive this payment?",

  SELECTION_ERROR: 'Page Error: go back and select an option',

  CLIENT_DETAILS: {
    TITLE: 'Setting this up for your client?',
    SUBTITLE:
      "We'll let them know when the payment request is sent so they can connect their bank info.",

    FIRST_NAME_LABEL: 'Their first name',
    LAST_NAME_LABEL: 'Their last name',
    EMAIL_LABEL: 'Their email',
  },

  SELF_DETAILS: {
    TITLE: "Looks like we don't have your name on file",

    FIRST_NAME_LABEL: 'Legal first name',
    LAST_NAME_LABEL: 'Legal last name',
  },
}

export const PROP_AND_TENANT = {
  TITLE: "Let's add the basics next",

  ADD_NEW_PROPERTY: 'Add a new property',
  ADD_NEW_TENANT: 'Add a new tenant',
}

export const PAYMENT_DETAILS = {
  LANDING: {
    TITLE: 'Now, what type of payments are you collecting?',
  },

  RENT: {
    ADD_LATE_FEE: 'Add a late fee',
  },
}

/*
 * Don't embed in Payment or Review objects:
 *    This component should be used by the Payment Details & Review page eventually,
 *    so the constants should remain separate as well.
 */
export const ABOUT_PAYMENT = {
  RENT_TITLE: 'Tell us about rent',

  HOW_OFTEN: 'How often?',
  MONTHLY: 'Monthly',
  ONE_TIME: 'One-time',

  HOW_MUCH: 'How much?',

  HOW_LONG: 'For how long?',

  START_DATE_HELPER_TEXT: 'Starts on',
  END_DATE_HELPER_TEXT: 'Ends on',

  HELPER_TEXT_END_DATE: 'If no end date, payments will continue month-to-month',
  HELPER_TEXT_NOTE: "We'll show this info to the tenant",

  ERROR_AMOUNT: 'Input a valid monetary amount',
}
