import { Grid, Typography } from '@mui/material'
import Snackbar from '@rentspree/component-2023.components.atoms.snackbar'
import dayjs from 'dayjs'
import timezonePlugin from 'dayjs/plugin/timezone'
import utcPlugin from 'dayjs/plugin/utc'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import React, { useState, useContext, useMemo, useCallback, useEffect } from 'react'

import { TitleWithSubtitle } from 'components/molecules/title-with-subtitle'
import { OVERHAUL_RENT_PAYMENT } from 'tracker/const'
import { PaymentsCard } from 'v3/containers/overhaul-rent-payment/components/cards/payments-card'
import { RecipientDetailsCard } from 'v3/containers/overhaul-rent-payment/components/cards/recipient-details-card'
import { TenantAndPropertyCard } from 'v3/containers/overhaul-rent-payment/components/cards/tenant-and-property-card'
import { TellUsAboutPaymentDrawer } from 'v3/containers/overhaul-rent-payment/components/payment-forms/tell-us-about-payment'
import {
  recipientFields,
  PAYMENT_TYPES,
  pageOptions,
  recipientDetailsOptions,
  MULTIPLE_SELECTION_PAYMENTS,
  ONE_TIME_FEES,
  RENT_PAYMENTS,
  PaymentCategories,
} from 'v3/containers/overhaul-rent-payment/constants'
import { PaymentInfoContext, PageIndexesContext } from 'v3/containers/overhaul-rent-payment/context'
import { paymentsField } from 'v3/containers/overhaul-rent-payment/pages/payment-details/page'
import {
  tenantField,
  propertyField,
} from 'v3/containers/overhaul-rent-payment/pages/property-and-tenant/page'
import { REVIEW } from 'v3/containers/overhaul-rent-payment/pages/review/constants'
import { generateAPIQuotation } from 'v3/containers/overhaul-rent-payment/pages/utils'
import {
  useGetOrCreateDraftRentalPayment,
  useUpdateDraftQuotations,
} from 'v3/containers/rent-payment/shared/hooks'
import { trackClick } from 'v3/utils/tracker-utils'

dayjs.extend(timezonePlugin)
dayjs.extend(utcPlugin)

// TODO: define and import from property and tenant details page
const PHONE = 'phone'
const NAME = {
  FIRST: 'firstName',
  LAST: 'lastName',
}
const PROPERTY_FIELDS = {
  STREET: 'street',
  CITY: 'city',
  STATE: 'state',
  ZIP: 'zip',
}
export const handleReviewProgression = async (
  { increment = false, decrement = false },
  curPageIndexes,
  setPageIndexes,
  _pageField,
  curPaymentInfo,
) => {
  const { propertyAndTenantInfo, reviewInfo, paymentDetailsInfo } = {
    propertyAndTenantInfo: get(curPaymentInfo, pageOptions.PROPERTY_AND_TENANT, {}),
    reviewInfo: get(curPaymentInfo, pageOptions.REVIEW, {}),
    paymentDetailsInfo: get(curPaymentInfo, pageOptions.PAYMENT_DETAILS, {}),
  }
  const tenant = get(propertyAndTenantInfo, tenantField, {})
  const inviteByTextEnabled = get(reviewInfo, REVIEW.INVITE_BY_TEXT, false)

  if (increment && inviteByTextEnabled) {
    if (!tenant.phone) {
      console.error('Phone number is required')
      return false
    }

    const phoneDigits = tenant.phone.replace(/\D/g, '')
    if (phoneDigits.length < 10) {
      console.error('Invalid phone number format')
      return false
    }
  }

  const indexChanges = {
    pageL1Index: curPageIndexes.pageL1Index,
    drawerOpen: curPageIndexes.drawerOpen,
  }
  // shortcut invalid scenarios
  if (!(decrement || increment) || (increment && decrement)) {
    /*
     * logging an error, but this can still be consumer facing, so staying vague
     * need to find direct DD log avenue for debug details if possible
     */
    console.error('Unusual state for Review page progression request')
  } else {
    // we never want to submit on drawer open states, or change pages
    if (curPageIndexes.drawerOpen) {
      indexChanges.drawerOpen = false

      const payments = get(paymentDetailsInfo, paymentsField, [])

      // Due to the asynchronous nature of setting context values, `handleReviewProgression` is called without the latest updated value in `curPaymentInfo`. Therefore, checking for a length of 1 is used to verify if all payments have been deleted.
      if (payments.length === 1 && decrement) {
        indexChanges.pageL1Index = 1
      }

      setPageIndexes(indexChanges)
      // intercept submission on drawer state
      return false
    }

    trackClick(OVERHAUL_RENT_PAYMENT.EVENT_NAME.COLLECT_PAYMENTS_CLICK, {
      step: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.STEP.REVIEW,
      destination: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.DESTINATION.DASHBOARD,
      click_text: increment
        ? OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.CLICK_TEXT.SEND_REQUEST
        : OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.CLICK_TEXT.BACK,
    })

    if (increment) {
      return true
    }

    if (decrement) {
      indexChanges.pageL1Index -= 1
      setPageIndexes(indexChanges)
    }
  }

  return false
}

export const ReviewPage = ({ setIsValid, isValid }) => {
  const [paymentInfo, setPaymentInfo] = useContext(PaymentInfoContext)
  const [pageIndexes, setPageIndexes] = useContext(PageIndexesContext)
  const { data: draftRentalPayment } = useGetOrCreateDraftRentalPayment()
  const [showPaymentDeletedSnackBar, setShowPaymentDeletedSnackBar] = useState(false)
  const {
    updateDraftQuotations,
    data: quotations,
    status: existingPaymentFetchStatus,
  } = useUpdateDraftQuotations()

  const { recipientDetailsInfo, propertyAndTenantInfo, paymentDetailsInfo, reviewDetails } = {
    recipientDetailsInfo: get(paymentInfo, pageOptions.RECIPIENT_DETAILS, {}),
    propertyAndTenantInfo: get(paymentInfo, pageOptions.PROPERTY_AND_TENANT, {}),
    paymentDetailsInfo: get(paymentInfo, pageOptions.PAYMENT_DETAILS, {}),
    reviewDetails: get(paymentInfo, pageOptions.REVIEW, {}),
  }
  const { recipientInfo, recipient } = {
    recipientInfo: get(recipientDetailsInfo, recipientFields.recipientInfo, {}),
    recipient: get(recipientDetailsInfo, recipientFields.recipient, {}),
  }
  const { property, tenant } = {
    property: get(propertyAndTenantInfo, propertyField, {}),
    tenant: get(propertyAndTenantInfo, tenantField, {}),
  }
  const payments = get(paymentDetailsInfo, paymentsField, [])
  const paymentsJSON = JSON.stringify(payments)
  const setPayments = nextPayments => {
    const ingestedPayments = nextPayments.map(curPayment => {
      const nextPayment = { ...curPayment }
      // check for API payments with OTHER/custom categories
      const categoryCustom = !Object.values(PaymentCategories).includes(nextPayment?.category)

      nextPayment.customCategory = ''
      if (categoryCustom) {
        nextPayment.customCategory = nextPayment.category
        nextPayment.category = PaymentCategories.OTHER
      }

      const beginningDate = nextPayment?.startDate || nextPayment?.dueDate
      nextPayment.startDate = beginningDate
      nextPayment.dueDate = beginningDate

      return nextPayment
    })

    const nextPaymentInfo = {
      ...paymentInfo,
      [pageOptions.PAYMENT_DETAILS]: {
        ...paymentInfo?.[pageOptions.PAYMENT_DETAILS],
        [paymentsField]: ingestedPayments,
      },
    }

    setPaymentInfo(nextPaymentInfo)
  }

  const { phone, firstName, lastName } = {
    phone: get(tenant, PHONE, ''),
    firstName: get(tenant, NAME.FIRST, ''),
    lastName: get(tenant, NAME.LAST, ''),
  }

  const setInviteByText = useCallback(
    checked => {
      setPaymentInfo(prevState => ({
        ...prevState,
        [pageOptions.REVIEW]: {
          ...prevState[pageOptions.REVIEW],
          [REVIEW.INVITE_BY_TEXT]: checked,
        },
      }))
    },
    [setPaymentInfo],
  )

  const inviteByTextCheckbox = get(reviewDetails, REVIEW.INVITE_BY_TEXT, false)

  const [selectedQuotation, setSelectedQuotation] = useState({ index: NaN, payment: {} })
  const { payment: selectedPayment, index: selectedIndex } = selectedQuotation
  const setSelectedPayment = payment => {
    setSelectedQuotation({
      ...selectedQuotation,
      payment: {
        ...selectedPayment,
        ...payment,
      },
    })
  }

  useEffect(() => {
    let isMounted = true
    // assume for now that if we have a new API call, we want to toss any cached changes
    if (quotations !== undefined && isMounted) {
      setPayments(quotations)
    }

    // a cleanup function to keep operations from running after the component unmounts via race condition
    return () => {
      isMounted = false
    }
  }, [existingPaymentFetchStatus])

  const {
    monthly: monthlyPayments,
    onetime: onetimePayments,
    remainingPaymentOptions,
  } = useMemo(() => {
    const filteredOneTimePayments = [...RENT_PAYMENTS, ...ONE_TIME_FEES].filter(
      category => !payments.some(fp => fp?.category === category),
    )
    const monthly = []
    const onetime = []

    payments.forEach((payment, i) => {
      if (payment?.type === PAYMENT_TYPES.RECURRING) {
        monthly.push([i, payment])
      } else if (payment?.type === PAYMENT_TYPES.ONE_TIME) {
        onetime.push([i, payment])
      } else {
        console.error(`Unknown payment type '${payment?.type}' found`)
      }
    })

    // Sort both monthly and onetime payments by amount in descending order
    monthly.sort((a, b) => b[1].amount - a[1].amount)
    onetime.sort((a, b) => b[1].amount - a[1].amount)

    return {
      monthly,
      onetime,
      remainingPaymentOptions: [...filteredOneTimePayments, ...MULTIPLE_SELECTION_PAYMENTS],
    }
  }, [payments.length, paymentsJSON])
  const isAgentInitiated = recipient === recipientDetailsOptions.CLIENT
  const { street, city, state, zip } = {
    [PROPERTY_FIELDS.STREET]: get(property, PROPERTY_FIELDS.STREET, ''),
    [PROPERTY_FIELDS.CITY]: get(property, PROPERTY_FIELDS.CITY, ''),
    [PROPERTY_FIELDS.STATE]: get(property, PROPERTY_FIELDS.STATE, ''),
    [PROPERTY_FIELDS.ZIP]: get(property, PROPERTY_FIELDS.ZIP, ''),
  }
  const propertyAddress = `${street}, ${city}, ${state} ${zip}`

  const setDrawerOpen = useCallback(
    ({ isOpen, payment, paymentIndex }) => {
      setPageIndexes({ drawerOpen: isOpen })
      setSelectedQuotation({ index: paymentIndex, payment })
    },
    [setSelectedQuotation, setPageIndexes],
  )

  const onDrawerSaveHandler = useCallback(
    ({ increment = false, decrement = false }) => {
      try {
        if (decrement) {
          setDrawerOpen({ isOpen: false, payment: null })
          setSelectedQuotation({ index: NaN, payment: {} })
          return false
        }

        if (!increment || selectedIndex < 0 || Number.isNaN(selectedIndex)) {
          console.error('Missing data')
          return false
        }

        const nextPayments = [...payments]
        nextPayments[selectedIndex] = selectedPayment
        const mappedQuotations = nextPayments.map(payment => generateAPIQuotation(payment))

        updateDraftQuotations({
          rentalPaymentId: draftRentalPayment.id,
          quotations: mappedQuotations,
        })

        // update payment, and reset drawer & selection
        setSelectedQuotation({ index: NaN, payment: {} })
        setDrawerOpen({ isOpen: false, payment: null })

        // don't trigger page save on drawer save
        return false
      } catch (error) {
        console.error('Error saving payment', error)
        return false
      }
    },
    [quotations, selectedQuotation, draftRentalPayment?.id, updateDraftQuotations, setPageIndexes],
  )

  const setPhoneNumber = useCallback(
    newPhoneNumber => {
      setPaymentInfo(prevState => ({
        ...prevState,
        [pageOptions.PROPERTY_AND_TENANT]: {
          ...prevState[pageOptions.PROPERTY_AND_TENANT],
          [tenantField]: {
            ...prevState[pageOptions.PROPERTY_AND_TENANT][tenantField],
            phone: newPhoneNumber,
          },
        },
      }))
    },
    [setPaymentInfo],
  )

  useEffect(() => {
    if (!(REVIEW.INVITE_BY_TEXT in reviewDetails)) {
      setInviteByText(Boolean(phone))
    }
  }, [])

  const validateReviewPage = () =>
    (inviteByTextCheckbox ? tenant.phone.length === 10 : true) && payments.length > 0

  useEffect(() => {
    setIsValid(validateReviewPage())
  }, [inviteByTextCheckbox, setIsValid, tenant?.phone, payments, setDrawerOpen])

  const handleOnBack = () => {
    const nextPayments = [...payments]
    nextPayments.splice(selectedIndex, 1)

    const mappedQuotations = nextPayments.map(payment => generateAPIQuotation(payment))

    updateDraftQuotations({
      rentalPaymentId: draftRentalPayment.id,
      quotations: mappedQuotations,
    })

    setShowPaymentDeletedSnackBar(true)
  }

  return (
    <>
      <TitleWithSubtitle
        title={REVIEW.TITLE}
        subtitle={REVIEW.DESCRIPTION}
        mTitleMargin="0 0 0px"
        titleMargin="0 0 0px"
        titleSize="24px"
        subTitleSize="16px"
      />

      {isAgentInitiated && !isEmpty(recipientInfo) && (
        <>
          <Typography variant="title">{REVIEW.RECIPIENT_DETAILS}</Typography>
          <RecipientDetailsCard recipientInfo={recipientInfo} />
        </>
      )}

      <Typography variant="title">{REVIEW.TENANT_AND_PROPERTY}</Typography>
      {/* Need to refactor this to send in the whole object */}
      <TenantAndPropertyCard
        name={`${firstName} ${lastName}`.trim()}
        address={propertyAddress}
        phoneNumber={phone}
        inviteByText={inviteByTextCheckbox}
        onSetInviteByText={setInviteByText}
        onSetPhoneNumber={setPhoneNumber}
        isAgentInitiated={isAgentInitiated}
      />
      <TitleWithSubtitle
        title={REVIEW.PAYMENT_DETAILS.title}
        subtitle={REVIEW.PAYMENT_DETAILS.description}
        mTitleMargin="0 0 0px"
        titleMargin="0 0 0px"
        titleSize="20px"
        subTitleSize="16px"
      />
      {monthlyPayments.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="title">{REVIEW.MONTHLY_PAYMENTS}</Typography>
          {monthlyPayments.map(val => {
            const [i, payment] = val
            return (
              <PaymentsCard
                key={`${payment?.category}-${payment?.type}-${payment?.amount}`}
                payment={payment}
                paymentIndex={i}
                setDrawerOpen={setDrawerOpen}
              />
            )
          })}
        </Grid>
      )}
      {onetimePayments.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="title">{REVIEW.ONE_TIME_PAYMENTS}</Typography>
          {onetimePayments.map(val => {
            const [i, payment] = val
            return (
              <PaymentsCard
                key={`${payment?.category}-${payment?.type}-${payment?.amount}`}
                payment={payment}
                paymentIndex={i}
                setDrawerOpen={setDrawerOpen}
              />
            )
          })}
        </Grid>
      )}
      <Snackbar
        sx={{ zIndex: 7500, marginBottom: '81px' }}
        open={showPaymentDeletedSnackBar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3000}
        message="Payment deleted"
        onClose={() => setShowPaymentDeletedSnackBar(false)}
      />

      <TellUsAboutPaymentDrawer
        payment={selectedPayment}
        setPayment={setSelectedPayment}
        drawerOpen={pageIndexes.drawerOpen}
        onClose={() => onDrawerSaveHandler({ decrement: true })}
        onBack={handleOnBack}
        onSave={() => onDrawerSaveHandler({ increment: true })}
        paymentOptions={remainingPaymentOptions}
        editAllTypes
        isNewPayment={false}
        setIsValid={setIsValid}
        isValid={isValid}
      />
    </>
  )
}
