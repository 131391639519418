import { useFeatureGate } from '@statsig/react-bindings'

import { NEW_DASHBOARD_FOR_EXISTING_USER_WITH_AT_LEAST_ONE_SCREENING } from 'utils/feature-flag/constants'

import { useAgentNewDashboardConfig } from './use-agent-new-dashboard-config'
import { useGetMyUserInvitation } from './use-get-my-invitations'

export const useDashboard = () => {
  const { data: myInvitation, isLoading: myInvitationLoading } = useGetMyUserInvitation()
  const isInvitedUser = !!myInvitation?.result?.registeredUserId

  const { value: shouldShowNewDashboardToExistingUser } = useFeatureGate(
    NEW_DASHBOARD_FOR_EXISTING_USER_WITH_AT_LEAST_ONE_SCREENING,
  )
  const { isAgentNewDashboardEnabled } = useAgentNewDashboardConfig()

  if (myInvitationLoading) {
    return { isLoading: myInvitationLoading }
  }

  // The existing user should not aware of any experimentConfig or properties
  if (isInvitedUser) {
    return { isNewHome: true, isInvitedUser }
  }

  if (isAgentNewDashboardEnabled) {
    return { isNewHome: true, isInvitedUser }
  }

  return shouldShowNewDashboardToExistingUser
    ? { isNewHome: true, isInvitedUser }
    : { isNewHome: false, isInvitedUser }
}
