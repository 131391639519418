// USER
export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL'
export const REFRESH_PROFILE_REQUEST = 'REFRESH_PROFILE_REQUEST'
export const REFRESH_PROFILE_SUCCESS = 'REFRESH_PROFILE_SUCCESS'
export const REFRESH_PROFILE_FAIL = 'REFRESH_PROFILE_FAIL'
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAIL'
export const UPDATE_FLYER_TEXT_REQUEST = 'UPDATE_FLYER_TEXT_REQUEST'
export const UPDATE_FLYER_TEXT_SUCCESS = 'UPDATE_FLYER_TEXT_SUCCESS'
export const UPDATE_FLYER_TEXT_FAILED = 'UPDATE_FLYER_TEXT_FAILED'
export const GET_USER_PREFERENCE_REQUEST = 'GET_USER_PREFERENCE_REQUEST'
export const GET_USER_PREFERENCE_SUCCESS = 'GET_USER_PREFERENCE_SUCCESS'
export const GET_USER_PREFERENCE_FAILED = 'GET_USER_PREFERENCE_FAILED'
export const UPDATE_USER_PREFERENCE_REQUEST = 'UPDATE_USER_PREFERENCE_REQUEST'
export const UPDATE_USER_PREFERENCE_SUCCESS = 'UPDATE_USER_PREFERENCE_SUCCESS'
export const UPDATE_USER_PREFERENCE_FAILED = 'UPDATE_USER_PREFERENCE_FAILED'
export const GET_USER_INTEGRATION_REQUEST = 'GET_USER_INTEGRATION_REQUEST'
export const GET_USER_INTEGRATION_SUCCESS = 'GET_USER_INTEGRATION_SUCCESS'
export const GET_USER_INTEGRATION_FAIL = 'GET_USER_INTEGRATION_FAIL'

export const TALK_JS_READY = 'TALK_JS_READY'
export const GET_TALK_JS_USER_SIGNATURE_REQUEST = 'GET_TALK_JS_USER_SIGNATURE_REQUEST'
export const GET_TALK_JS_USER_SIGNATURE_SUCCESS = 'GET_TALK_JS_USER_SIGNATURE_SUCCESS'
export const GET_TALK_JS_USER_SIGNATURE_FAIL = 'GET_TALK_JS_USER_SIGNATURE_FAIL'

// AUTHENTICATION
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGIN_FACEBOOK_FAILED = 'LOGIN_FACEBOOK_FAILED'
export const LOGIN_GOOGLE_FAILED = 'LOGIN_GOOGLE_FAILED'
export const REMOVE_ACCESS_TOKEN = 'REMOVE_ACCESS_TOKEN'
export const REGISTER_FAILED = 'REGISTER_FAILED'
export const SAVE_ACCESS_TOKEN = 'SAVE_ACCESS_TOKEN'
export const FETCHING_REFRESH_TOKEN = 'FETCHING_REFRESH_TOKEN'

// MISC
export const OPEN_SWEET_ALERT = 'OPEN_SWEET_ALERT'
export const CLOSE_SWEET_ALERT = 'CLOSE_SWEET_ALERT'
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE'

// CREDIT REPORT
export const GET_CREDIT_REPORT_REQUEST = 'GET_CREDIT_REPORT_REQUEST'
export const GET_CREDIT_REPORT_SUCCESS = 'GET_CREDIT_REPORT_SUCCESS'
export const GET_CREDIT_REPORT_FAILED = 'GET_CREDIT_REPORT_FAILED'
export const CLEAR_CREDIT_REPORT_STATE = 'CLEAR_CREDIT_REPORT_STATE'
export const CLEAR_CREDIT_REPORT_DATA = 'CLEAR_CREDIT_REPORT_DATA'
export const OPEN_CREDIT_REPORT_REQUEST = 'OPEN_CREDIT_REPORT_REQUEST'
export const OPEN_CREDIT_REPORT_SUCCESS = 'OPEN_CREDIT_REPORT_SUCCESS'
export const OPEN_CREDIT_REPORT_FAILED = 'OPEN_CREDIT_REPORT_FAILED'
export const CLEAR_CREDIT_REPORT_SCORE = 'CLEAR_CREDIT_REPORT_SCORE'
export const GET_CREDIT_PAYMENT_REQUEST = 'GET_CREDIT_PAYMENT_REQUEST'
export const GET_CREDIT_PAYMENT_FAILED = 'GET_CREDIT_PAYMENT_FAILED'
export const GET_CREDIT_PAYMENT_SUCCESS = 'GET_CREDIT_PAYMENT_SUCCESS'
export const SUBMIT_CREDIT_PAYMENT_REQUEST = 'SUBMIT_CREDIT_PAYMENT_REQUEST'
export const SUBMIT_CREDIT_PAYMENT_FAILED = 'SUBMIT_CREDIT_PAYMENT_FAILED'
export const SUBMIT_CREDIT_PAYMENT_SUCCESS = 'SUBMIT_CREDIT_PAYMENT_SUCCESS'
export const CLEAR_CREDIT_PAYMENT = 'CLEAR_CREDIT_PAYMENT'
export const UPDATE_STATUS_CREDIT_REPORT = 'UPDATE_STATUS_CREDIT_REPORT'
export const DISABLED_PAYMENT_BTN = 'DISABLED_PAYMENT_BTN'
export const UPDATE_CURRENT_REPORT_STATUS = 'UPDATE_CURRENT_REPORT_STATUS'
export const CLEAR_PAYMENT_ERROR = 'CLEAR_PAYMENT_ERROR'

// PROPERTY
export const GET_PROPERTY_LIST_REQUEST = 'GET_PROPERTY_LIST_REQUEST'
export const GET_PROPERTY_LIST_SUCCESS = 'GET_PROPERTY_LIST_SUCCESS'
export const GET_PROPERTY_LIST_FAILED = 'GET_PROPERTY_LIST_FAILED'
export const GET_PROPERTY_REQUEST = 'GET_PROPERTY_REQUEST'
export const GET_PROPERTY_SUCCESS = 'GET_PROPERTY_SUCCESS'
export const GET_PROPERTY_FAILED = 'GET_PROPERTY_FAILED'
export const GET_PERMALINK_REQUEST = 'GET_PERMALINK_REQUEST'
export const GET_PERMALINK_SUCCESS = 'GET_PERMALINK_SUCCESS'
export const GET_PERMALINK_FAILED = 'GET_PERMALINK_FAILED'
export const GET_ZIPLOGIX_PROPERTY_REQUEST = 'GET_ZIPLOGIX_PROPERTY_REQUEST'
export const GET_ZIPLOGIX_PROPERTY_SUCCESS = 'GET_ZIPLOGIX_PROPERTY_SUCCESS'
export const GET_ZIPLOGIX_PROPERTY_FAILED = 'GET_ZIPLOGIX_PROPERTY_FAILED'
export const GET_PROPERTY_DROP_DOWN_REQUEST = 'GET_PROPERTY_DROP_DOWN_REQUEST'
export const GET_PROPERTY_DROP_DOWN_SUCCESS = 'GET_PROPERTY_DROP_DOWN_SUCCESS'
export const GET_PROPERTY_DROP_DOWN_FAILED = 'GET_PROPERTY_DROP_DOWN_FAILED'
export const CREATE_PROPERTY_REQUEST = 'CREATE_PROPERTY_REQUEST'
export const CREATE_PROPERTY_SUCCESS = 'CREATE_PROPERTY_SUCCESS'
export const CREATE_PROPERTY_FAILED = 'CREATE_PROPERTY_FAILED'
export const CLEAR_DISABLED_FEATURES = 'CLEAR_DISABLED_FEATURES'
export const CLEAR_PROPERTY = 'CLEAR_PROPERTY'
export const CLEAR_CREATE_PROPERTY_NOTI = 'CLEAR_CREATE_PROPERTY_NOTI'
export const REACTIVATE_PROPERTY_REQUEST = 'REACTIVATE_PROPERTY_REQUEST'
export const REACTIVATE_PROPERTY_SUCCESS = 'REACTIVATE_PROPERTY_SUCCESS'
export const REACTIVATE_PROPERTY_FAILED = 'REACTIVATE_PROPERTY_FAILED'
export const DEACTIVATE_PROPERTY_REQUEST = 'DEACTIVATE_PROPERTY_REQUEST'
export const DEACTIVATE_PROPERTY_SUCCESS = 'DEACTIVATE_PROPERTY_SUCCESS'
export const DEACTIVATE_PROPERTY_FAILED = 'DEACTIVATE_PROPERTY_FAILED'

// APPLICATION
export const GET_APPLICATION_LIST_REQUEST = 'GET_APPLICATION_LIST_REQUEST'
export const GET_APPLICATION_LIST_SUCCESS = 'GET_APPLICATION_LIST_SUCCESS'
export const GET_APPLICATION_LIST_FAILED = 'GET_APPLICATION_LIST_FAILED'
export const GET_APPLICATION_REQUEST = 'GET_APPLICATION_REQUEST'
export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS'
export const GET_APPLICATION_FAILED = 'GET_APPLICATION_FAILED'
// RENTAL SUBMISSION
export const GET_FORM_AVAILABLE_REQUEST = 'GET_FORM_AVAILABLE_REQUEST'
export const GET_FORM_AVAILABLE_SUCCESS = 'GET_FORM_AVAILABLE_SUCCESS'
export const GET_FORM_AVAILABLE_FAILED = 'GET_FORM_AVAILABLE_FAILED'
export const CLEAR_RENTAL_DETAIL = 'CLEAR_RENTAL_DETAIL'
export const SET_REPORT_FETCHING = 'SET_REPORT_FETCHING'
export const CLEAR_REPORT_FETCHING = 'CLEAR_REPORT_FETCHING'

// OTHER DOCUMENTS
export const GET_OTHER_DOCS_REQUEST = 'GET_OTHER_DOCUMENTS_REQUEST'
export const GET_OTHER_DOCS_SUCCESS = 'GET_OTHER_DOCUMENTS_SUCCESS'
export const GET_OTHER_DOCS_FAILED = 'GET_OTHER_DOCUMENTS_FAILED'

// REQUEST
export const SAVE_SELECTED_PROPERTY = 'SAVE_SELECTED_PROPERTY'
export const SEND_APPLICANTS_REQUEST = 'SEND_APPLICANTS_REQUEST'
export const SEND_APPLICANTS_SUCCESS = 'SEND_APPLICANTS_SUCCESS'
export const SEND_APPLICANTS_FAILED = 'SEND_APPLICANTS_FAILED'
export const INIT_SEND_APPLICANTS = 'INIT_SEND_APPLICANTS'
export const SELECT_RENTER_AS_PAYER = 'SELECT_RENTER_AS_PAYER'
export const SELECT_AGENT_AS_PAYER = 'SELECT_AGENT_AS_PAYER'
export const CLEAR_REQUEST_OPTIONS = 'CLEAR_REQUEST_OPTIONS'
export const CLEAR_IS_SEND_EMAIL = 'CLEAR_IS_SEND_EMAIL'
export const SEND_TEXT_MESSAGE_REQUEST = 'SEND_TEXT_MESSAGE_REQUEST'
export const SEND_TEXT_MESSAGE_SUCCESS = 'SEND_TEXT_MESSAGE_SUCCESS'
export const SEND_TEXT_MESSAGE_FAILED = 'SEND_TEXT_MESSAGE_FAILED'
export const SEND_MORE_TEXT_MESSAGE = 'SEND_MORE_TEXT_MESSAGE'
export const SET_APPLICATION_TYPE = 'SET_APPLICATION_TYPE'
export const SELECT_PRICING_SCHEME = 'SELECT_PRICING_SCHEME'
export const GET_SCREENING_PLAN_REQUEST = 'GET_SCREENING_PLAN_REQUEST'
export const GET_SCREENING_PLAN_SUCCESS = 'GET_SCREENING_PLAN_SUCCESS'
export const GET_SCREENING_PLAN_FAILED = 'GET_SCREENING_PLAN_FAILED'

export const CREATE_SCREENING_REQUEST_REQUEST = 'CREATE_SCREENING_REQUEST_REQUEST'
export const CREATE_SCREENING_REQUEST_SUCCESS = 'CREATE_SCREENING_REQUEST_SUCCESS'
export const CREATE_SCREENING_REQUEST_FAILED = 'CREATE_SCREENING_REQUEST_FAILED'
export const SET_SCREENING_OPTION_VALIDATION_ERROR = 'SET_SCREENING_OPTION_VALIDATION_ERROR'
export const CLEAR_SCREENING_OPTION_VALIDATION_ERROR = 'CLEAR_SCREENING_OPTION_VALIDATION_ERROR'

export const SHOW_VIDEO_TUTORIAL_MODAL = 'SHOW_VIDEO_TUTORIAL_MODAL'
export const SHOW_VIDEO_VIEW_PROCESS_MODAL = 'SHOW_VIDEO_VIEW_PROCESS_MODAL'
export const SHOW_PRO_RESTRICTIONS_MODAL = 'SHOW_PRO_RESTRICTIONS_MODAL'
export const SHOW_OPEN_CREDIT_REPORT_MODAL = 'SHOW_OPEN_CREDIT_REPORT_MODAL'
export const SHOW_DEACTIVATE_PROPERTY_MODAL = 'SHOW_DEACTIVATE_PROPERTY_MODAL'
export const SHOW_REACTIVATE_PROPERTY_MODAL = 'SHOW_REACTIVATE_PROPERTY_MODAL'
export const SHOW_LRA_LEARN_MORE_MODAL = 'SHOW_LRA_LEARN_MORE_MODAL'
export const SHOW_GETTING_STARTED_MODAL = 'SHOW_GETTING_STARTED_MODAL'
export const SHOW_POST_LOG_IN_ADVERTISE_MODAL = 'SHOW_POST_LOG_IN_ADVERTISE_MODAL'

export const GET_SUBSCRIPTIONS_AUTH_REQUEST = 'GET_SUBSCRIPTIONS_AUTH_REQUEST'
export const GET_SUBSCRIPTIONS_AUTH_SUCCESS = 'GET_SUBSCRIPTIONS_AUTH_SUCCESS'
export const GET_SUBSCRIPTIONS_AUTH_FAILED = 'GET_SUBSCRIPTIONS_AUTH_FAILED'
export const CLEAR_SUBSCRIPTIONS_AUTH = 'CLEAR_SUBSCRIPTIONS_AUTH'
export const REDEEM_COUPON_REQUEST = 'REDEEM_COUPON_REQUEST'
export const REDEEM_COUPON_SUCCESS = 'REDEEM_COUPON_SUCCESS'
export const REDEEM_COUPON_FAILED = 'REDEEM_COUPON_FAILED'
export const REDEEM_COUPON_CLEAR_ERROR = 'REDEEM_COUPON_CLEAR_ERROR'
export const REDEEM_COUPON_CLEAR_DATA = 'REDEEM_COUPON_CLEAR_DATA'
export const GET_MY_SUBSCRIPTION_REQUEST = 'GET_MY_SUBSCRIPTION_REQUEST'
export const GET_MY_SUBSCRIPTION_SUCCESS = 'GET_MY_SUBSCRIPTION_SUCCESS'
export const GET_MY_SUBSCRIPTION_FAILED = 'GET_MY_SUBSCRIPTION_FAILED'
export const GET_SUBSCRIPTION_PLANS_REQUEST = 'GET_SUBSCRIPTION_PLANS_REQUEST'
export const GET_SUBSCRIPTION_PLANS_SUCCESS = 'GET_SUBSCRIPTION_PLANS_SUCCESS'
export const GET_SUBSCRIPTION_PLANS_FAILED = 'GET_SUBSCRIPTION_PLANS_FAILED'

// REFERENCE CHECKS
export const UPDATE_SELECTED_REFERENCE_CHECK = 'UPDATE_SELECTED_REFERENCE_CHECK'
export const UPDATE_SELECTED_REFERENCE_CHECK_ALL = 'UPDATE_SELECTED_REFERENCE_CHECK_ALL'
export const GET_REFERENCE_CHECK_REQUEST = 'GET_REFERENCE_CHECK_REQUEST'
export const GET_REFERENCE_CHECK_SUCCESS = 'GET_REFERENCE_CHECK_SUCCESS'
export const GET_REFERENCE_CHECK_FAILED = 'GET_REFERENCE_CHECK_FAILED'
export const POST_REFERENCE_CHECK_REQUEST = 'POST_REFERENCE_CHECK_REQUEST'
export const POST_REFERENCE_CHECK_SUCCESS = 'POST_REFERENCE_CHECK_SUCCESS'
export const POST_REFERENCE_CHECK_FAILED = 'POST_REFERENCE_CHECK_FAILED'

// LRA FORM
export const GET_LRA_FORM_REQUEST = 'GET_LRA_FORM_REQUEST'
export const GET_LRA_FORM_SUCCESS = 'GET_LRA_FORM_SUCCESS'
export const GET_LRA_FORM_FAILED = 'GET_LRA_FORM_FAILED'

// REDIRECT

export const CALL_REDIRECT = 'CALL_REDIRECT'
export const CANCEL_REDIRECT = 'CANCEL_REDIRECT'

// ALL RENTSPREE PRO REQUEST ACTIONS
export const RENTSPREE_PRO_REQUESTS = [
  GET_REFERENCE_CHECK_REQUEST,
  POST_REFERENCE_CHECK_REQUEST,
  GET_OTHER_DOCS_REQUEST,
]

// RENTAL SUBMISSION V2
export const GET_RENTAL_BY_ID_REQUEST = 'GET_RENTAL_BY_ID_REQUEST'
export const GET_RENTAL_BY_ID_SUCCESS = 'GET_RENTAL_BY_ID_SUCCESS'
export const GET_RENTAL_BY_ID_FAILED = 'GET_RENTAL_BY_ID_FAILED'

// SUBMISSION PARTICIPANT
export const GET_SUBMISSION_PARTICIPANT_REQUEST = 'GET_SUBMISSION_PARTICIPANT_REQUEST'
export const GET_SUBMISSION_PARTICIPANT_SUCCESS = 'GET_SUBMISSION_PARTICIPANT_SUCCESS'
export const GET_SUBMISSION_PARTICIPANT_FAILED = 'GET_SUBMISSION_PARTICIPANT_FAILED'

// AGENT OPEN REPORT EXECUTION
export const AGENT_OPEN_REPORT_EXECUTION_REQUEST = 'AGENT_OPEN_REPORT_EXECUTION_REQUEST'
export const AGENT_OPEN_REPORT_EXECUTION_SUCCESS = 'AGENT_OPEN_REPORT_EXECUTION_SUCCESS'
export const AGENT_OPEN_REPORT_EXECUTION_FAILED = 'AGENT_OPEN_REPORT_EXECUTION_FAILED'

// CHANGE MOBILE ACTIVE TAB
export const CALL_UPDATE_MOBILE_ACTIVE_TAB = 'CALL_UPDATE_MOBILE_ACTIVE_TAB'

// SSO HANDLER
export const SAVE_URL_PARAMS = 'SAVE_URL_PARAMS'

// OTHER DOC PDF
export const GET_DOC_PDF_REQUEST = 'GET_DOC_PDF_REQUEST'
export const GET_DOC_PDF_SUCCESS = 'GET_DOC_PDF_SUCCESS'
export const GET_DOC_PDF_FAILED = 'GET_DOC_PDF_FAILED'

export const SHOW_DOC_PDF_SUCCESS = 'SHOW_DOC_PDF_SUCCESS'

// DOWNLOAD FULL DOCUMENT IN ZIP FILE
export const DOWNLOAD_FULL_DOC_ZIP_REQUEST = 'DOWNLOAD_FULL_DOC_ZIP_REQUEST'
export const DOWNLOAD_FULL_DOC_ZIP_SUCCESS = 'DOWNLOAD_FULL_DOC_ZIP_SUCCESS'
export const DOWNLOAD_FULL_DOC_ZIP_FAILED = 'DOWNLOAD_FULL_DOC_ZIP_FAILED'

// UNSEEN VIEW_MODALS
export const GET_UNSEEN_VIEW_MODAL_REQUEST = 'GET_UNSEEN_VIEW_MODAL_REQUEST'
export const GET_UNSEEN_VIEW_MODAL_SUCCESS = 'GET_UNSEEN_VIEW_MODAL_SUCCESS'
export const GET_UNSEEN_VIEW_MODAL_FAILED = 'GET_UNSEEN_VIEW_MODAL_FAILED'

// POST LOGIN ADVERTISE CHECKBOX
export const NOT_SHOW_POST_LOG_IN_ADVERTISE_CHECKED = 'NOT_SHOW_POST_LOG_IN_ADVERTISE_CHECKED'

// SCREENING PRO OPTION
export const UPDATE_DOCUMENT_PRO_OPTION = 'UPDATE_DOCUMENT_PRO_OPTION'
export const UPDATE_REF_CHECK_PRO_OPTION = 'UPDATE_REF_CHECK_PRO_OPTION'
export const CLEAR_SCREENING_WITH_PROPERTY_PRO_OPTIONS = 'CLEAR_SCREENING_WITH_PROPERTY_PRO_OPTIONS'

// PENDING REPORT
export const CLOSE_PENDING_REPORT_BANNER = 'CLOSE_PENDING_REPORT_BANNER'