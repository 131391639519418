import { combineReducers } from 'redux'
import transform from 'lodash/transform'
import isString from 'lodash/isString'
import isObject from 'lodash/isObject'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import lowerFirst from 'lodash/lowerFirst'
import * as Types from '../constants/action-types'
const iniCredit = {
  total: 0,
  price: {},
  screening_option: {},
}

export const mapKeyToLowerFirst = obj =>
  transform(obj, (result, value, key) => {
    const currentKey = isString(key) ? lowerFirst(key) : key
    // eslint-disable-next-line no-param-reassign
    result[currentKey] = isObject(value) ? mapKeyToLowerFirst(value) : value
    return result
  })

const creditReportDetail = (state = iniCredit, action) => {
  const CreditReport = get(action, 'creditReport.tuApplication.Applicants[0].CreditReport')
  switch (action.type) {
    case Types.GET_CREDIT_REPORT_SUCCESS: {
      const { creditReport } = action
      if (creditReport && isEmpty(creditReport.score)) {
        creditReport.score = {
          creditScore: 0,
          criminalRecords: 0,
          evictionRecords: 0,
        }
      }
      if (!isEmpty(CreditReport)) {
        creditReport.tuApplication.Applicants[0].CreditReport = mapKeyToLowerFirst(CreditReport)
      }
      return creditReport
    }
    case Types.OPEN_CREDIT_REPORT_REQUEST:
    case Types.CLEAR_CREDIT_REPORT_DATA:
      return iniCredit
    default:
      return state
  }
}
const creditPayment = (state = {}, action) => {
  switch (action.type) {
    case Types.GET_CREDIT_PAYMENT_SUCCESS:
      return { ...action.data }
    case Types.CLEAR_CREDIT_PAYMENT:
      return {}
    default:
      return state
  }
}

const isRunCreditReport = (state = false, action) => {
  switch (action.type) {
    case Types.OPEN_CREDIT_REPORT_SUCCESS:
      return true
    case Types.CLEAR_CREDIT_REPORT_STATE:
    case Types.OPEN_CREDIT_REPORT_FAILED:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action = {}) => {
  switch (action.type) {
    case Types.GET_CREDIT_REPORT_FAILED:
    case Types.GET_CREDIT_PAYMENT_FAILED:
      return action.message || 'error credit report'
    case Types.CLEAR_CREDIT_REPORT_STATE:
    case Types.GET_CREDIT_REPORT_REQUEST:
    case Types.GET_CREDIT_REPORT_SUCCESS:
      return null
    case Types.SUBMIT_CREDIT_PAYMENT_FAILED:
      if (action) {
        // return action.data.error.message || "error submit credit report"
        return {
          message:
            action?.payload?.message ||
            "Your payment didn't go through. Please check your payment information, use a different payment method, or try again later.",
          contact:
            'If the problem persists, please contact us at (323) 515-7757 or support@rentspree.com.',
        }
      }
      return null
    case Types.CLEAR_PAYMENT_ERROR:
      return null
    default:
      return state
  }
}

const errorOpen = (state = null, action) => {
  switch (action.type) {
    case Types.OPEN_CREDIT_REPORT_FAILED:
      return { ...action.meta }
    case Types.CLEAR_CREDIT_REPORT_STATE:
    case Types.OPEN_CREDIT_REPORT_REQUEST:
    case Types.OPEN_CREDIT_REPORT_SUCCESS:
      return null
    default:
      return state
  }
}

const isFetchingRunCreditReport = (state = false, action) => {
  switch (action.type) {
    case Types.OPEN_CREDIT_REPORT_REQUEST:
      return true
    case Types.OPEN_CREDIT_REPORT_SUCCESS:
    case Types.OPEN_CREDIT_REPORT_FAILED:
      return false
    default:
      return state
  }
}
const isFetchingCreditPayment = (state = false, action) => {
  switch (action.type) {
    case Types.GET_CREDIT_PAYMENT_REQUEST:
    case Types.SUBMIT_CREDIT_PAYMENT_REQUEST:
      return true
    case Types.GET_CREDIT_PAYMENT_FAILED:
    case Types.GET_CREDIT_PAYMENT_SUCCESS:
    case Types.SUBMIT_CREDIT_PAYMENT_FAILED:
    case Types.SUBMIT_CREDIT_PAYMENT_SUCCESS:
      return false
    default:
      return state
  }
}
const isFetchingCreditReport = (state = false, action) => {
  switch (action.type) {
    case Types.GET_CREDIT_REPORT_REQUEST:
      return true
    case Types.GET_CREDIT_REPORT_SUCCESS:
    case Types.GET_CREDIT_REPORT_FAILED:
      return false
    case Types.CLEAR_CREDIT_REPORT_STATE:
      return false
    default:
      return state
  }
}

const isGetCreditReportFail = (state = false, action) => {
  switch (action.type) {
    case Types.GET_CREDIT_REPORT_FAILED:
      return true
    case Types.GET_CREDIT_REPORT_SUCCESS:
    case Types.GET_CREDIT_REPORT_REQUEST:
      return false
    case Types.CLEAR_CREDIT_REPORT_STATE:
      return false
    default:
      return state
  }
}

const isGetCreditPaymentFail = (state = false, action) => {
  switch (action.type) {
    case Types.GET_CREDIT_PAYMENT_FAILED:
      return true
    case Types.GET_CREDIT_PAYMENT_SUCCESS:
    case Types.GET_CREDIT_PAYMENT_REQUEST:
      return false
    default:
      return state
  }
}

export const disabledPaymentBtn = (state = true, action) => {
  switch (action.type) {
    case Types.DISABLED_PAYMENT_BTN:
      return action.data
    default:
      return state
  }
}

export const pendingReportBannersOpen = (state = null, action) => {
  switch (action.type) {
    case Types.CLOSE_PENDING_REPORT_BANNER:
      return {
        ...state,
        [action.reportType]: false
      }
    default:
      return state
  }
}

export default combineReducers({
  creditReportDetail,
  creditPayment,
  isFetchingCreditReport,
  isGetCreditReportFail,
  isRunCreditReport,
  isFetchingRunCreditReport,
  isFetchingCreditPayment,
  isGetCreditPaymentFail,
  errorMessage,
  errorOpen,
  disabledPaymentBtn,
  pendingReportBannersOpen,
})
