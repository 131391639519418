export const REVIEW = {
  TITLE: 'Review and send payment request',
  DESCRIPTION:
    'Make sure everything looks good. We’ll notify the tenant once you send the request.',
  TENANT_AND_PROPERTY: 'Tenant and property',
  PAYMENT_DETAILS: {
    title: 'Payment details',
    description: 'Funds are deposited within 2-5 business days after the tenant makes the payment.',
  },
  MONTHLY_PAYMENTS: 'Monthly payments',
  ONE_TIME_PAYMENTS: 'One-time payments',
  RECIPIENT_DETAILS: 'Recipient details',
  INVITE_BY_TEXT: 'inviteByText',
}
