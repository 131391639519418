import AddIcon from '@mui/icons-material/Add'
import { Grid, Typography } from '@mui/material'
import { Chip } from '@rentspree/component-2023.components.atoms.chip'
import Snackbar from '@rentspree/component-2023.components.atoms.snackbar'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'

import { PaymentsCard } from 'v3/containers/overhaul-rent-payment/components/cards/payments-card'
import { TellUsAboutPaymentDrawer } from 'v3/containers/overhaul-rent-payment/components/payment-forms/tell-us-about-payment'
import {
  ONE_TIME_FEES,
  MULTIPLE_SELECTION_PAYMENTS,
  PAYMENT_TYPES,
  RENT_PAYMENTS,
  PaymentCategories,
} from 'v3/containers/overhaul-rent-payment/constants'
import {
  findPaymentCategory,
  generateAPIQuotation,
  updatePayment,
} from 'v3/containers/overhaul-rent-payment/pages/utils'

const { ONE_TIME } = PAYMENT_TYPES
const initialCurrentFee = {
  payment: {
    category: '',
    customCategory: '',
    type: '',
    amount: '',
    startDate: '',
    dueDate: '',
    dueOn: '',
    endDate: '',
    description: '',
  },
  index: NaN,
}

/*
 * Params are passed from payment-details/page.js
 * See <CurrentPage ... /> at the bottom of file
 *
 * @param {Object[]} payments - payments list from PaymentInfoContext
 * @param {function} setPayments - update payments list in PaymentInfoContext
 * @param {function} setDrawerOpen - update drawerOpen state in PageIndexesContext
 */
export const DepositAndFees = ({
  payments,
  setPayments,
  setDrawerOpen,
  pageIndexes,
  setIsValid,
  isValid,
  _selectionOptions,
  _paymentSelections,
  _setPaymentSelections,
}) => {
  const feePayments = payments.filter(p => !RENT_PAYMENTS.includes(p?.category))
  const [currentFee, setCurrentFee] = useState(initialCurrentFee)
  const [isNewPayment, setIsNewPayment] = useState(false)
  const { payment: feePayment, index: feeIndex } = currentFee
  const [showPaymentDeletedSnackBar, setShowPaymentDeletedSnackBar] = useState(false)
  /*
   * Filter out one time payments that are already in the fees list
   * MULTIPLE_SELECTION_PAYMENTS (Utilities, Others) can be added multiple times
   */
  const filteredOneTimePayments = ONE_TIME_FEES.filter(
    payment => !feePayments.some(fp => fp?.category === payment),
  )

  const combinedPayments = [...filteredOneTimePayments, ...MULTIPLE_SELECTION_PAYMENTS]

  const setFeePayment = paymentData => {
    setCurrentFee({
      ...currentFee,
      payment: { ...feePayment, ...paymentData },
    })
  }

  useEffect(() => {
    setIsValid(feePayments?.length >= 1)
  }, [feePayments.length])

  const handleEditPayment = ({ isOpen = true, payment, paymentIndex }) => {
    /*
     * 'beginningDate' allows for editing a payment to switch between one-time & recurring types,
     * and the start/due date to stay synced for hte user experience
     */
    const beginningDate = payment?.startDate || payment?.dueDate
    // since some fees are oneTime only, set a type default to oneTime to match to start off
    const type = payment?.type || ONE_TIME

    setCurrentFee({
      payment: { ...payment, startDate: beginningDate, dueDate: beginningDate, type },
      index: paymentIndex,
    })
    setIsNewPayment(false)
    setDrawerOpen(isOpen)
  }

  const handleAddFee = category => {
    const [paymentIndex, payment] = findPaymentCategory(payments, category)

    // check for API payments with OTHER/custom categories
    const categoryCustom = !Object.values(PaymentCategories).includes(payment?.category)
    payment.customCategory = ''

    if (categoryCustom) {
      payment.customCategory = payment.category
      payment.category = PaymentCategories.OTHER
    }

    handleEditPayment({ isOpen: true, payment, paymentIndex })
  }

  const handleResetFee = () => {
    setCurrentFee(initialCurrentFee)
    setDrawerOpen(false)
    setIsValid(feePayments?.length >= 1)
  }

  const handleOnSave = debounce(() => {
    const quotation = generateAPIQuotation(feePayment)

    if (Number.isNaN(feeIndex)) {
      // no fee selected or payment invalid scenarios, don't do anything -- maybe add error logs later
      return
    }

    updatePayment({ nextPayment: quotation, payments, setPayments, paymentIndex: feeIndex })
    handleResetFee()
  }, 400)

  const handleOnBack = () => {
    if (!isNewPayment) {
      const newPayments = [...payments]
      setPayments(newPayments.filter(p => p.category !== feePayment.category))
    }
    handleResetFee()
    setShowPaymentDeletedSnackBar(true)
  }

  return (
    <Grid container gap={4}>
      <Grid container sx={{ justifyContent: 'flex-start' }}>
        <Typography variant="title" align="left">
          What deposits and fees are you collecting?
        </Typography>
      </Grid>
      <Grid container gap={2}>
        {combinedPayments.map(payment => (
          <Chip
            key={payment}
            startIcon={<AddIcon />}
            label={payment}
            onClick={() => {
              handleAddFee(payment)
              setIsNewPayment(true)
            }}
            sx={{
              fontSize: '1rem',
            }}
          />
        ))}
      </Grid>
      <Grid item sx={{ width: '100%', margin: 'auto' }}>
        {payments.map((payment, index) => {
          let component
          if (!RENT_PAYMENTS.includes(payment?.category)) {
            component = (
              <PaymentsCard
                key={`${payment?.category}`}
                payment={payment}
                paymentIndex={index}
                setDrawerOpen={handleEditPayment}
              />
            )
          }
          return component
        })}
      </Grid>

      <Snackbar
        sx={{ zIndex: 7500, marginBottom: '81px' }}
        open={showPaymentDeletedSnackBar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3000}
        message="Payment deleted"
        onClose={() => setShowPaymentDeletedSnackBar(false)}
      />

      <TellUsAboutPaymentDrawer
        payment={feePayment}
        setPayment={setFeePayment}
        drawerOpen={pageIndexes.drawerOpen}
        onClose={handleResetFee}
        onSave={handleOnSave}
        onBack={handleOnBack}
        paymentOptions={combinedPayments}
        isNewPayment={isNewPayment}
        setIsValid={setIsValid}
        isValid={isValid}
      />
    </Grid>
  )
}
