import { createSelector } from "reselect"
import get from "lodash/get"
import moment from "moment"
import isEmpty from "lodash/isEmpty"
import { generateFullName } from '@rentspree/helper'

const initialState = {}
const getCreditReport = state => get(state, "creditReport", {})
const getCreditReportDetail = state =>
  get(state, "creditReport.creditReportDetail", initialState)

export const selectCreditReportDetail = createSelector(
  getCreditReportDetail,
  state => state,
)

export const selectApplicant = createSelector(
  getCreditReportDetail,
  state => get(state, "tuApplication.Applicants[0]", {}),
)

export const selectExternalRenter = createSelector(
  getCreditReportDetail,
  state => get(state, "transunion_application.externalRenter") || {},
)

export const makeSelectedExternalRenter = createSelector(selectExternalRenter, extRenter => {
  if(isEmpty(extRenter)) return extRenter
  return {
    ...extRenter,
    fullName: generateFullName(extRenter)
  }
})

export const selectExternalLandlord = createSelector(
  getCreditReportDetail,
  state => get(state, "transunion_application.externalLandlord") || {},
)

export const selectBundleId = createSelector(
  getCreditReportDetail,
  state => get(state, "transunion_application.bundleId") || {},
)

export const selectApplicationCreationDate = createSelector(
  getCreditReportDetail,
  state => get(state, "transunion_application.createdAt"),
)


export const makeSelectedExternalLandlord = createSelector(selectExternalLandlord, extLandlord => {
  if(isEmpty(extLandlord)) return extLandlord
  let fullName = generateFullName(extLandlord)
  if (extLandlord.isRentSpreeAddress) {
    fullName = fullName.replace(/ /g, '')
  }
  return {
    ...extLandlord,
    fullName,
  }
})

export const selectGeneratedOn = createSelector(
  getCreditReportDetail,
  state => get(state, "generated_at"),
)

export const makeSelectedExpiresOn = createSelector(
  getCreditReportDetail,
  state => moment(get(state, "generated_at")).add(30, "days"),
)

export const selectIsFetchingCreditReport = createSelector(
  getCreditReport,
  state => get(state, "isFetchingCreditReport"),
)

export const selectCreditReportErrorMessage = createSelector(
  getCreditReport,
  state => get(state, "errorMessage"),
)

export const selectPendingReportBannersOpen = createSelector(
  getCreditReport, 
  state => get(state, "pendingReportBannersOpen", {})
)