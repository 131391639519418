import React from 'react'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import isNil from 'lodash/isNil'
import toNumber from 'lodash/toNumber'
import findIndex from 'lodash/findIndex'
import indexOf from 'lodash/indexOf'
import includes from 'lodash/includes'
import startsWith from 'lodash/startsWith'
import { query, buildPath } from '@rentspree/path'
import { Button } from '@rentspree/component-2023.components.atoms.button'
import { Level2Template } from '@rentspree/component-2023.components.templates.level-2-template'
import tracker from 'tracker'
import {
  EVENT_ACTION_DROPDOWN,
  EVENT_ACTION_DROPDOWN_CLICK_FROM,
  EVENT_REQUEST_SCREENING,
  EVENT_REPORT,
  POST_LOGIN_EXPERIENCE_EVENT,
  LEASE_AGREEMENTS,
  RENT_PAYMENT,
  EVENT_TENANT_SCREENING,
} from 'tracker/const'
import { SCREENING_METHODS } from 'legacy/constants/screening-plan-consts'
import { CenterContent, WhiteWrapper, FilterRow, Loading } from 'components/layout/main'
import FilterBarForPropertyApplicationList from 'components/organisms/filter-bar-with-switch-button'
import {
  FEATURE,
  FEATURE_STATUS,
  TOOLTIP,
  SOURCE,
} from 'containers/property/toggle-property-feature/constants'
import ApplicationTable from 'components/application-table'
import { SCREEN_NEW_TENANT } from 'legacy/constants/request-const'
import { compose } from 'redux'
import { BUTTON_TYPE } from 'containers/explore/constants'
import { isAllowAcceptDeny } from 'containers/reports/helper'
import {
  ESIGN_DASHBOARD_SELECT_METHODS,
  PERMALINK_ACTIONS_ID,
  PROPERTY,
  SINGLE_APPLICATION,
} from 'constants/route'
import Breadcrumb2023 from 'containers/breadcrumb-2023'
import { COMPONENT_2023_BREAKPOINT_MOBILE } from 'containers/constants'
import PropertyInnerTabs from 'containers/property-inner-tabs'
import ApplicationListConnect from './application-list-connect'
import Pagination from '../../components/filter/pagination'
import {
  APP_NOT_SUBMIT_STATUS,
  FILTER_APP_PROGRESS_OPTIONS,
} from '../../constants/application-consts'
import { FailedToFetch } from '../../components/layout/failed-to-fetch'
import * as ERRORS from '../../constants/error-messages'
import DeactivatePropertyModal from '../../components/modal/deactivate-property-modal'
import ReactivatePropertyModal from '../../components/modal/reactivate-property-modal'
import { withTracker } from '../with-tracker'
import { POST_LOG_IN_ADVERTISE_MODAL_GROUP } from './const'

const { SCREEN_FROM_CURRENT_PROPERTY, DEACTIVATE_PROPERTY, REACTIVATE_PROPERTY } =
  EVENT_ACTION_DROPDOWN
const { APPLICATION_LIST } = EVENT_ACTION_DROPDOWN_CLICK_FROM

const DEFAULT_QUERY = {
  page: 1,
  perPage: 8,
  filter: { status: FILTER_APP_PROGRESS_OPTIONS[0].value },
  sort: {},
}

// TODO: Remove this after clean up feature flag new_ui_table_property_application
export class ApplicationList extends React.Component {
  constructor() {
    super()
    this.queryOptions = { ...DEFAULT_QUERY }
    this.state = {
      isMobile: window.innerWidth <= COMPONENT_2023_BREAKPOINT_MOBILE,
    }
    this.handleResize = this.handleResize.bind(this)
    this.topElementRef = React.createRef()
  }

  componentWillMount() {
    const { history, location, actions } = this.props
    this.unlisten = history.listen(({ state, search }, action) => {
      if (action === 'POP' || (state && state.clearApplicationListSearch)) {
        this.queryOptions = { ...DEFAULT_QUERY }
        const { page, filter, search: querySearch, sort } = query.parse(search)
        this.getApplicationList(page, filter, querySearch, sort, false)
      }
    })

    const { page, filter, search, sort, prvSource } = query.parse(location.search)

    if (this.validationQuery(page, filter, search, sort)) {
      window.location.href = '/not-found'
    }

    if (prvSource === SCREEN_NEW_TENANT) {
      actions.getUnSeenViewModals(POST_LOG_IN_ADVERTISE_MODAL_GROUP)
    }
    this.getApplicationList(page, filter, search, sort, false)
  }

  componentWillUnmount() {
    this.unlisten()
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidMount() {
    if (this.topElementRef.current) {
      this.topElementRef.current.scrollIntoView()
    }
    tracker.trackEvent(EVENT_REQUEST_SCREENING.ENTER_SCREENING_MENU)
    window.addEventListener('resize', this.handleResize)
  }

  handleResize() {
    const currentIsMobile = window.innerWidth <= COMPONENT_2023_BREAKPOINT_MOBILE
    if (currentIsMobile !== this.state.isMobile) {
      this.setState({ isMobile: currentIsMobile })
    }
  }

  getApplicationList(page, filter = {}, search, sort, pushHistory = true) {
    const { actions, match } = this.props

    this.queryOptions.page = page || this.queryOptions.page
    this.queryOptions.filter = filter.status ? { ...filter } : { ...this.queryOptions.filter }
    this.queryOptions.search = isNil(search) ? this.queryOptions.search : search
    this.queryOptions.sort = sort || this.queryOptions.sort

    const { propertyId } = match.params
    if (propertyId) {
      actions.getApplicationList(propertyId, this.queryOptions)
    }
    if (pushHistory) {
      this.addSearchParam()
    }
  }

  validationQuery(page, filter, search, sort) {
    if (
      (page && isNaN(toNumber(page))) ||
      (filter &&
        filter.status &&
        filter.status !== 'all' &&
        findIndex(FILTER_APP_PROGRESS_OPTIONS, v => v.value === filter.status) === -1) ||
      (search && search.length > 50) ||
      (sort && indexOf(['asc', 'desc'], sort[Object.keys(sort)[0]]) < 0)
    ) {
      return true
    }
    return false
  }

  handleChangePage = page => {
    this.getApplicationList(page)
  }

  handleChangeShowMeAgainCheckBox = () => {
    this.props.actions.togglePostLogInAdvertiseChecked(!this.props.postLogInAdvertiseChecked)
  }

  handleFilter = ({ value }) => {
    this.getApplicationList(1, { status: value })
  }

  handleSearch = search => {
    this.getApplicationList(1, {}, search)
  }

  handleSort = (field = 'updated_at') => {
    const { sort } = this.queryOptions
    const newSort = {}
    if (isEmpty(sort[field])) {
      newSort[field] = 'asc'
    } else {
      newSort[field] = sort[field] === 'desc' ? 'asc' : 'desc'
    }
    this.getApplicationList(null, {}, null, newSort)
  }

  addSearchParam() {
    const { page, filter, search, sort } = this.queryOptions
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: query.stringify({ page, filter, search, sort }),
    })
  }

  /**
   * Event emit on clicking on each application
   * At the point there should be a logic to check whether the credit report had been opened or not
   * If it's not yet open, then the mortal should appear for opening the credit report
   * If it's already open, then push to the application page
   * @param {Object} app application object
   */
  clickApplicationRow = (app = {}) => {
    const {
      history,
      match,
      location: { search },
    } = this.props
    if (includes(APP_NOT_SUBMIT_STATUS, app.status)) {
      // eslint-disable-next-line no-console
      console.log(`App status is ${app.status}`)
    } else {
      tracker.trackEvent(EVENT_REPORT.clickViewReport, {
        click_from: EVENT_TENANT_SCREENING.EVENT_PROPERTY.CLICK_FROM.APPLICATION_LIST,
        click_from_widget: search?.['click_from_widget'],
      })

      history.push(
        buildPath(SINGLE_APPLICATION, {
          propertyId: match.params.propertyId,
          // eslint-disable-next-line no-underscore-dangle
          rentalAppId: app._id,
        }),
      )
    }
  }

  deactivateProperty = () => {
    const { match } = this.props
    this.props.actions.toggleDeactivatePropertyModal(true, match.params.propertyId)
  }

  confirmDeactivateProperty = propertyId => {
    tracker.trackEvent(DEACTIVATE_PROPERTY, { click_from: APPLICATION_LIST })
    this.props.actions.deactivateProperty(propertyId)
  }

  reactivateProperty = () => {
    const { match } = this.props
    this.props.actions.toggleReactivatePropertyModal(true, match.params.propertyId)
  }

  confirmReactivateProperty = propertyId => {
    tracker.trackEvent(REACTIVATE_PROPERTY, { click_from: APPLICATION_LIST })
    this.props.actions.reactivateProperty(propertyId)
  }

  openFeatureToggleConfirmModal = (isEnable, source) => {
    const { actions } = this.props
    actions.openTogglePropertyFeatureModal({
      feature: FEATURE.TENANT_SCREENING,
      type: isEnable ? FEATURE_STATUS.ENABLE : FEATURE_STATUS.DISABLED,
      source,
    })
  }

  handleClosePostLogInAdvertiseModal = from => {
    this.props.actions.togglePostLoginAdvertiseModal(false)
    if (this.props.postLogInAdvertiseChecked) {
      this.props.actions.updateSeenViewModals([POST_LOG_IN_ADVERTISE_MODAL_GROUP])
    }
    const { EVENT_NAME, EVENT_PROPERTY } = POST_LOGIN_EXPERIENCE_EVENT
    tracker.trackEvent(EVENT_NAME.CLICK_TO_CLOSE_MODAL, {
      [EVENT_PROPERTY.CHECKED]: this.props.postLogInAdvertiseChecked,
      [EVENT_PROPERTY.FROM]: from,
    })
  }

  handleTogglePropertyFeature = e =>
    this.openFeatureToggleConfirmModal(e.target.checked, SOURCE.TOGGLE_APP_LIST)

  handleAddScreenNewTenant = () => {
    const { history, disabledFeatures, match } = this.props

    if (disabledFeatures[FEATURE.TENANT_SCREENING]) {
      this.openFeatureToggleConfirmModal(true, SOURCE.SCREEN_APP_LIST)
    } else {
      tracker.trackEvent(SCREEN_FROM_CURRENT_PROPERTY, {
        click_from: APPLICATION_LIST,
      })
      history.push(
        buildPath(
          PERMALINK_ACTIONS_ID,
          {
            propertyId: match.params.propertyId,
          },
          { screeningMethod: SCREENING_METHODS.EMAIL },
        ),
      )
    }
  }

  handleClickFeature = featureLink => {
    if (startsWith(featureLink, '/')) {
      this.props.history.push(featureLink)
    } else {
      window.location.assign(featureLink)
    }
  }

  handleTracking = ({ button, featureName }) => {
    const { EVENT_NAME, EVENT_PROPERTY, EVENT_VALUE } = POST_LOGIN_EXPERIENCE_EVENT
    let eventName
    switch (button) {
      case BUTTON_TYPE.FEATURE: {
        eventName = EVENT_NAME.CLICK_DIRECT_TO_FEATURE
        tracker.trackEvent(eventName, {
          [EVENT_PROPERTY.FEATURE_NAME]: featureName,
          [EVENT_PROPERTY.BUTTON_LOCATION]: EVENT_VALUE.ADS_MODAL,
        })
        break
      }
      case BUTTON_TYPE.LEARN_MORE: {
        eventName = EVENT_NAME.CLICK_LEARN_MORE
        tracker.trackEvent(eventName, {
          [EVENT_PROPERTY.FEATURE_NAME]: featureName,
          [EVENT_PROPERTY.FROM]: EVENT_VALUE.ADS_MODAL,
        })
        break
      }
      default:
        break
    }
  }

  handleOpenAcceptDenyModal = payload => {
    const { actions } = this.props
    actions.openAcceptDenyModal(payload)
  }

  handleSetupRentPayment = (propertyId, tenantEmail) => {
    const { actions } = this.props
    tracker.trackEvent(RENT_PAYMENT.EVENT_NAME.CLICK_SETUP_RENT_PAYMENT, {
      click_from: EVENT_TENANT_SCREENING.EVENT_PROPERTY.CLICK_FROM.MENU_SCREENING_LISTING,
    })
    actions.continueToRentPayment({
      queryString: `?propertyId=${propertyId}&tenantEmail=${encodeURIComponent(tenantEmail)}`,
    })
  }

  handleSetupLease = propertyId => {
    tracker.trackEvent(LEASE_AGREEMENTS.CREATE_LEASE, {
      click_from: EVENT_TENANT_SCREENING.EVENT_PROPERTY.CLICK_FROM.MENU_SCREENING_LISTING,
    })

    const url = buildPath(ESIGN_DASHBOARD_SELECT_METHODS, { propertyId })
    window.location.assign(url)
  }

  handleGoBack = () => {
    const { history } = this.props
    history.push(PROPERTY)
  }

  render() {
    const { applicationList, property, history, disabledFeatures, isAllowCarLease } = this.props
    const { isFetching, pagination, error } = applicationList
    const { search, filter, sort } = this.queryOptions
    const isFilterAll = filter?.status === FILTER_APP_PROGRESS_OPTIONS[0].value
    const hasQueryOption = !isFilterAll || !isEmpty(search)
    const hasAppList = applicationList.list.length > 0 || hasQueryOption
    const disabledTenantScreening = !!disabledFeatures[FEATURE.TENANT_SCREENING]?.disabledAt
    const searchQueries = query.parse(search)

    const { isMobile } = this.state

    if (!isEmpty(error)) {
      return (
        <FailedToFetch
          noBreadcrumbMobile
          withPagination={hasAppList}
          title={ERRORS.APPLICATIONS.LISTING.TITLE}
          text={ERRORS.APPLICATIONS.LISTING.MESSAGE}
        />
      )
    }

    return (
      <>
        <div
          style={{
            position: 'relative',
            top: '-80px',
          }}
          ref={this.topElementRef}
        />
        <Level2Template
          title={property?.street}
          isMobile={isMobile}
          onClickBack={this.handleGoBack}
          cta={
            <>
              {applicationList.list.length !== 0 && (
                <Button variant="contained" size="small" onClick={this.handleAddScreenNewTenant}>
                  Screen tenant
                </Button>
              )}
            </>
          }
          breadcrumb={<Breadcrumb2023 />}
          tabs={<PropertyInnerTabs defaultTab="propertyInnerTabTenantScreening" />}
        />
        <div id="applicationListPage">
          {hasAppList && (
            <FilterRow id="applicationListFilter">
              <FilterBarForPropertyApplicationList
                handleSearch={this.handleSearch}
                handleFilter={this.handleFilter}
                status={filter.status || FILTER_APP_PROGRESS_OPTIONS[0].value}
                search={search}
                options={FILTER_APP_PROGRESS_OPTIONS}
                placeholder="Search applicants' name or email"
                switchBtnTooltip={TOOLTIP}
                switchBtnChecked={!disabledTenantScreening}
                handleSwitchBtn={this.handleTogglePropertyFeature}
                noIconOnMobileSelected
              />
            </FilterRow>
          )}
          <WhiteWrapper
            withBreadcrumb
            withPagination={applicationList.list.length !== 0}
            withStickyFooter
            withFilter={hasAppList}
          >
            {isFetching && (
              <CenterContent overlay withBreadcrumb withFilter>
                <Loading />
              </CenterContent>
            )}
            <ApplicationTable
              data={applicationList.list}
              isLoading={applicationList.isFetching}
              sort={this.handleSort}
              currentSort={!isEmpty(sort) ? sort : { updated_at: 'desc' }}
              isAllowAcceptDeny={isAllowAcceptDeny}
              isAllowCarLease={isAllowCarLease}
              eventSource={{ clickFromWidget: searchQueries?.['click_from_widget'] }}
              onClickRentalSubmissionRow={this.clickApplicationRow}
              onOpenAcceptDenyModal={this.handleOpenAcceptDenyModal}
              onClickSetupLease={this.handleSetupLease}
              onClickSetupRentPayment={this.handleSetupRentPayment}
              property={property}
              hasQueryOption={hasQueryOption}
              history={history}
              disabledTenantScreening={disabledTenantScreening}
            />
            <Pagination pagination={pagination} handleChangePage={this.handleChangePage} />
          </WhiteWrapper>
          <DeactivatePropertyModal
            id="deactivatePropertyModal"
            showModal={this.props.showDeactivatePropertyModal.isShow}
            propertyId={this.props.showDeactivatePropertyModal.propertyId}
            confirmDeactivateProperty={this.confirmDeactivateProperty}
            closeModal={() => this.props.actions.toggleDeactivatePropertyModal(false)}
          />
          <ReactivatePropertyModal
            id="reactivatePropertyModal"
            showModal={this.props.showReactivatePropertyModal.isShow}
            propertyId={this.props.showReactivatePropertyModal.propertyId}
            confirmReactivateProperty={this.confirmReactivateProperty}
            closeModal={() => this.props.actions.toggleReactivatePropertyModal(false)}
          />
          {/* <PostLogInAdvertiseModal
            id="postLogInAdvertiseModal"
            stickyCloseButton
            backdrop="static"
            showModal={this.props.showPostLogInAdvertiseModal}
            doNotShowAgainChecked={this.props.postLogInAdvertiseChecked}
            onShowMeAgainCheckBoxChange={this.handleChangeShowMeAgainCheckBox}
            closeModal={this.handleClosePostLogInAdvertiseModal}
            onClickFeature={this.handleClickFeature}
            onTracking={this.handleTracking}
          /> */}
        </div>
      </>
    )
  }
}

// withFeatureFlag needs to be the right most in compose(), so that the function can receive featureFlagProvider value.
export default compose(
  withTracker,
  withRouter,
  ApplicationListConnect,
)(ApplicationList)
