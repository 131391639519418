import React, { useCallback, useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { AppBar, Box, Dialog, DialogContent, Skeleton, Toolbar } from '@mui/material'
import IconButton from '@rentspree/component-2023.components.atoms.icon-button'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import { useScreenSize } from '../../hooks/use-screen-size'
import './pdf-overlay-content/pdf-overlay-content.scss'
import './pdf-overlay-header/pdf-overlay-header.scss'
import './pdf-overlay-sidebar/pdf-overlay-sidebar.scss'

const PdfOverlayLoading = ({ show, title, onClose, defaultWidth }) => {
  const { isDesktop } = useScreenSize()

  const [pdfWidth, setPdfWidth] = useState(defaultWidth)

  const handleResize = useCallback(() => {
    const newWidth = window.innerWidth < defaultWidth ? window.innerWidth : defaultWidth
    if (pdfWidth !== newWidth) {
      setPdfWidth(newWidth)
    }
  }, [defaultWidth, window.innerWidth])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return (
    <Dialog className="RSPPdfOverlay RSPPdfOverlay-Loading" open={show} fullScreen hideBackdrop>
      <AppBar className="RSPPdfOverlayHeader RSPPdfOverlay-Loading" position="sticky">
        <Toolbar>
          {isDesktop && <Typography variant="title">{title}</Typography>}
          <Box className="RSPPdfOverlayControlGroup" />
          <IconButton color="white" aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {isDesktop && (
        <DialogContent className="RSPPdfOverlaySidebar RSPPdfOverlaySidebar-Loading">
          <Box className="RSPPdfOverlaySidebar-PageContainer">
            <Skeleton
              className="RSPPdfOverlaySidebar-ActivePage"
              variant="rectangular"
              width={120}
              height={180}
              sx={{ bgcolor: '#fff' }}
            />
          </Box>
        </DialogContent>
      )}
      <DialogContent className="RSPPdfOverlayContent RSPPdfOverlayContent-Loading">
        <Box className="RSPPdfOverlayContent-PageContainer">
          <Skeleton
            className="RSPPdfOverlayContent-Page"
            variant="rectangular"
            width={pdfWidth}
            height={pdfWidth * 1.5}
            sx={{ bgcolor: '#fff' }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

PdfOverlayLoading.defaultProps = {
  defaultWidth: 600,
}

export default PdfOverlayLoading
