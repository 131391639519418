import React from 'react'
import { Box } from '@mui/material'

import EmptyState from '@rentspree/component-2023.components.templates.empty-state'
import { TableMobileCard } from './table-mobile-card'
import './table-mobile.scss'

export const PropertyListTableMobile = props => {
  const { data, showDataEmptyState } = props
  if (showDataEmptyState) {
    return (
      <Box data-testid="property-table-empty-state">
        <EmptyState
          image={
            <img
              src="https://cdn.rentspree.com/static-files/zero-state/search-not-found-empty-state.svg"
              alt="search property empty state"
            />
          }
          subTitle="No properties match your search and filter criteria."
        />
      </Box>
    )
  }

  return (
    <Box className="property-table-mobile-container">
      {data?.map((property, index) => (
        <TableMobileCard {...props} key={index} index={index} property={property} />
      ))}
    </Box>
  )
}
