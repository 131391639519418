import { Box, Grid } from '@mui/material'
import TakeoverTemplate from '@rentspree/component-2023.components.templates.takeover-template'
import { buildPath, query } from '@rentspree/path'
import { withFormik } from 'formik'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo, useRef } from 'react'

import {
  PROPERTY_OVERVIEW_PAGE,
  RENT_PAYMENT,
  RENT_PAYMENT_SELECT_PAYMENT_RECEIVER,
} from 'constants/route'
import * as ROUTE from 'constants/route'
import { useFeatureFlag, STATSIG } from 'hooks/use-statsig-feature-flag'
import { RENT_PAYMENT as RENT_PAYMENT_EVENTS } from 'tracker/const'
import { RENT_PAYMENT_FEATURE_FLAG_KEY } from 'utils/feature-flag/constants'
import history from 'utils/history'
import { FormikScrollToFirstError } from 'v3/components/formik-scroll-to-error'
import { useMediaQuery } from 'v3/hooks/use-media-query'

import { useListPropertiesQuery } from '../../apis/property'
import { useSearchRenterInfo } from '../../shared/hooks/use-search-renter-info'
import { STORAGE_KEYS } from '../constants'
import {
  generatePropertyOptions,
  generateRenterInfoOptions,
} from '../set-up-for-myself/steps/property-and-tenant/utils'
import { findDraftingPaymentByPropertyStorage } from '../utils'

import { PAGE_TITLE, SUB_HEADER, DRAFT_PAYMENT_FORM_SECTION_ID } from './constants'
import { LandlordForm } from './landlord-form'
import { PropertyForm } from './property-form'
import { QuotationsForm } from './quotations-form'
import { mapPropsToValues, validationSchema } from './schema'
import { TenantForm } from './tenant-form'

import './styles.scss'

export const AgentInitiateDraftRentalPaymentForm = props => {
  const {
    location,
    values,
    touched,
    errors,
    setFieldValue,
    isSubmitting,
    setFieldTouched,
    setValues,
    isValid,
    handleSubmit,
  } = props

  const propertyRef = useRef()
  const landlordRef = useRef()
  const tenantRef = useRef()
  const quotationsRef = useRef()
  const takeoverRef = useRef()
  const isMobile = useMediaQuery('(max-width: 1016px)')

  const queryString = query.parse(location.search, [location.search])

  useEffect(() => {
    if (history.location?.hash) {
      const scrollSettings = { block: isMobile ? 'center' : 'start', behavior: 'smooth' }
      const target = history.location.hash
      const scrollFn = ref => {
        if (ref.current) {
          ref.current.scrollIntoView(scrollSettings)
        }
      }

      switch (target) {
        case `#${DRAFT_PAYMENT_FORM_SECTION_ID.PROPERTY}`:
          scrollFn(propertyRef)
          break
        case `#${DRAFT_PAYMENT_FORM_SECTION_ID.LANDLORD}`:
          scrollFn(landlordRef)
          break
        case `#${DRAFT_PAYMENT_FORM_SECTION_ID.TENANT}`:
          scrollFn(tenantRef)
          break
        case `#${DRAFT_PAYMENT_FORM_SECTION_ID.QUOTATIONS}`: {
          const quotations = quotationsRef.current
          if (quotations) {
            if (quotations.children[0] && quotations.children[0].children[0]) {
              quotations.children[0].children[0].scrollIntoView(scrollSettings)
            }
          }
          break
        }
        default:
          break
      }
    }
  }, [
    propertyRef.current,
    landlordRef.current,
    tenantRef.current,
    quotationsRef.current,
    history?.location?.hash,
  ])

  const { data: tenantList, searchRenterInfo } = useSearchRenterInfo()

  const listPropertiesQuery = useListPropertiesQuery()

  const propertyOptions = useMemo(
    () => generatePropertyOptions(listPropertiesQuery.data?.data),
    [listPropertiesQuery.data],
  )

  const { isEnabled: isMonthToMonthEnabled } = useFeatureFlag({
    statsigFeatureFlagKey: RENT_PAYMENT_FEATURE_FLAG_KEY.MONTH_TO_MONTH,
    featureFlagProvider: STATSIG,
  })

  useEffect(() => {
    if (!queryString.property) {
      return
    }
    const property = propertyOptions.find(v => v.value === queryString.property)
    if (!property) {
      return
    }
    const draftedPayment = findDraftingPaymentByPropertyStorage(
      STORAGE_KEYS.AGENT_DRAFTING_PAYMENT,
      queryString.property,
    )
    if (draftedPayment) {
      setValues({ ...draftedPayment, selectedProperty: property }, true)
    } else {
      setValues({ ...values, selectedProperty: property }, true)
    }

    if (isValid && !queryString.edit) {
      history.push(
        buildPath(
          ROUTE.RENT_PAYMENT_REQUEST_AGENT_INITIATED_CONFIRMATION_PAGE,
          {},
          {
            source: queryString.source,
            property: queryString.property,
          },
        ),
        history.location.state,
      )
    }
  }, [propertyOptions])

  const tenantOptions = generateRenterInfoOptions(tenantList)

  useEffect(() => {
    // change prefill data after select new property
    const propertyId = values?.selectedProperty?.value
    if (!propertyId) {
      return
    }
    const draftedPayment = findDraftingPaymentByPropertyStorage(
      STORAGE_KEYS.AGENT_DRAFTING_PAYMENT,
      propertyId,
    )
    if (!isEmpty(draftedPayment)) {
      setValues(draftedPayment, true)
    } else {
      const properties = listPropertiesQuery?.data?.data || []
      const property = properties.find(ele => ele._id === propertyId)
      if (property) {
        setFieldValue('landlordInfo.firstName', property.landlordProfile?.firstName || '')
        setFieldValue('landlordInfo.lastName', property.landlordProfile?.lastName || '')
        setFieldValue('landlordInfo.email', property.landlordProfile?.email || '')
      }
    }
  }, [values?.selectedProperty?.value])

  useEffect(() => {
    if (values?.selectedTenant && tenantList?.length > 0) {
      setFieldValue(
        'renterInfo',
        tenantList.find(tenant => tenant.id === values.selectedTenant),
      )
    }
  }, [values?.selectedTenant, tenantList])

  useEffect(() => {
    if (searchRenterInfo) searchRenterInfo()
  }, [])

  const fromAgentIncentiveIngressPathname = history?.location?.state?.fromPathname
  const handleClickBack = () => {
    if (fromAgentIncentiveIngressPathname) {
      history.replace(
        buildPath(fromAgentIncentiveIngressPathname, { propertyId: queryString.property }),
      )
      return
    }

    history.push(
      buildPath(
        RENT_PAYMENT_SELECT_PAYMENT_RECEIVER,
        {},
        { source: queryString.source, property: queryString.property },
      ),
    )
  }

  const handleClose = () => {
    if (fromAgentIncentiveIngressPathname) {
      return history.replace(
        buildPath(fromAgentIncentiveIngressPathname, { propertyId: queryString.property }),
      )
    }

    if (
      queryString.source === RENT_PAYMENT_EVENTS.EVENT_PROPERTY.CLICK_FROM.PROPERTY_OVERVIEW &&
      queryString.property
    ) {
      return history.push(buildPath(PROPERTY_OVERVIEW_PAGE, { propertyId: queryString.property }))
    }
    return history.push(RENT_PAYMENT)
  }

  const propertyFormProps = {
    placeholder: 'Type or expand to select a property',
    options: propertyOptions,
    location,
    loading: listPropertiesQuery.isFetching,
    touched,
    setFieldTouched,
    errors,
    setFieldValue,
    'data-testid': 'property-select',
    values,
  }

  const tenantProps = {
    options: tenantOptions,
    fetchTenants: searchRenterInfo,
    ...props,
  }

  const quotationsProps = {
    isMonthToMonthEnabled,
    ...props,
  }

  return (
    <TakeoverTemplate
      title={PAGE_TITLE}
      subTitle={SUB_HEADER}
      closeButtonProps={{
        closeButtonText: 'Close',
        onClose: handleClose,
      }}
      navigationProps={{
        backButtonText: 'Back',
        backButtonProps: {
          onClick: handleClickBack,
          disabled: isSubmitting,
        },
        nextButtonText: 'Next',
        nextButtonProps: {
          onClick: handleSubmit,
          disabled: isSubmitting,
        },
      }}
    >
      <Grid container ref={takeoverRef}>
        <Grid item xs={12} md={6}>
          {[
            {
              form: <PropertyForm {...propertyFormProps} />,
              key: DRAFT_PAYMENT_FORM_SECTION_ID.PROPERTY,
              ref: propertyRef,
            },
            {
              form: <LandlordForm {...props} />,
              key: DRAFT_PAYMENT_FORM_SECTION_ID.LANDLORD,
              ref: landlordRef,
            },
            {
              form: <TenantForm {...tenantProps} />,
              key: DRAFT_PAYMENT_FORM_SECTION_ID.TENANT,
              ref: tenantRef,
            },
            {
              form: <QuotationsForm {...quotationsProps} />,
              key: DRAFT_PAYMENT_FORM_SECTION_ID.QUOTATIONS,
              ref: quotationsRef,
            },
          ].map(item => (
            <Box marginBottom="40px" key={item.key} id={item.key} ref={item.ref}>
              {item.form}
            </Box>
          ))}
        </Grid>
      </Grid>
      <FormikScrollToFirstError />
    </TakeoverTemplate>
  )
}

const Form = withFormik({
  validationSchema: props => validationSchema(props),
  mapPropsToValues: props => mapPropsToValues(props),
  displayName: 'AgentInitiateDraftRentalPaymentForm',
  handleSubmit: (formikDraftPaymentValues, { props }) => {
    const fulfilledOneTimePayment = formikDraftPaymentValues?.oneTimePayments?.filter(
      oneTimePayments => {
        const { amount, dueDate, category, customCategory } = oneTimePayments
        return !!amount || !!dueDate || !!category || !!customCategory
      },
    )

    const fulfilledRecurringPayment = formikDraftPaymentValues?.recurringPayments?.filter(
      recurringPayments => {
        const { amount, startDate, endDate, category, customCategory, isInitiallyAdded } =
          recurringPayments
        return (
          !!amount ||
          !!startDate ||
          !!endDate ||
          !!(category && !isInitiallyAdded) ||
          !!customCategory
        )
      },
    )

    props.onSubmit({
      ...formikDraftPaymentValues,
      recurringPayments: fulfilledRecurringPayment,
      oneTimePayments: fulfilledOneTimePayment,
    })
  },
  validateOnChange: true,
  validateOnBlur: true,
  enableReinitialize: false,
})(AgentInitiateDraftRentalPaymentForm)

export default Form
