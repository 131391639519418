/* eslint-disable camelcase */
import EmptyState from '@rentspree/component-2023.components.templates.empty-state'
import React, { useState } from 'react'
import tracker from 'tracker'
import { EVENT_ACTION_DROPDOWN, EVENT_ACTION_DROPDOWN_CLICK_FROM } from 'tracker/const'
import {
  FEATURE,
  FEATURE_STATUS,
  SOURCE,
} from 'containers/property/toggle-property-feature/constants'
import { openTogglePropertyFeatureModal } from 'containers/property/toggle-property-feature/actions'
import { useTenantScreeningStepUrl } from 'hooks/use-tenant-screening-step-url'
import { useDispatch } from 'react-redux'
import Button from '@rentspree/component-2023.components.atoms.button'
import PdfOverlay from '../../components/pdf-overlay/pdf-overlay'
import { SAMPLE_REPORT_PDF_URL } from '../../env'

const { SCREEN_FROM_CURRENT_PROPERTY } = EVENT_ACTION_DROPDOWN
const { APPLICATION_LIST_LANDING } = EVENT_ACTION_DROPDOWN_CLICK_FROM

export const NewScreenCTA = ({ handleClickScreenNewTenant, color = 'primary' }) => (
  <Button
    onClick={() => handleClickScreenNewTenant('title cta')}
    size="small"
    variant="contained"
    color={color}
    data-testid="new-screen-tenant-cta-click"
    id="new-screen-tenant-cta-click"
  >
    Screen tenant
  </Button>
)

export const PropertyApplicationZeroState = ({ history, disabledTenantScreening, propertyId }) => {
  const [showPdf, setShowPdf] = useState(false)
  const dispatch = useDispatch()
  const { getBuiltPathPermalinkActionId } = useTenantScreeningStepUrl()

  const handleClickScreenNewTenant = location => {
    if (disabledTenantScreening) {
      dispatch(
        openTogglePropertyFeatureModal({
          feature: FEATURE.TENANT_SCREENING,
          type: FEATURE_STATUS.ENABLE,
          source: SOURCE.SCREEN_EMPTY,
        }),
      )
    } else {
      const nextPage = getBuiltPathPermalinkActionId({ propertyId })
      tracker.trackButtonClick({
        location,
        click_text: 'Screen new tenant',
        click_url: nextPage,
      })

      tracker.trackEvent(SCREEN_FROM_CURRENT_PROPERTY, {
        click_from: APPLICATION_LIST_LANDING,
      })
      history.push(nextPage)
    }
  }

  const handleSampleReportClick = () => {
    tracker.trackButtonClick({
      location: 'empty_state',
      click_text: 'View sample report',
    })
    setShowPdf(true)
  }

  return (
    <EmptyState
      image={
        <img
          src="https://cdn.rentspree.com/static-files/zero-state/property-application-empty-state.svg"
          alt="empty state"
        />
      }
      title="Screen with confidence"
      subTitle="Quickly identify qualified renters with credit, rental history, and background checks, plus a comprehensive income report."
      cta={
        <NewScreenCTA
          handleClickScreenNewTenant={() => handleClickScreenNewTenant('empty state')}
        />
      }
      secondaryCTA={
        <>
          <Button
            onClick={handleSampleReportClick}
            variant="text"
            color="secondary"
            size="small"
            className="underline"
          >
            View sample report
          </Button>
          <PdfOverlay
            title="Sample reports"
            pdfFile={SAMPLE_REPORT_PDF_URL}
            show={showPdf}
            onClose={() => setShowPdf(false)}
          />
        </>
      }
    />
  )
}
