import { Drawer } from '@mui/material'
import React, { useContext } from 'react'

import { COMPONENT_2023_BREAKPOINT_MOBILE } from 'containers/constants'
import useMobile from 'hooks/use-mobile'
import { PageIndexesContext } from 'v3/containers/overhaul-rent-payment/context'

const MobileBottomDrawer = ({ ...attrs }) => {
  const joinedAttrs = {
    ...attrs,
    PaperProps: {
      sx: {
        height: 'auto',
        minHeight: '450px',
        padding: '20px 15px',
        // footer is just over 80px tall
        paddingBottom: '105px',
      },
    },
  }

  return <Drawer {...joinedAttrs} />
}

const BrowserRightDrawer = ({ ...attrs }) => {
  const joinedAttrs = {
    ...attrs,
    PaperProps: {
      sx: {
        width: 'auto',
        minWidth: '500px',
        padding: '20px 15px',
        // footer is just over 80px tall
        paddingBottom: '105px',
      },
    },
  }

  return <Drawer {...joinedAttrs} />
}

export const PaymentsDrawer = ({ children }) => {
  /*
   * This component handles wrapping content in a drawer, either a bottom drawer for mobile or a right side drawer for desktop.
   */
  const isMobile = useMobile(COMPONENT_2023_BREAKPOINT_MOBILE)
  const [pageIndexes, debouncedSetPageIndexes] = useContext(PageIndexesContext)

  const setDrawerOpen = nextDrawerOpen => debouncedSetPageIndexes({ drawerOpen: nextDrawerOpen })

  const chooseDrawerType = () => {
    if (isMobile) {
      return (
        <MobileBottomDrawer
          anchor="bottom"
          open={pageIndexes.drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          {children}
        </MobileBottomDrawer>
      )
    }

    return (
      <BrowserRightDrawer
        anchor="right"
        open={pageIndexes.drawerOpen}
        onClose={() => setDrawerOpen(false)}
        style={{ zIndex: 3100 }}
      >
        {children}
      </BrowserRightDrawer>
    )
  }

  return <>{chooseDrawerType()}</>
}
