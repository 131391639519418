import get from 'lodash/get'
import { createSelector } from 'reselect'
import { LEASE_AGREEMENT_TERM } from 'constants/terms'

const getLeaseAgreementDetail = state => get(state, 'leaseAgreementDetail')
const getProfile = state => get(state, 'user')

export const makeSelectIsAcceptTermsLeaseAgreement = () =>
  createSelector(getProfile, state => get(state, `profile.terms.${LEASE_AGREEMENT_TERM}`, false))

export const selectLeaseAgreementDetail = createSelector(getLeaseAgreementDetail, state => state)

export const selectLeaseAgreementDetailData = () =>
  createSelector(getLeaseAgreementDetail, state => state)

export const selectIsLoading = () =>
  createSelector(selectLeaseAgreementDetail, state => get(state, 'isLoading'))

export const selectIsResending = () =>
  createSelector(selectLeaseAgreementDetail, state => ({
    isLoading: get(state, 'isResending', false),
    isCloseModalResend: get(state, 'isCloseModalResend', false),
  }))
export const selectIsAccepting = () =>
  createSelector(selectLeaseAgreementDetail, state => get(state, 'isAccepting'))

export const selectIsError = () =>
  createSelector(selectLeaseAgreementDetail, state => get(state, 'isError'))

export const selectTitle = () =>
  createSelector(selectLeaseAgreementDetail, state => get(state, 'title', ''))

// TODO: TECH-17060 [DUPLICATED_ALERT] moved to `lease-agreement-list` selector already
export const makeSelectorVoidStatus = () =>
  createSelector(selectLeaseAgreementDetail, state => ({
    isShowVoidModal: get(state, 'isShowVoidModal', false),
    isLoading: get(state, 'isLoadVoid', false),
    isSuccess: get(state, 'isVoidSuccess', false),
  }))

// TODO: TECH-17060 [DUPLICATED_ALERT] moved to `lease-agreement-list` selector already
export const makeSelectorDeleteStatus = () =>
  createSelector(selectLeaseAgreementDetail, state => ({
    isLoading: get(state, 'isLoadDelete', false),
    isSuccess: get(state, 'isDeleteSuccess', false),
  }))

// TODO: TECH-17060 [DUPLICATED_ALERT] moved to `lease-agreement-list` selector already
export const selectIsErrorVoid = () =>
  createSelector(selectLeaseAgreementDetail, state => get(state, 'isErrorVoid', false))

export const makeSelectError = () =>
  createSelector(selectLeaseAgreementDetail, state => get(state, 'error', null))

export const makeSelectIsConsentSuccess = () =>
  createSelector(selectLeaseAgreementDetail, state => get(state, 'isConsentSuccess', false))
