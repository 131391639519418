import Box from '@mui/material/Box'
import Button from '@rentspree/component-2023.components.atoms.button'
import EmptyState from '@rentspree/component-2023.components.templates.empty-state'
import Level1Template from '@rentspree/component-2023.components.templates.level-1-template'
import React from 'react'

import tracker from 'tracker'
import { useGetMyUserInvitation } from 'v3/hooks/use-get-my-invitations'

export const EMPTY_STATE_TEXTS = {
  DEFAULT_AGENT_NAME: 'Your agent',
  TITLE: 'hasn’t shared reports yet',
  DESCRIPTION: 'While you wait, check out a sample report.',
}

export const CTAS = {
  LEFT: {
    TEXT: 'View sample report',
    URL: 'https://support.rentspree.com/en/a-completed-rentspree-application',
  },
}

const ZERO_STATE_SCREENING_IMAGE_URL =
  'https://cdn.rentspree.com/static-files/zero-state/screening-page-empty-state-invited-ll.svg'

export const OnboardingLandlordTSEmptyState = ({ hideTitle = false }) => {
  const { data: userInvitation, isFetched: isUserInvitationFetched } = useGetMyUserInvitation()

  let agentName = EMPTY_STATE_TEXTS.DEFAULT_AGENT_NAME
  if (isUserInvitationFetched) {
    agentName =
      [userInvitation?.result?.inviter?.firstName, userInvitation?.result?.inviter?.lastName]
        .filter(Boolean)
        .join(' ') || EMPTY_STATE_TEXTS.DEFAULT_AGENT_NAME
  }

  const openNewTabToViewSampleReport = () => {
    tracker.trackButtonClick({
      click_text: 'View sample report',
      click_url: CTAS.LEFT.URL,
      location: 'empty_state',
    })
    window.open(CTAS.LEFT.URL, '_blank')
  }

  return (
    <>
      {!hideTitle && (
        <Box>
          <Level1Template title="Screening" />
        </Box>
      )}
      <Box>
        <EmptyState
          title={`${agentName} ${EMPTY_STATE_TEXTS.TITLE}`}
          subTitle={EMPTY_STATE_TEXTS.DESCRIPTION}
          image={
            <img
              src={ZERO_STATE_SCREENING_IMAGE_URL}
              alt="empty-state-screening-invited-landlord"
            />
          }
          secondaryCTA={
            <Button
              onClick={openNewTabToViewSampleReport}
              variant="text"
              color="secondary"
              className="underline"
            >
              View sample report
            </Button>
          }
        />
      </Box>
    </>
  )
}
