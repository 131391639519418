import { debounce } from '@mui/material'
import dayjs from 'dayjs'
import timezonePlugin from 'dayjs/plugin/timezone'
import utcPlugin from 'dayjs/plugin/utc'

import {
  ONE_TIME_FEES,
  PAYMENT_TYPES,
  PaymentCategories,
} from 'v3/containers/overhaul-rent-payment/constants'
import { pageIndexesTemplate } from 'v3/containers/overhaul-rent-payment/context'
import { MONTH_TO_MONTH_LABEL } from 'v3/containers/rent-payment/constants'
import { generateQuotationByType } from 'v3/containers/rent-payment/setup-page/set-up-for-myself/steps/payment-details/utils'

dayjs.extend(timezonePlugin)
dayjs.extend(utcPlugin)

export const utcStartOfDay = date => {
  /*
   * A quick reusable method to make sure all dates use UTC
   * WITHOUT keeping local time -- .utc(true) would keep local time offset
   */
  return dayjs.utc(date).startOf('day')
}

export const isObject = obj => typeof obj === 'object' && !Array.isArray(obj)
export const isEmptyOrNotObject = obj => {
  return (
    obj === null ||
    obj === undefined ||
    (isObject(obj) && Object.getOwnPropertyNames(obj).length === 0)
  )
}

export const smartNextIndexesCalc = (
  // nextPageIndexes
  { pageL1Index, pageL2Index, drawerOpen },
  prevPageIndexes,
) => {
  /*
   * start from a default state, and add desired states top-down, so sub-page states are fresh if a parent-page progresses
   *  - i.e. if the top L1 page goes from index 1 to 2, and the L2 sub-page was at index 3, set pageL2Index to 0 so L1 page 2 doesn't start with an offset.
   *  - drawer state is considered "the sub-est sub-page" conceptually, for this method's logic
   *
   *   @param pageL1Index: the top-level (Level 1) page's index, optional if no change is desired
   *   @param pageL2Index: the second level (Level 2) page's index, optional if no change is desired
   *   @param drawerOpen: the Drawer's open/closed state flag, optional if no change is desired
   *   @param curPageIndexes: the complete & current object of all page indexes; required
   *   @return: the desired index states to change to
   */

  // if the caller passes nothing in, catch & set values to the blank-slate template
  const currentPageIndexes = prevPageIndexes || pageIndexesTemplate

  const nextPageIndexes = {
    pageL1Index: 0,
    pageL2Index: 0,
    drawerOpen: false,
  }

  // separate for debugging + line length
  const loopEntries = Array.from(
    // we want this "object" to be ordered, leveraging a quick Map
    new Map([
      ['pageL1Index', pageL1Index],
      ['pageL2Index', pageL2Index],
      ['drawerOpen', drawerOpen],
    ]).entries(),
  )
  // some unhelpful linting here; refactoring this loop would be a good idea later
  // eslint-disable-next-line no-restricted-syntax, no-unused-vars
  for (const [key, desiredNextVal] of loopEntries) {
    if (desiredNextVal === undefined) {
      nextPageIndexes[key] = currentPageIndexes[key]
    } else {
      nextPageIndexes[key] = desiredNextVal
      if (desiredNextVal !== currentPageIndexes[key]) {
        // everything "lower level" than this should be reset, so that page level can start fresh; break out of the loop now
        break
      }
    }
  }

  return nextPageIndexes
}

export const createPropertyString = property => {
  const { street, unitNumber, state, city, zip } = property
  const propertyValues = [street, unitNumber, state, Array.join([city, zip], ' ')].filter(
    val => typeof val === 'string' && val !== '',
  )
  return Array.join(propertyValues, ', ')
}

export const tenantToString = (tenant = {}) => {
  const { firstName, lastName } = tenant
  return `${firstName} ${lastName}`.trim()
}

export const isCategoryOneTimeOnly = category => {
  return ONE_TIME_FEES.includes(category)
}

export const getPaymentTemplate = category => {
  const type = isCategoryOneTimeOnly(category) ? PAYMENT_TYPES.ONE_TIME : PAYMENT_TYPES.RECURRING

  const template = {
    ...generateQuotationByType({
      category,
      customCategory: category,
    }),
    type,
    description: null,
  }

  return template
}

export const findPaymentCategory = (payments, desiredCategory) => {
  /*
   * quickly checks for the Rent category payment in a given array of payments
   *   TODO: consider an object/map
   */
  if (Object.values(PaymentCategories).includes(desiredCategory)) {
    //   - outdated guidance? https://stackoverflow.com/a/72072751 (and vars are, in fact, definitely used)
    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
    const paymentIndex = payments.findIndex(payment => {
      return payment?.category === desiredCategory
    })
    // light shallow-copy if found, just in case
    const payment = {
      ...(paymentIndex > -1 ? payments[paymentIndex] : getPaymentTemplate(desiredCategory)),
    }
    return [paymentIndex, payment]
  }
  return [-1, undefined]
}

export const capitalize = str => {
  if (!str) return ''
  str.trim()
  if (str.length === 0) return ''
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const updatePayment = debounce(({ nextPayment, payments, setPayments, paymentIndex }) => {
  /*
   * Reusable util to set a payment's value within the overall payments array.
   *
   *   TODO: consider an object/map
   *   TODO: use 'findPaymentCategory(payment.category)' instead of the paymentIndex parameter
   *
   * @nextPayment: the next value of the payment object to set in the payments array, new or replacing an existing (old) iteration
   * @payments: the existing array of payments; this might include the existing/old version of the 'nextPayment' object
   * @setPayments: the method to set the payments array, likely a setState() handler
   */

  /*
   * Allow the user to specify the index and leave it to them to overwrite
   * category with another *only when intended*,  as that's a needed editing capability
   */
  const desiredIndex = Number.isInteger(paymentIndex)
    ? paymentIndex
    : findPaymentCategory(payments, nextPayment?.category)[0]

  const nextPayments = [...payments]
  if (desiredIndex < 0) {
    nextPayments.push(nextPayment)
  } else {
    nextPayments[desiredIndex] = nextPayment
  }

  // only objects should be allowed, especially if debounce logic accidentally sends in 'undefined' via race condition, etc.
  setPayments(nextPayments.filter(val => isObject(val)))
}, 400)

export const generateAPIQuotation = payment => {
  const endDateDisabled = ['oneTime', undefined].includes(payment?.type)
  const dueDate = payment?.dueDate || payment?.startDate

  const inputs = {
    category: payment?.category,
    customCategory: capitalize(payment?.customCategory),
    monthlyDescription: payment?.description,
    oneTimeDescription: payment?.description,
    amount: payment?.amount,
    paymentType: payment?.type,
    firstPayment: payment?.startDate,
    lastPayment: !endDateDisabled && payment?.endDate ? payment?.endDate : MONTH_TO_MONTH_LABEL,
    dueOn: dueDate ? utcStartOfDay(dueDate).date() : null,
    dueDate,
    selectedFeeCollection: {},
  }

  const quotation = generateQuotationByType(inputs)

  if (payment?.type === 'recurring') {
    quotation.repeat = 'monthly'
  }

  return quotation
}
