import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { AppBar, Box, Toolbar } from '@mui/material'
import IconButton from '@rentspree/component-2023.components.atoms.icon-button'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import { useScreenSize } from '../../../hooks/use-screen-size'
import './pdf-overlay-header.scss'

const PdfOverlayHeader = ({ title, onClose, children }) => {
  const { isDesktop } = useScreenSize()

  return (
    <AppBar className="RSPPdfOverlayHeader" position="sticky">
      <Toolbar>
        {isDesktop && <Typography variant="title">{title}</Typography>}
        <Box className="RSPPdfOverlayControlGroup">{children}</Box>
        <IconButton color="white" aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default PdfOverlayHeader
