import Box from '@mui/material/Box'
import Button from '@rentspree/component-2023.components.atoms.button'
import EmptyState from '@rentspree/component-2023.components.templates.empty-state'
import Level1Template from '@rentspree/component-2023.components.templates.level-1-template'
import React, { useState } from 'react'

import useMobile from 'hooks/use-mobile'
import { useTenantScreeningStepUrl } from 'hooks/use-tenant-screening-step-url'
import tracker from 'tracker'
import { MOBILE_THRESHOLD } from 'utils/constants'
import PdfOverlay from '../../../components/pdf-overlay/pdf-overlay'
import { SAMPLE_REPORT_PDF_URL } from '../../../env'

const ZERO_STATE_SCREENING_IMAGE_URL =
  'https://cdn.rentspree.com/static-files/zero-state/screening-page-empty-state.svg'

export const TenantScreeningLanding = ({ onCTAClick }) => {
  const [showPdf, setShowPdf] = useState(false)
  const isMobile = useMobile(MOBILE_THRESHOLD)
  const { getFirstScreeningRequestStepUrl } = useTenantScreeningStepUrl()

  const handleCTAClick = () => {
    const url = getFirstScreeningRequestStepUrl()
    tracker.trackButtonClick({
      click_text: 'Screen tenant',
      click_url: url,
      location: 'empty_state',
    })
    onCTAClick()
  }

  const handleSampleReportClick = () => {
    tracker.trackButtonClick({
      click_text: 'View sample report',
      location: 'empty_state',
    })
    setShowPdf(true)
  }

  return (
    <>
      <Box>
        <Level1Template title="Screening" isMobile={isMobile} />
      </Box>
      <Box sx={{ height: '100%' }}>
        <EmptyState
          title="Screen with confidence"
          subTitle="Quickly identify qualified renters with credit, rental history, and background checks, plus a comprehensive income report."
          image={<img src={ZERO_STATE_SCREENING_IMAGE_URL} alt="empty-state-screening" />}
          cta={
            <Button onClick={handleCTAClick} variant="contained" color="primary" size="small">
              Screen tenant
            </Button>
          }
          secondaryCTA={
            <>
              <Button
                onClick={handleSampleReportClick}
                variant="text"
                color="secondary"
                size="small"
                className="underline"
              >
                View sample report
              </Button>
              <PdfOverlay
                show={showPdf}
                title="Sample reports"
                pdfFile={SAMPLE_REPORT_PDF_URL}
                onClose={() => setShowPdf(false)}
              />
            </>
          }
        />
      </Box>
    </>
  )
}

export const TenantScreeningLandingComponent = ({ onCTAClick }) => (
  <TenantScreeningLanding onCTAClick={onCTAClick} />
)
