import get from 'lodash/get'
import React from 'react'
import styled from 'styled-components'

import { COLOR } from 'styles/settings'
import NullDataReport from 'components/reports/screening-reports/share/null-report'
import { HeaderDoc } from 'components/reports/screening-reports/share/doc-components'
import { 
  ReportWrapper, 
  Divider, 
  ScreeningSectionHeader, 
  ScreeningSubSectionHeader, 
  ScreeningSubSectionContainer,
  Label,
  Value, 
  ScreeningInfoContentWrapper
} from 'components/reports/screening-reports/share/responsive-doc-style'
import {
  ApplicantOverview,
  ReportsBody,
  ScreeningReportHeader,
  ScreeningApplicantSection,
  ScreeningReportFooter
} from 'components/reports/screening-reports/share/responsive-doc-components'
import HeaderPrint from 'containers/reports/header'
import { NoReport } from 'legacy/components/reports/share/doc-components'
import CompanyInfoFooter from 'legacy/components/reports-v2/share/company-info-footer'
import { isEmptyArray, generateHeaderName } from 'legacy/components/reports-v2/share/function'
import { convert } from 'helpers/convert'
import { REPORT_TYPES } from 'containers/reports/constants'
import { PendingReport } from "../share/pending-report"

import { evictionMapper, evictionMapperV2 } from './eviction-report/mapper'
import EvictionRecord, { EvictionCase } from './eviction-report/eviction-record'
import { Attention } from './criminal-report/doc-components/attention'
import { REPORT_STATUSES } from '../share/constants'

const EvictionSearchTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${COLOR.grayLv2};
  width: 310px;
`
const SearchResultContainer = styled.div`
  border: solid 2px ${COLOR.lightTextGrey};
  border-bottom: solid 1px ${COLOR.lightTextGrey};
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
  
`
const HeaderInfoLine = styled.div`
  padding-top: 0px;
  min-height: 0px;
  width: 50%;
  display: flex;
  flex-direction: row;
  gap: 10px;
`
export const EvictionHousingRecords = ({evictionReport, evictionCases, fullName}) => (
  <div>
    <ScreeningSectionHeader>Housing Records</ScreeningSectionHeader>
    {!isEmptyArray(evictionCases) ? (
      <div>
        <ScreeningSubSectionHeader color={COLOR.tuDarkOrange}>Needs Review</ScreeningSubSectionHeader>
        <ScreeningSubSectionContainer color={COLOR.tuOrange}>
          <EvictionSearchHeader completedDate={evictionReport.completedOn}/>
          {evictionCases.map((eviction, index) => (
            <EvictionCase
              fullName={fullName}
              eviction={eviction}
              key={index.toString()}
              footnote={get(evictionReport, 'housing.courtReportedAmountNote')}
            />
          ))}
        </ScreeningSubSectionContainer>
      </div>
    ) : (
      <div>
        <ScreeningSubSectionHeader color={COLOR.tuGreen}>No Reportable Records</ScreeningSubSectionHeader>
        <ScreeningSubSectionContainer color={COLOR.tuGreen}>
          <EvictionSearchHeaderContents completedDate={evictionReport.completedOn}/>
        </ScreeningSubSectionContainer>
      </div>
    )}
  </div>
)

export const EvictionSearchHeader = ({completedDate}) => (
  <SearchResultContainer>
    <EvictionSearchHeaderContents completedDate={completedDate}/>
  </SearchResultContainer>
)

export const EvictionSearchHeaderContents = ({completedDate}) => (
  <ScreeningInfoContentWrapper>
    <EvictionSearchTitle>Housing Records Search</EvictionSearchTitle>
    <HeaderInfoLine>
      <Label width={120}>Completion Date</Label>
      <Value isBold={false}>{convert(completedDate, 'dateOfLocal', 'MM/DD/YYYY')}</Value>
    </HeaderInfoLine>
  </ScreeningInfoContentWrapper>
)

export default function EvictionReportComponent({
  evictionRecords,
  generatedOn,
  expiresOn,
  renter,
  landlord,
  applicant,
  showHeaderComponent,
  customBorder,
  customMargin,
  applicationCreationDate,
  rentalDetail,
  userType,
  match
}) {
  const reportName = 'Eviction Related Proceedings'
  const isNewJsonFormat = !!get(evictionRecords, 'status')
  const reportInProcess = !evictionRecords || (evictionRecords?.status === REPORT_STATUSES.IN_PROCESS)
  const evictions = isNewJsonFormat ? evictionMapperV2(evictionRecords?.housing?.activities) : evictionMapper(evictionRecords?.Records)

  if (reportInProcess)
  {
    return <PendingReport reportType={REPORT_TYPES.EVICTION} userType={userType} match={match}/>
  }

  if (!evictions) {
    return <NullDataReport generatedOn={generatedOn} headerDoc={reportName} />
  }

  const fullName = isNewJsonFormat ? renter.fullName : generateHeaderName(evictionRecords?.RequestedConsumer)
  const email = get(renter, 'email')
  const phoneNumber = get(renter, 'homePhoneNumber')
  const formattedPhoneNumber = convert(phoneNumber, 'phoneNumber')
  const ssnMessage = applicant.sSNMessage
  const headerDocProps = {
    ssnMessage,
    text: reportName,
    transUnion: { expiresOn, generatedOn },
    renter,
    landlord,
  }
  return (
    <ReportWrapper border={customBorder} margin={customMargin}>
      {showHeaderComponent && <HeaderPrint />}
      { isNewJsonFormat ?
        <ReportsBody>
          <ScreeningReportHeader/>
          <Divider/>
          <ScreeningApplicantSection
            applicant={renter} 
            reportNumber={evictionRecords.reportNumber} 
            completedDate={evictionRecords.completedOn}
            orderedOn={applicationCreationDate}
            packageName="Housing"
            property={rentalDetail.property}
          />
          <EvictionHousingRecords
            evictionReport={evictionRecords}
            evictionCases={evictions}
            fullName={renter.fullName}
          />
          <ScreeningReportFooter/>
        </ReportsBody>
        :
        <ReportsBody>
          <HeaderDoc {...headerDocProps} />
          <ApplicantOverview
            name={fullName}
            email={email}
            phone={formattedPhoneNumber}
            withIncorrectInfo
          />
          {!isEmptyArray(evictions) ? (
            evictions.map((eviction, index) => (
              <EvictionRecord
                eviction={eviction}
                index={index + 1}
                state={get(eviction, 'State')}
                key={index.toString()}
              />
            ))
          ) : (
            <NoReport text="No records to return" />
          )}
          <CompanyInfoFooter isTUReport />
          <Attention />
        </ReportsBody>
      }
    </ReportWrapper>
  )
}
