export const LEASE_AGREEMENT_DETAIL_CALL = 'LEASE_AGREEMENT_DETAIL_CALL'
export const LEASE_AGREEMENT_DETAIL_REQUEST = 'LEASE_AGREEMENT_DETAIL_REQUEST'
export const LEASE_AGREEMENT_DETAIL_SUCCESS = 'LEASE_AGREEMENT_DETAIL_SUCCESS'
export const LEASE_AGREEMENT_DETAIL_FAILURE = 'LEASE_AGREEMENT_DETAIL_FAILURE'
export const CLEAR_LEASE_AGREEMENT_DETAIL = 'CLEAR_LEASE_AGREEMENT_DETAIL'

// TODO: TECH-17060 [DUPLICATED_ALERT] moved to `lease-agreement-list` constants already
export const OPEN_VOID_MODAL = 'OPEN_VOID_MODAL'
export const CLOSE_VOID_MODAL = 'CLOSE_VOID_MODAL'

// TODO: TECH-17060 [DUPLICATED_ALERT] moved to `lease-agreement-list` constants already
export const VOID_ENVELOPE_CALL = 'VOID_ENVELOPE_CALL'
export const VOID_ENVELOPE_REQUEST = 'VOID_ENVELOPE_REQUEST'
export const VOID_ENVELOPE_SUCCESS = 'VOID_ENVELOPE_SUCCESS'
export const VOID_ENVELOPE_FAILED = 'VOID_ENVELOPE_FAILED'

// TODO: TECH-17060 [DUPLICATED_ALERT] moved to `lease-agreement-list` constants already
export const DELETE_ENVELOPE_CALL = 'DELETE_ENVELOPE_CALL'
export const DELETE_ENVELOPE_REQUEST = 'DELETE_ENVELOPE_REQUEST'
export const DELETE_ENVELOPE_SUCCESS = 'DELETE_ENVELOPE_SUCCESS'
export const DELETE_ENVELOPE_FAILED = 'DELETE_ENVELOPE_FAILED'

export const UPDATE_LEASE_AGREEMENT_CONSENT_CALL = 'UPDATE_LEASE_AGREEMENT_CONSENT_CALL'
export const UPDATE_LEASE_AGREEMENT_CONSENT_REQUEST = 'UPDATE_LEASE_AGREEMENT_CONSENT_REQUEST'
export const UPDATE_LEASE_AGREEMENT_CONSENT_SUCCESS = 'UPDATE_LEASE_AGREEMENT_CONSENT_SUCCESS'
export const UPDATE_LEASE_AGREEMENT_CONSENT_FAILED = 'UPDATE_LEASE_AGREEMENT_CONSENT_FAILED'

// TODO: TECH-17060 [DUPLICATED_ALERT] moved to `lease-agreement-list` constants already
export const CLEAR_LEASE_ERROR = 'CLEAR_LEASE_ERROR'

export const RESEND_ENVELOPE_CALL = 'RESEND_ENVELOPE_CALL'
export const RESEND_ENVELOPE_REQUEST = 'RESEND_ENVELOPE_REQUEST'
export const RESEND_ENVELOPE_SUCCESS = 'RESEND_ENVELOPE_SUCCESS'
export const RESEND_ENVELOPE_FAILED = 'RESEND_ENVELOPE_FAILED'

export const BASE_API_LEASE_AGREEMENT = '/api/v2/properties/:propertyId/lease-agreements'
export const API_GET_LEASE_AGREEMENT_BY_ID = `${BASE_API_LEASE_AGREEMENT}/:leaseAgreementId`

export const HEAD_LABEL = {
  LEASE_DETAILS: 'Rental Information',
  ENVELOPES: 'Lease Agreement',
}

export const LEASE_DETAILS_LABEL = {
  MOVE_IN_DATE: 'Lease Start:',
  LEASE_EXPIRES: 'Lease End:',
  RENT_AMOUNT: 'Rent Amount:',
  SECURITY_DEPOSIT: 'Security Deposit:',
}

// TODO: TECH-17060 [DUPLICATED_ALERT] moved to `lease-agreement-list` constants already
export const CLEAR_VOID_STATUS = 'CLEAR_VOID_STATUS'
export const RESEND_WORD = {
  TITLE: 'Resend signature request',
  DESC: (fullName, email) =>
    `Send another signature request email to <b>${fullName}</b> via <b>${email}</b>`,
  CONFIRM: 'Send',
}
export const CONSENT_WORD = {
  TITLE: 'E-Sign and E-Delivery Service Agreement',
  TERMS: [
    {
      text: `RentSpree provides you with the ability to enter into transactions electronically with your applicants and tenants and to obtain electronic signatures and deliver documents electronically (the “E-Sign Service”).  RentSpree offers this service to you subject to its Disclaimer of Warranties and Limitation of Liability above.  Moreover, in using our E-Sign Service, you are responsible for authenticating and verifying the identity of the parties using that service, and RentSpree is not responsible or liable for verifying or authenticating any information entered into our platform.  You must have a documented identity verification process, and we may from time to time request that you provide us a copy of that process.  In addition, you must retain for a period of seven (7) years the document or source you relied on to verify the identity of any applicant or tenant who used the E-Sign Service.  You are solely responsible for the documents you upload or deliver through the E-Sign Service, for providing paper copies of documents to your applicants and tenants when requested, and for determining whether it is appropriate to enter into electronic transactions with your applicants or tenants.  From time to time, we may receive requests to opt-out of electronic transactions or delivery of electronic documents from your applicants or tenants and we will forward that information to you.  Other than forwarding opt-out requests to you, we have no responsibility to manage opt-outs to our E-Sign Service, and you are solely responsible for complying with any opt-out requests from your applicants and/or tenants.`,
    },
  ],
}

export const CREATE_CUSTOM_TEMPLATE = 'CREATE_CUSTOM_TEMPLATE'
export const CREATE_CUSTOM_TEMPLATE_TOAST_SUCCESS = 'New template created.'
// TODO: TECH-17060 [DUPLICATED_ALERT] moved to `lease-agreement-list` constants already
export const DELETE_ENVELOPE = 'Document deleted'
export const CREATE_CUSTOM_TEMPLATE_TOAST_FAILED = 'Error occurred. Unable to save template.'
