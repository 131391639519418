import React, { useEffect, useRef } from 'react'
import { Box, DialogContent } from '@mui/material'
import { Page } from 'react-pdf/dist/esm/entry.webpack5'
import './pdf-overlay-sidebar.scss'

const PdfOverlaySidebarPdf = ({ totalPage, currentPageIndex, onClick }) => {
  const pageRefs = useRef(Array(totalPage))

  useEffect(() => {
    if (pageRefs.current[currentPageIndex]) {
      pageRefs.current[currentPageIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [currentPageIndex])

  return (
    <DialogContent className="RSPPdfOverlaySidebar">
      <Box className="RSPPdfOverlaySidebar-PageContainer">
        {[...Array(totalPage).keys()].map(i => (
          <Page
            className={
              currentPageIndex === i
                ? 'RSPPdfOverlaySidebar-ActivePage'
                : 'RSPPdfOverlaySidebar-Page'
            }
            renderAnnotationLayer={false}
            renderTextLayer={false}
            key={i}
            inputRef={el => {
              pageRefs.current[i] = el
            }}
            pageNumber={i + 1}
            width={120}
            onClick={() => onClick(i)}
          />
        ))}
      </Box>
    </DialogContent>
  )
}

export default PdfOverlaySidebarPdf
