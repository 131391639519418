import { Box, debounce } from '@mui/material'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import React, { useContext, useEffect } from 'react'

import { OVERHAUL_RENT_PAYMENT } from 'tracker/const'
import { pageOptions, recipientFields } from 'v3/containers/overhaul-rent-payment/constants'
import { PaymentInfoContext } from 'v3/containers/overhaul-rent-payment/context'
import { PropertySelectAndForm } from 'v3/containers/overhaul-rent-payment/pages/property-and-tenant/property-select-and-form'
import { TenantSelectAndForm } from 'v3/containers/overhaul-rent-payment/pages/property-and-tenant/tenant-select-and-form'
import { isEmptyOrNotObject, isObject } from 'v3/containers/overhaul-rent-payment/pages/utils'
import { PROP_AND_TENANT } from 'v3/containers/overhaul-rent-payment/text-constants'
import { trackClick } from 'v3/utils/tracker-utils'

import { stepperFormGroups } from '../../components/shared-style'

export const tenantField = 'tenant'
export const propertyField = 'property'
const isPropertyDataValid = pageData => {
  const propertyData = pageData?.[propertyField]
  const isPropertyPopulated = isObject(propertyData) && !isEmptyOrNotObject(propertyData)

  // TODO: leaving room for populated data validation
  return isPropertyPopulated
}
const isTenantDataValid = pageData => {
  const tenantData = pageData?.[tenantField]
  const isTenantPopulated = isObject(tenantData) && !isEmptyOrNotObject(tenantData)

  // TODO: leaving room for populated data validation
  return isTenantPopulated
}
const isPageDataAllValid = pageData => {
  return isPropertyDataValid(pageData) && isTenantDataValid(pageData)
}

export const handlePropertyAndTenantProgression = (
  { increment = false, decrement = false },
  curPageIndexes,
  setPageIndexes,
  pageField,
  curPaymentInfo,
) => {
  const indexChanges = {
    pageL1Index: curPageIndexes.pageL1Index,
    drawerOpen: curPageIndexes.drawerOpen,
  }
  // if all the data is entered and is valid, then return true
  let shouldSaveData = false
  // shortcut invalid scenarios
  if (increment === decrement) {
    /*
     * logging an error, but this can still be consumer facing, so staying vague
     * need to find direct DD log avenue for debug details if possible
     */
    console.error('Unusual state for Property & Tenant page progression request')
  } else {
    trackClick(OVERHAUL_RENT_PAYMENT.EVENT_NAME.COLLECT_PAYMENTS_CLICK, {
      step: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.STEP.PROPERTY_TENANT,
      destination: increment
        ? OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.DESTINATION.STEP_3_PAYMENT_DETAILS_SELECTION
        : OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.DESTINATION.STEP_1_RECIPIENT_DETAILS,
    })

    if (increment) {
      const pageInfo = curPaymentInfo[pageField]
      const allDataEnteredAndValid = isPageDataAllValid(pageInfo)
      if (allDataEnteredAndValid) {
        // if all info is filled out, we can progress to the next page
        indexChanges.pageL1Index += 1
        // we have no prior page to go back to in this flow, so only the drawer can be closed
        indexChanges.drawerOpen = false
        shouldSaveData = true
      }
    }
    if (decrement) {
      if (curPageIndexes.drawerOpen) {
        // we have no prior page to go back to in this flow, so only the drawer can be closed
        indexChanges.drawerOpen = false
      } else {
        // this page can step back to the prior one if the drawer is closed
        indexChanges.pageL1Index -= 1
        // drawer resetting should be handled by the smart index progression method, but add safety in redundancy
        indexChanges.drawerOpen = false
      }
    }
  }

  /*
   * All decrement states for this page involve going back to the root of the flow
   * Absorbing errored-state traversal handling as well
   */
  setPageIndexes(indexChanges)
  return shouldSaveData
}

export const PropertyAndTenantPage = ({ pageField, updateDataHandler, setIsValid }) => {
  const [paymentInfo] = useContext(PaymentInfoContext)

  const pageInfo = paymentInfo?.[pageField] || {}
  const { [recipientFields.recipientInfo]: recipientInfo } =
    paymentInfo?.[pageOptions.RECIPIENT_DETAILS] || {}
  // don't spam state variable changes & page re-rendering while someone's typing in the drawers
  const debouncedSetPropertyPiece = debounce(nextPropertyInfo => {
    updateDataHandler({
      // leave the other non-recipient-info sub-fields for the page's other info alone
      ...pageInfo,
      // change only what we care about
      [propertyField]: nextPropertyInfo,
    })
  }, 200)

  useEffect(() => {
    setIsValid(!!pageInfo?.[propertyField] && !!pageInfo?.[tenantField])
  }, [pageInfo?.[propertyField], pageInfo?.[tenantField]])

  // don't spam state variable changes & page re-rendering while someone's typing in the drawers
  const debouncedSetTenantPiece = debounce(nextTenantInfo => {
    updateDataHandler({
      // leave the other non-recipient-info sub-fields for the page's other info alone
      ...pageInfo,
      // change only what we care about
      [tenantField]: nextTenantInfo,
    })
  }, 200)

  return (
    <Box sx={stepperFormGroups}>
      <Typography variant="title-medium">{PROP_AND_TENANT.TITLE}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <PropertySelectAndForm
          selectedProperty={pageInfo?.[propertyField]}
          setProperty={debouncedSetPropertyPiece}
          recipientInfo={recipientInfo}
        />
        {isPropertyDataValid(pageInfo) && (
          <TenantSelectAndForm
            selectedTenant={pageInfo?.[tenantField]}
            setTenant={debouncedSetTenantPiece}
          />
        )}
      </Box>
    </Box>
  )
}
