import dayjs from 'dayjs'
import { isDecimal } from 'validator'
import { number, object, string } from 'yup'

import { utcStartOfDay } from 'v3/containers/overhaul-rent-payment/pages/utils'

import { PAYMENT_TYPES, PaymentCategories } from '../../constants'

export const validateSharedPaymentFields = payment => {
  /*
   * Validates the shared payment fields between Recurring & One-Time types
   */
  const allowedKeys = new Set(['type', 'category', 'amount', 'description'])
  const presentKeys = new Set(Object.keys(payment))

  // Set.difference() is only available since June 2024, newer than our code apparently
  const difference = [...allowedKeys].filter(key => !presentKeys.has(key))
  if (difference.length !== 0) {
    // if the base keys aren't present, the API should reject it
    return false
  }

  let isValidPayment = false
  const { type, category, amount, description } = payment

  try {
    const typePresent = typeof type === 'string' && ['recurring', 'oneTime'].includes(type)
    const categoryIsPresent = typeof category === 'string' && category.length > 0
    // type enforcement doesn't seem to require an amount minimum
    const validAmount =
      typeof amount === 'string'
        ? isDecimal(amount) && parseFloat(amount).toFixed(2) === amount
        : typeof amount === 'number'
    // must be null if blank??
    const descMissingOrString = !description || typeof description === 'string'

    // all checks must pass
    isValidPayment = Boolean(typePresent && categoryIsPresent && validAmount && descMissingOrString)
  } catch (error) {
    // log this somewhere
    // eslint-disable-next-line no-console
    console.warn(error)
  }

  return isValidPayment
}

export const validateOneTimePayment = payment => {
  /*
   * Validates the One-Time payments & enforces some pre-emptive value guardrails before
   * we call out to the API.
   */
  let isValidOneTimePayment = false

  // if there are more keys than expected, the API will (might?) reject it
  const requiredKeys = new Set(['dueDate'])
  const presentKeys = new Set(Object.keys(payment))
  // Set.intersection() is only available since June 2024, newer than our code apparently
  const intersection = [...requiredKeys].filter(key => presentKeys.has(key))
  const hasOneTimeSpecificFields = intersection.length === requiredKeys.size
  // check if the base fields are valid
  const basePaymentFieldsValid = validateSharedPaymentFields(payment)

  if (basePaymentFieldsValid && hasOneTimeSpecificFields) {
    // check the one-time payment specific fields & values
    const { type, dueDate } = payment

    try {
      const isOneTime = type === 'oneTime'
      const validDueDate =
        typeof dueDate === 'string' &&
        dueDate.startsWith(utcStartOfDay(dueDate).format('YYYY-MM-DD'))

      // all checks must pass
      isValidOneTimePayment = Boolean(isOneTime && validDueDate)
    } catch (error) {
      // log this somewhere
      // eslint-disable-next-line no-console
      console.warn(error)
    }
  }

  return isValidOneTimePayment
}

export const validateRecurringPayment = payment => {
  /*
   * Validates the Recurring payments & enforces some pre-emptive value guardrails before
   * we call out to the API.
   */
  let isValidRecurringPayment = false

  // if there are more keys than expected, the API will (might?) reject it
  const requiredKeys = new Set(['repeat', 'startDate', 'dueOn'])
  const presentKeys = new Set(Object.keys(payment))
  const intersection = [...requiredKeys].filter(key => presentKeys.has(key))
  const hasRecurringSpecificFields = intersection.length === requiredKeys.size
  // check if the base fields are valid
  const basePaymentFieldsValid = validateSharedPaymentFields(payment)

  if (basePaymentFieldsValid && hasRecurringSpecificFields) {
    // check the one-time payment specific fields & values
    const { type, repeat, startDate, endDate, dueOn, term } = payment

    try {
      const isRecurring = type === 'recurring'
      const validRepeat = ['monthly', 'biweekly', 'weekly'].includes(repeat)
      // API dates are full ISO format
      const validStartDate =
        typeof startDate === 'string' &&
        startDate.startsWith(utcStartOfDay(startDate).format('YYYY-MM-DD'))
      // endDate is required for a fixed term
      const validEndDate =
        // can be null if the term isn't 'fixed', otherwise should be a valid date string
        (endDate === null && term !== 'fixed') ||
        // API dates are full ISO format
        (typeof endDate === 'string' &&
          endDate.startsWith(utcStartOfDay(endDate).format('YYYY-MM-DD')))
      // TODO: depends on late fee??
      const validDueOn =
        dueOn >= 1 &&
        dueOn <= 31 &&
        parseInt(dueOn, 10) === dueOn &&
        validStartDate &&
        utcStartOfDay(startDate).date() === dueOn
      const validTerm = ['fixed', 'perpetual', undefined, null].includes(term)

      // all checks must pass
      isValidRecurringPayment = Boolean(
        isRecurring && validRepeat && validStartDate && validEndDate && validDueOn && validTerm,
      )
    } catch (error) {
      // log this somewhere
      // eslint-disable-next-line no-console
      console.warn(error)
    }
  }

  return isValidRecurringPayment
}

const NOW = dayjs()

const minDate = (value, minDateValue) => {
  if (!value || value === '') {
    return true
  }
  const date = dayjs(value)
  return date.isSame(dayjs(minDateValue), 'day') || date.isAfter(dayjs(minDateValue), 'day')
}

const maxDate = (value, maxDateValue) => {
  if (!value || value === '') {
    return true
  }
  const date = dayjs(value)
  return date.isSame(dayjs(maxDateValue), 'day') || date.isBefore(dayjs(maxDateValue), 'day')
}

const isValidDate = value => {
  if (!value) {
    return true
  }
  return dayjs(value).isValid()
}

const basePaymentValidationSchema = object({
  startDate: string()
    .required('Starts on date is required')
    .test('min-date', 'Select today’s date or a future date', value => minDate(value, NOW))
    .test('val-date', 'Enter a valid date', value => isValidDate(value)),
  category: string().required(),
  type: string().oneOf(Object.values(PAYMENT_TYPES)).required(),
  amount: number()
    .min(3, 'Enter an amount between $3 and $10,000')
    .max(10000, 'Enter an amount between $3 and $10,000')
    .required('Amount is required'),
  description: string(),
})

export const oneTimePaymentValidationSchema = basePaymentValidationSchema.concat(
  object({
    dueDate: string()
      .required('Due date is required')
      .test('min-date', 'Select today’s date or a future date', value => minDate(value, NOW)),
  }),
)

export const recurringPaymentValidationSchema = basePaymentValidationSchema.concat(
  object({
    endDate: string()
      .test('max-date', 'Select an end date within the next 36 months', (value, context) =>
        maxDate(value, dayjs(context?.parent?.startDate).add(36, 'month')),
      )
      .when('startDate', startDate => {
        if (startDate) {
          return string().test(
            'after-date',
            'Select an end date that is after the start date',
            value => minDate(value, startDate),
          )
        }
        return string()
      }),
    repeat: string().oneOf(['monthly', 'biweekly', 'weekly']),
    dueOn: number().required(),
    term: string().oneOf(['fixed', 'perpetual']),
    // name: string().required('Select a name for your recurring payment'),
  }),
)

export const otherCategoryPaymentValidationSchema = object({
  name: string()
    .max(75, 'Enter a fee name that is under 75 characters')
    .when('category', category => {
      if (category === PaymentCategories.OTHER) {
        return string().required('Fee name is required')
      }
      return string()
    }),
})
