import remove from 'lodash/remove'
import isEmpty from 'lodash/isEmpty'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { CenterContent, Loading } from 'components/layout/main'
import ToggleArchivePropertyModal from 'containers/property/toggle-archive-property'
import tracker from 'tracker'
import { ARCHIVE_PROPERTY } from 'tracker/const'

import { useOnboardingPartnerAgent } from 'v3/hooks/use-onboarding-partner-agent'
import PropertyListTableComponent from 'components/property-list-table'
import { Box } from '@mui/material'
import { MODAL_STATUS } from 'containers/property/toggle-archive-property/constants'
import { FILTER_PROPERTY_LIST_OPTIONS } from 'components/property-list-table/constants'
import { FILTER_PROPERTY } from '../constants'
import { makeSelectIsFetching, makeSelectList, makeSelectPagination } from '../selectors'
import { PropertyLandingComponent } from '../landing'

export const PropertyListTableContainer = ({
  getPropertyList,
  filter,
  search,
  history,
  actions,
  hasProperty,
  hasNoQueryOption,
}) => {
  const { isHighlightScreenTenant, setIsHighlightScreenTenant } = useOnboardingPartnerAgent()
  const [currentFilter, setCurrentFilter] = useState({ status: 'active' })
  const [currentSearch, setCurrentSearch] = useState('')

  const isFetching = useSelector(makeSelectIsFetching())
  const propertyList = useSelector(makeSelectList())
  const pagination = useSelector(makeSelectPagination())

  const handleSearch = searchValue => {
    setCurrentSearch(searchValue)
    getPropertyList(1, {}, true, searchValue)
  }

  const handleFilter = ({ target: { value } }) => {
    tracker.trackEvent(ARCHIVE_PROPERTY.selectFilter, { status: value })
    setCurrentFilter({ status: value })
    getPropertyList(1, { status: value })
  }

  const handleChangePage = page => {
    getPropertyList(page, currentFilter, currentSearch)
  }

  const handleArchiveProperty = propertyId => {
    actions.openArchiveModal({
      type: MODAL_STATUS.ARCHIVE,
      propertyId,
    })
  }

  const handleUnarchiveProperty = propertyId => {
    actions.openArchiveModal({
      type: MODAL_STATUS.UNARCHIVE,
      propertyId,
    })
  }

  const filterList = (list, statusValue) => {
    switch (statusValue) {
      case FILTER_PROPERTY.ALL:
        return list
      case FILTER_PROPERTY.ARCHIVED:
        return remove(list, p => isEmpty(p.archived))
      case FILTER_PROPERTY.ACTIVE:
      default:
        return remove(list, p => !isEmpty(p.archived))
    }
  }

  const filteredList = useMemo(
    () => filterList(propertyList, filter.status),
    [propertyList, filter],
  )

  return (
    <Box id="propertyListPage" data-testid="property-list-page-container">
      {isFetching && (
        <CenterContent
          overlay
          noBreadcrumbMobile
          withFilter
          withPagination={filteredList.length !== 0}
        >
          <Loading data-testid="loading" />
        </CenterContent>
      )}
      <PropertyLandingComponent hasProperty={hasProperty} history={history} />
      {hasProperty && (
        <Box data-testid="property-list-table-component-container">
          <PropertyListTableComponent
            handleFilterBarStatus={handleFilter}
            handleFilterBarSearch={handleSearch}
            filterBarStatus={filter.status || FILTER_PROPERTY_LIST_OPTIONS[1].value}
            searchQueries={{ search: search || '' }}
            filterBarOptions={FILTER_PROPERTY_LIST_OPTIONS}
            data={propertyList}
            isLoading={isFetching}
            currentSort={{ updated_at: 'desc' }}
            pagination={pagination}
            handleChangePage={handleChangePage}
            onArchiveProperty={handleArchiveProperty}
            onUnarchiveProperty={handleUnarchiveProperty}
            isHighlightScreenTenant={isHighlightScreenTenant}
            setIsHighlightScreenTenant={setIsHighlightScreenTenant}
            history={history}
            hasNoQueryOption={hasNoQueryOption}
          />
        </Box>
      )}
      <ToggleArchivePropertyModal />
    </Box>
  )
}
