import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, DialogContent } from '@mui/material'
import { Page } from 'react-pdf/dist/esm/entry.webpack5'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import './pdf-overlay-content.scss'

const PdfOverlayContent = ({ totalPage, scale, currentPageIndex, onChange, defaultWidth }) => {
  const [pdfWidth, setPdfWidth] = useState(
    window.innerWidth < defaultWidth ? window.innerWidth : defaultWidth,
  )
  const pageRefs = useRef(Array(totalPage))
  const [state, setState] = useState({
    displayPageIndex: currentPageIndex,
    currentPageIndex,
    isScrolling: false,
  })
  const [isReady, setIsReady] = useState(false)
  const [observer, setObserver] = useState(null)

  const handleResize = useCallback(() => {
    if (window.innerWidth < defaultWidth) {
      if (pdfWidth !== window.innerWidth) {
        setPdfWidth(window.innerWidth)
      }
    } else {
      setPdfWidth(defaultWidth)
    }
  }, [defaultWidth, window.innerWidth])

  useEffect(() => {
    if (!isReady) {
      setTimeout(() => {
        setIsReady(true)
      }, 500)
    }

    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    if (isReady) {
      const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.6 / scale, // Trigger when at least threshold of the element is visible
      }

      setObserver(prevObserver => {
        if (prevObserver) {
          prevObserver.disconnect()
        }
        return new IntersectionObserver(entries => {
          entries.forEach(entry => {
            const index = Number(entry.target.dataset.index)
            if (entry.isIntersecting) {
              setState(prev => ({ ...prev, displayPageIndex: index }))
            }
          })
        }, observerOptions)
      })
    }
  }, [isReady, scale])

  useEffect(() => {
    if (observer) {
      pageRefs.current.forEach(el => {
        if (el) observer.observe(el)
      })
    }
  }, [observer])

  useEffect(() => {
    if (pageRefs.current[currentPageIndex]) {
      if (currentPageIndex !== state.currentPageIndex) {
        pageRefs.current[currentPageIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
        setState(prev => ({
          ...prev,
          isScrolling: true,
          currentPageIndex,
        }))
      }
    }
  }, [currentPageIndex, state])

  useEffect(() => {
    if (state.isScrolling && state.currentPageIndex === state.displayPageIndex) {
      setState(prev => ({ ...prev, isScrolling: false }))
    } else if (!state.isScrolling && state.displayPageIndex !== state.currentPageIndex) {
      setState(prev => ({
        ...prev,
        displayPageIndex: state.displayPageIndex,
        currentPageIndex: state.displayPageIndex,
      }))
      onChange(state.displayPageIndex)
    }
  }, [state])

  return (
    <DialogContent className="RSPPdfOverlayContent">
      <Box className="RSPPdfOverlayContent-PageContainer">
        {[...Array(totalPage).keys()].map(i => (
          <div
            className="RSPPdfOverlayContent-Page"
            key={i}
            ref={el => {
              pageRefs.current[i] = el
            }}
            data-index={i}
          >
            <Page width={pdfWidth} pageNumber={i + 1} scale={scale} />
          </div>
        ))}
      </Box>
    </DialogContent>
  )
}

PdfOverlayContent.defaultProps = {
  defaultWidth: 600,
}

export default PdfOverlayContent
